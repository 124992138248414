/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './CorrelationScreen.css'
import resultBase from '../../Images/resultBase.svg';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getCorrelationHeaders } from '../../../utils/getCorrelationHeaders';
import Table from '../HomeScreen/Table/Table';
import MasterPage from '../../MasterPage';
import { useLocation } from 'react-router-dom';

function CorrelationScreen({setCurrentlyActiveTab,currentlyActiveTab, selectedGoal,setSelectedGoal}) {

    const [header, setHeader] = useState();
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    let location = useLocation();
    
    const correlation_allVariable = useSelector(state => state.correlation_allVariableReducer);

    const { loading: loading1, error: error1, CorrelationallVariableList } = correlation_allVariable;

    const correlation_allVariable_goal2 = useSelector(state => state.correlation_allVariableReducerGoal2);
    const {loading: loadingGoal2, error: error2, CorrelationallVariableListGoal2} = correlation_allVariable_goal2;

    const correlation_allVariable_goal3 = useSelector(state => state.correlation_allVariableReducerGoal3);
    const {loading: loadingGoal3, error: error3, CorrelationallVariableListGoal3} = correlation_allVariable_goal3;

    const correlation_allVariable_goal4 = useSelector(state => state.correlation_allVariableReducerGoal4);
    const {loading: loadingGoal4, error: error4, CorrelationallVariableListGoal4} = correlation_allVariable_goal4;

    const correlation_allVariable_goal5 = useSelector(state => state.correlation_allVariableReducerGoal5);
    const {loading: loadingGoal5, error: error5, CorrelationallVariableListGoal5} = correlation_allVariable_goal5;

    const correlation_allVariable_goal6 = useSelector(state => state.correlation_allVariableReducerGoal6);
    const {loading: loadingGoal6, error: error6, CorrelationallVariableListGoal6} = correlation_allVariable_goal6;

    const correlation_allVariable_goal7 = useSelector(state => state.correlation_allVariableReducerGoal7);
    const {loading: loadingGoal7, error: error7, CorrelationallVariableListGoal7} = correlation_allVariable_goal7;

    const override = css`position: absolute; transform: translate(-20%, 300%);`;

    useEffect(() => {
        setCurrentlyActiveTab("correlation");
    },[currentlyActiveTab, setCurrentlyActiveTab])

    //setting headers for table
    useEffect(() => {
        const columns = getCorrelationHeaders("All_var",selectedGoal);
        setHeader(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // setting selected goal based on the route
    useEffect(() => {
        //seperating goal (ie., goal1,goal2,..) from location path
        if(!selectedGoal.length && location.pathname.split('/')[1] && location.pathname.split('/')[1].includes('goal')){
            setSelectedGoal(location.pathname.split('/')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])

    //changing the table data when selected goal is changed and the loading is done
    useEffect(() => {
        if(selectedGoal === 'goal1' && !loading1){
            setRow(CorrelationallVariableList); 
            setIsLoading(loading1)
        }
        if(selectedGoal === 'goal2' && !loadingGoal2){
            setRow(CorrelationallVariableListGoal2); 
            setIsLoading(loadingGoal2)
        }
        if(selectedGoal === 'goal3' && !loadingGoal3){
            setRow(CorrelationallVariableListGoal3); 
            setIsLoading(loadingGoal3)
        }
        if(selectedGoal === 'goal4' && !loadingGoal4){
            setRow(CorrelationallVariableListGoal4); 
            setIsLoading(loadingGoal4)
        }
        if(selectedGoal === 'goal5' && !loadingGoal5){
            setRow(CorrelationallVariableListGoal5); 
            setIsLoading(loadingGoal5)
        }
        if(selectedGoal === 'goal6' && !loadingGoal6){
            setRow(CorrelationallVariableListGoal6); 
            setIsLoading(loadingGoal6)
        }
        if(selectedGoal === 'goal7' && !loadingGoal6){
            setRow(CorrelationallVariableListGoal7); 
            setIsLoading(loadingGoal7)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGoal,loading1,loadingGoal2,loadingGoal3,loadingGoal4,loadingGoal5,loadingGoal6,loadingGoal7])



    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab}  currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />
            <div className='resultscreen screen'>
                <div className='tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <div className={`tab active`}>
                        <span className="tabStyle">
                            <label>Correlation All Variables</label>
                        </span>
                    </div>
                </div>
                <div className="Container boundary">
                <div className='table-view'>
                    {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                        <>
                            {(header && row) && <Table columns={header} data={row} sortedField="Correlation" />}
                        </>
                    }
                </div>

            </div>
            </div>


        </>
    );
}

export default CorrelationScreen;