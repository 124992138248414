import { ALL_VARIABLES_FAIL_GOAL6, ALL_VARIABLES_REQUEST_GOAL6, ALL_VARIABLES_SUCCESS_GOAL6 } from "../../constants";

// all variable reducer for goal 6
export const allVariableReducerGoal6 = (state = {allVariableList: []}, action) => {
    switch(action.type){
        case ALL_VARIABLES_REQUEST_GOAL6:
            return {loading: true}
        case ALL_VARIABLES_SUCCESS_GOAL6:
            return{loading: false, allVariableList: action.payload }
        case ALL_VARIABLES_FAIL_GOAL6:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}