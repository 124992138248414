import { getAllVariableApiGoal5 } from "../../../utils/api";
import { ALL_VARIABLES_FAIL_GOAL5, ALL_VARIABLES_REQUEST_GOAL5, ALL_VARIABLES_SUCCESS_GOAL5 } from "../../constants";


// all variable action for goal 5
export const getAllVariableGoal5 = () => async(dispatch) => {
    dispatch({
        type: ALL_VARIABLES_REQUEST_GOAL5
    })

    try{
      const {data} = await getAllVariableApiGoal5();
      dispatch({
          type: ALL_VARIABLES_SUCCESS_GOAL5,
          payload: data
      });
    }catch(error){
        dispatch({
            type: ALL_VARIABLES_FAIL_GOAL5,
            payload: error.message
        })
    }
}