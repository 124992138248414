import { getSelectedVariableApi } from "../../../utils/api";
import { SELECTED_VARIABLES_REQUEST, SELECTED_VARIABLES_SUCCESS, SELECTED_VARIABLES_FAIL } from "../../constants";

// selected variables action for goal 1
export const getselectedVariable = () => async(dispatch) => {
    dispatch({
        type: SELECTED_VARIABLES_REQUEST
    })

    try{
      const {data} = await getSelectedVariableApi();
      dispatch({
          type: SELECTED_VARIABLES_SUCCESS,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SELECTED_VARIABLES_FAIL,
            payload: error.message
        })
    }
}
