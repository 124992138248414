import React, { useState } from 'react';
import './HomeScreenHeader.css';
import base from '../../../Images/base.png';
import TableView from '../TableLayout/TableView';

//header for the home page table
function HomeScreenHeader({selectedGoal,setSelectedGoal, setSavedVariables}) {

    const [currentTab, setCurrentTab] = useState(0);
    return (
        <div className='homeheader'>
            <div className='tableHeader' style={{
                background: `url(${base})`,
            }}>

                <span className="homeTabStyle tab" onClick={() => setCurrentTab(0)}><label>All Variables</label>
                    <div className={`${currentTab === 0 && "underline"}`}></div>
                </span>
                <span className="homeTabStyle tab" onClick={() => setCurrentTab(1)}>
                    <label>Summary Statistics</label>
                    <div className={`${currentTab === 1 && "underline"}`}></div>
                </span>
                <span className="homeTabStyle tab" onClick={() => setCurrentTab(2)}>
                    <label>Selected Variable</label>
                    <div className={`${currentTab === 2 && "underline"}`}></div>
                </span>
            </div>
            <TableView currentTab={currentTab} selectedGoal={selectedGoal} setSavedVariables={setSavedVariables} sortedField="Mean" />

        </div>
    );
}

export default HomeScreenHeader;