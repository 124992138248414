import {GOAL_RESULT_FAIL, GOAL_RESULT_REQUEST, GOAL_RESULT_SUCCESS } from "../../constants";

// goal result reducer
export const goalResultReducer = (state = {goalResult: []}, action) => {
    switch(action.type){
        case GOAL_RESULT_REQUEST:
            return {loading: true}
        case GOAL_RESULT_SUCCESS:
            return{loading: false, goalResult: action.payload }
        case GOAL_RESULT_FAIL:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}