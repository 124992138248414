/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { getHeader } from '../../../../utils/getAllVariables';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import btnBG from '../../../Images/btnBG.svg'
import Table from '../Table/Table';
import './TableView.css';
import { getResult } from '../../../../Redux/Reducers/Result/resultAction';
import { getResultGoal2 } from '../../../../Redux/Reducers/Result/resultActionGoal2';
import { getResultGoal3 } from '../../../../Redux/Reducers/Result/resultActionGoal3';
import { getResultGoal4 } from '../../../../Redux/Reducers/Result/resultActionGoal4';
import { getResultGoal5 } from '../../../../Redux/Reducers/Result/resultActionGoal5';
import { getResultGoal6 } from '../../../../Redux/Reducers/Result/resultActionGoal6';

function TableView({ currentTab, selectedGoal,setSavedVariables,sortedField }) {

    const [header, setHeader] = useState();
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [variables, setVariables] = useState();
    const [formValidationErr, setFormValidationErr] = useState('');

    const allVariable = useSelector(state => state.allVariableReducer);
    const summary = useSelector(state => state.summaryReducer);
    const selectedVariable = useSelector(state => state.selectedVariableReducer);


    const { loading: loading1, error: error1, allVariableList } = allVariable;
    const { loading: loading2, error: error2, summaryStatistics } = summary;
    const { loading: loading3, error: error3, selectedVariableList } = selectedVariable;

    const allVariableGoal2 = useSelector(state => state.allVariableReducerGoal2);
    const summaryGoal2 = useSelector(state => state.summaryReducerGoal2);
    const selectedVariableGoal2 = useSelector(state => state.selectedVariableReducerGoal2);


    const { loading: loading1Goal2, error: error1Goal2, allVariableList: allVariableListGoal2 } = allVariableGoal2;
    const { loading: loading2Goal2, error: error2Goal2, summaryStatistics: summaryStatisticsGoal2 } = summaryGoal2;
    const { loading: loading3Goal2, error: error3Goal2, selectedVariableList: selectedVariableListGoal2 } = selectedVariableGoal2;

    const allVariableGoal3 = useSelector(state => state.allVariableReducerGoal3);
    const summaryGoal3 = useSelector(state => state.summaryReducerGoal3);
    const selectedVariableGoal3 = useSelector(state => state.selectedVariableReducerGoal3);

    const { loading: loading1Goal3, error: error1Goal3, allVariableList: allVariableListGoal3 } = allVariableGoal3;
    const { loading: loading2Goal3, error: error2Goal3, summaryStatistics: summaryStatisticsGoal3 } = summaryGoal3;
    const { loading: loading3Goal3, error: error3Goal3, selectedVariableList: selectedVariableListGoal3 } = selectedVariableGoal3;

    const allVariableGoal4 = useSelector(state => state.allVariableReducerGoal4);
    const summaryGoal4 = useSelector(state => state.summaryReducerGoal4);
    const selectedVariableGoal4 = useSelector(state => state.selectedVariableReducerGoal4);

    const { loading: loading1Goal4, error: error1Goal4, allVariableList: allVariableListGoal4 } = allVariableGoal4;
    const { loading: loading2Goal4, error: error2Goal4, summaryStatistics: summaryStatisticsGoal4 } = summaryGoal4;
    const { loading: loading3Goal4, error: error3Goal4, selectedVariableList: selectedVariableListGoal4 } = selectedVariableGoal4;

    const allVariableGoal5 = useSelector(state => state.allVariableReducerGoal5);
    const summaryGoal5 = useSelector(state => state.summaryReducerGoal5);
    const selectedVariableGoal5 = useSelector(state => state.selectedVariableReducerGoal5);

    const { loading: loading1Goal5, error: error1Goal5, allVariableList: allVariableListGoal5 } = allVariableGoal5;
    const { loading: loading2Goal5, error: error2Goal5, summaryStatistics: summaryStatisticsGoal5 } = summaryGoal5;
    const { loading: loading3Goal5, error: error3Goal5, selectedVariableList: selectedVariableListGoal5 } = selectedVariableGoal5;

    const allVariableGoal6 = useSelector(state => state.allVariableReducerGoal6);
    const summaryGoal6 = useSelector(state => state.summaryReducerGoal6);
    const selectedVariableGoal6 = useSelector(state => state.selectedVariableReducerGoal6);

    const { loading: loading1Goal6, error: error1Goal6, allVariableList: allVariableListGoal6 } = allVariableGoal6;
    const { loading: loading2Goal6, error: error2Goal6, summaryStatistics: summaryStatisticsGoal6 } = summaryGoal6;
    const { loading: loading3Goal6, error: error3Goal6, selectedVariableList: selectedVariableListGoal6 } = selectedVariableGoal6;

    const allVariableGoal7 = useSelector(state => state.allVariableReducerGoal7);
    const summaryGoal7 = useSelector(state => state.summaryReducerGoal6);
    const selectedVariableGoal7 = useSelector(state => state.selectedVariableReducerGoal7);

    const { loading: loading1Goal7, error: error1Goal7, allVariableList: allVariableListGoal7 } = allVariableGoal7;
    const { loading: loading2Goal7, error: error2Goal7, summaryStatistics: summaryStatisticsGoal7 } = summaryGoal7;
    const { loading: loading3Goal7, error: error3Goal7, selectedVariableList: selectedVariableListGoal7 } = selectedVariableGoal7;


    const dispatch = useDispatch();
    let navigate = useNavigate();

    const getVariables = (variables) => {
        
        setVariables(variables)
    }

    // validating selected variables form submission and dispatching repective getResult 
    const handleClickGetResult = () => {
    
        if(!variables || variables.length < 2){
            setFormValidationErr("Please select atleast 2 checkBoxes")
            return
        }
        setFormValidationErr('')
        if(selectedGoal === 'goal1'){
            dispatch(getResult(variables));
        }
        else if(selectedGoal === 'goal2'){
            dispatch(getResultGoal2(variables));
        }
        else if(selectedGoal === 'goal3'){
            dispatch(getResultGoal3(variables));
        }
        else if(selectedGoal === 'goal4'){
            dispatch(getResultGoal4(variables));
        }
        else if(selectedGoal === 'goal5'){
            dispatch(getResultGoal5(variables));
            // setFormValidationErr("Work in progress")
            // return
        }
        else if(selectedGoal === 'goal6'){
            dispatch(getResultGoal6(variables));
            // setFormValidationErr("Work in progress")
            // return
        }
        else if(selectedGoal === 'goal7'){
            // dispatch(getResultGoal7(variables));
            setFormValidationErr("Work in progress")
            return
        }
  
        setSavedVariables({
            independentVariables: variables,
            dependentVariables: row[0].Dependent_Variable
        })
        navigate(`/${selectedGoal}/result/Comparision`);
        
    }

    const override = css`position: absolute; transform: translate(-20%, 300%);`;


    useEffect(() => {
        const columns = getHeader(currentTab, selectedGoal);
        setHeader(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    //setting the table data based on the goal selected
    useEffect(() => {
        
        if(selectedGoal === 'goal1'){
            if (currentTab === 0) { setRow(allVariableList); setIsLoading(loading1) }
            if (currentTab === 1) { setRow(summaryStatistics); setIsLoading(loading2) }
            if (currentTab === 2) { setRow(selectedVariableList); setIsLoading(loading3) }
        }
        if(selectedGoal === 'goal2'){
            
            if (currentTab === 0) { setRow(allVariableListGoal2); setIsLoading(loading1Goal2) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal2 ); setIsLoading(loading2Goal2) }
            if (currentTab === 2) { setRow(selectedVariableListGoal2); setIsLoading(loading3Goal2) }
        }
        if(selectedGoal === 'goal3'){
            if (currentTab === 0) { setRow(allVariableListGoal3); setIsLoading(loading1Goal3) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal3 ); setIsLoading(loading2Goal3) }
            if (currentTab === 2) { setRow(selectedVariableListGoal3); setIsLoading(loading3Goal3) }
        }
        if(selectedGoal === 'goal4'){
            if (currentTab === 0) { setRow(allVariableListGoal4); setIsLoading(loading1Goal4) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal4 ); setIsLoading(loading2Goal4) }
            if (currentTab === 2) { setRow(selectedVariableListGoal4); setIsLoading(loading3Goal4) }
        }
        if(selectedGoal === 'goal5'){
            if (currentTab === 0) { setRow(allVariableListGoal5); setIsLoading(loading1Goal5) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal5 ); setIsLoading(loading2Goal5) }
            if (currentTab === 2) { setRow(selectedVariableListGoal5); setIsLoading(loading3Goal5) }
        }
        if(selectedGoal === 'goal6'){
            if (currentTab === 0) { setRow(allVariableListGoal6); setIsLoading(loading1Goal6) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal6 ); setIsLoading(loading2Goal6) }
            if (currentTab === 2) { setRow(selectedVariableListGoal6); setIsLoading(loading3Goal6) }
        }
        if(selectedGoal === 'goal7'){
            if (currentTab === 0) { setRow(allVariableListGoal7); setIsLoading(loading1Goal7) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal7 ); setIsLoading(loading2Goal7) }
            if (currentTab === 2) { setRow(selectedVariableListGoal7); setIsLoading(loading3Goal7) }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, loading1, loading2, loading3, 
        loading1Goal2, loading2Goal2, loading3Goal2,
        loading1Goal3,loading2Goal3,loading3Goal3,
        loading1Goal4,loading2Goal4,loading3Goal4,
        loading1Goal5,loading2Goal5,loading3Goal5,
        loading1Goal6,loading2Goal6,loading3Goal6,
        loading1Goal7,loading2Goal7,loading3Goal7, selectedVariableList])




    return (
        <div className={`tableContainer boundary ${currentTab !== 2 && "tableContainer-padding"}`}>
            <div className='table-view__container'>
                {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                    <>
                        {(header && row) && <Table columns={header} data={row} currentTab = {currentTab} getVariables = {getVariables} sortedField={sortedField} />}
                    </>
                }
            </div>
            
            {currentTab === 2 && <>
                <p className="checkbox-error">{formValidationErr}</p>
                <div className='button-wrapper' style={{
                    background: `url(${btnBG})`,
                }} onClick={handleClickGetResult}><button className="button">Get Result</button></div>
            </>}
        </div>
    );
}

export default TableView;