import { getSelectedVariableApiGoal5 } from "../../../utils/api";
import { SELECTED_VARIABLES_REQUEST_GOAL5, SELECTED_VARIABLES_SUCCESS_GOAL5, SELECTED_VARIABLES_FAIL_GOAL5 } from "../../constants";

// selected variables action for goal 5
export const getselectedVariableGoal5 = () => async(dispatch) => {
    dispatch({
        type: SELECTED_VARIABLES_REQUEST_GOAL5
    })

    try{
      const {data} = await getSelectedVariableApiGoal5();
      dispatch({
          type: SELECTED_VARIABLES_SUCCESS_GOAL5,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SELECTED_VARIABLES_FAIL_GOAL5,
            payload: error.message
        })
    }
}

