import MasterPage from '../../MasterPage';
import './LandingPage.css'
import Predictions from '../../Images/predictions.svg';
import newProject from '../../Images/newProject.png'
import Training from '../../Images/training.png'
import { useNavigate } from 'react-router-dom';
import {useState,useRef} from 'react'

function LandingPage({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal}) {
    const [selected,setSelected] = useState('')
    const predictCard = useRef(null)
    const trainCard = useRef(null)
    const createProject = useRef(null)

    let navigate = useNavigate();

    const handlePredictCardClick = () => {
        predictCard.current.classList.toggle('selected')
        trainCard.current.classList.remove('selected')
        createProject.current.classList.remove('selected')
        navigate('/trainedmodels')
    }

    const handleTrainCardClick = () => {
        trainCard.current.classList.toggle('selected')
        predictCard.current.classList.remove('selected')
        createProject.current.classList.remove('selected')
        navigate('/existingdata')
    }

    const handleNewProjectCardClick = () => {
        createProject.current.classList.toggle('selected')
        predictCard.current.classList.remove('selected')
        trainCard.current.classList.remove('selected')
        navigate('/createNewProject')
    }

    // const handleContinueClick = () => {
    //     if(selected === '') return
    //     navigate(selected)
    // }

    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal = {selectedGoal} setSelectedGoal = {setSelectedGoal} needSidebar={false} needHomePageSidebar={true} />
        <div className='landingscreen screen'>
            <div className='landingscreen-main'>
            <h1>Please select an option</h1>
            <div className='landing-cards-group'>
                <div ref={createProject} className='landing-card' onClick={() => handleNewProjectCardClick()}>               
                    <h1>Create New Project</h1>
                    <img src={newProject} alt="prediction-icon" />
                    <h4>Create your own new Project and upload your data to use Machine Learning Models to predict your outcome  </h4>
                </div>
                <div ref={predictCard} className='landing-card' onClick={() => handlePredictCardClick()}>               
                    <h1>Predict</h1>
                    <img src={Predictions} alt="prediction-icon" />
                    <h4> Use trained Machine Learning Models on extensive amount of data (over 6M+ observations) to predict your outcome from the derived inputs.</h4>
                </div>
                <div ref={trainCard} className='landing-card' onClick={() => handleTrainCardClick()}>
                    <h1>Train Your Own Model</h1>
                    <img src={Training} alt="training-icon" />
                    <h4>Find trained machine learning models, use existing extensive amount of data or load your own data to find, select, train, customize your own  machine learning models. </h4>
                </div>
            </div>

                {/* <button className='continue-button' onClick={handleContinueClick}>
                    <span>Continue</span>
                    <svg fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </button> */}
            </div>
        </div>
        </>
    );
}

export default LandingPage;