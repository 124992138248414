export const ALL_VARIABLES_REQUEST = 'ALL_VARIABLES_REQUEST';
export const ALL_VARIABLES_SUCCESS = 'ALL_VARIABLES_SUCCESS';
export const ALL_VARIABLES_FAIL = 'ALL_VARIABLES_FAIL';


export const SUMMARY_REQUEST = 'SUMMARY_REQUEST';
export const SUMMARY_SUCCESS = 'SUMMARY_SUCCESS';
export const SUMMARY_FAIL = 'SUMMARY_FAIL';

export const SELECTED_VARIABLES_REQUEST = 'SELECTED_VARIABLES_REQUEST';
export const SELECTED_VARIABLES_SUCCESS = 'SELECTED_VARIABLES_SUCCESS';
export const SELECTED_VARIABLES_FAIL = 'SELECTED_VARIABLES_FAIL';


export const CORRELATION_ALL_VARIABLES_REQUEST = 'CORRELATION_ALL_VARIABLES_REQUEST';
export const CORRELATION_ALL_VARIABLES_SUCCESS = 'CORRELATION_ALL_VARIABLES_SUCCESS';
export const CORRELATION_ALL_VARIABLES_FAIL = 'CORRELATION_ALL_VARIABLES_FAIL';

export const GET_CHART_RESULT_REQUEST = 'GET_CHART_RESULT_REQUEST';
export const GET_CHART_RESULT_SUCCESS = 'GET_CHART_RESULT_SUCCESS';
export const GET_CHART_RESULT_FAIL = 'GET_CHART_RESULT_FAIL';


export const RESET_DATA = 'RESET_DATA';
export const RESET_DATA_GOAL2 = 'RESET_DATA_GOAL2';
export const RESET_DATA_GOAL3 = 'RESET_DATA_GOAL3';
export const RESET_DATA_GOAL4 = 'RESET_DATA_GOAL4';
export const RESET_DATA_GOAL5 = 'RESET_DATA_GOAL5';
export const RESET_DATA_GOAL6 = 'RESET_DATA_GOAL6';

export const ALL_VARIABLES_REQUEST_GOAL2 = 'ALL_VARIABLES_REQUEST_GOAL2';
export const ALL_VARIABLES_SUCCESS_GOAL2 = 'ALL_VARIABLES_SUCCESS_GOAL2';
export const ALL_VARIABLES_FAIL_GOAL2 = 'ALL_VARIABLES_FAIL_GOAL2';


export const SUMMARY_REQUEST_GOAL2 = 'SUMMARY_REQUEST_GOAL2';
export const SUMMARY_SUCCESS_GOAL2 = 'SUMMARY_SUCCESS_GOAL2';
export const SUMMARY_FAIL_GOAL2 = 'SUMMARY_FAIL_GOAL2';

export const SELECTED_VARIABLES_REQUEST_GOAL2 = 'SELECTED_VARIABLES_REQUEST_GOAL2';
export const SELECTED_VARIABLES_SUCCESS_GOAL2 = 'SELECTED_VARIABLES_SUCCESS_GOAL2';
export const SELECTED_VARIABLES_FAIL_GOAL2 = 'SELECTED_VARIABLES_FAIL_GOAL2';

export const GET_CHART_RESULT_REQUEST_GOAL2 = 'GET_CHART_RESULT_REQUEST_GOAL2';
export const GET_CHART_RESULT_SUCCESS_GOAL2 = 'GET_CHART_RESULT_SUCCESS_GOAL2';
export const GET_CHART_RESULT_FAIL_GOAL2 = 'GET_CHART_RESULT_FAIL_GOAL2';

export const CORRELATION_ALL_VARIABLES_REQUEST_GOAL2 = 'CORRELATION_ALL_VARIABLES_REQUEST_GOAL2';
export const CORRELATION_ALL_VARIABLES_SUCCESS_GOAL2 = 'CORRELATION_ALL_VARIABLES_SUCCESS_GOAL2';
export const CORRELATION_ALL_VARIABLES_FAIL_GOAL2 = 'CORRELATION_ALL_VARIABLES_FAIL_GOAL2';

export const ALL_VARIABLES_REQUEST_GOAL3 = 'ALL_VARIABLES_REQUEST_GOAL3';
export const ALL_VARIABLES_SUCCESS_GOAL3 = 'ALL_VARIABLES_SUCCESS_GOAL3';
export const ALL_VARIABLES_FAIL_GOAL3 = 'ALL_VARIABLES_FAIL_GOAL3';

export const SUMMARY_REQUEST_GOAL3 = 'SUMMARY_REQUEST_GOAL3';
export const SUMMARY_SUCCESS_GOAL3 = 'SUMMARY_SUCCESS_GOAL3';
export const SUMMARY_FAIL_GOAL3 = 'SUMMARY_FAIL_GOAL3';

export const SELECTED_VARIABLES_REQUEST_GOAL3 = 'SELECTED_VARIABLES_REQUEST_GOAL3';
export const SELECTED_VARIABLES_SUCCESS_GOAL3 = 'SELECTED_VARIABLES_SUCCESS_GOAL3';
export const SELECTED_VARIABLES_FAIL_GOAL3 = 'SELECTED_VARIABLES_FAIL_GOAL3';

export const CORRELATION_ALL_VARIABLES_REQUEST_GOAL3 = 'CORRELATION_ALL_VARIABLES_REQUEST_GOAL3';
export const CORRELATION_ALL_VARIABLES_SUCCESS_GOAL3 = 'CORRELATION_ALL_VARIABLES_SUCCESS_GOAL3';
export const CORRELATION_ALL_VARIABLES_FAIL_GOAL3 = 'CORRELATION_ALL_VARIABLES_FAIL_GOAL3';

export const GET_CHART_RESULT_REQUEST_GOAL3 = 'GET_CHART_RESULT_REQUEST_GOAL3';
export const GET_CHART_RESULT_SUCCESS_GOAL3 = 'GET_CHART_RESULT_SUCCESS_GOAL3';
export const GET_CHART_RESULT_FAIL_GOAL3 = 'GET_CHART_RESULT_FAIL_GOAL3';

export const ALL_VARIABLES_REQUEST_GOAL4 = 'ALL_VARIABLES_REQUEST_GOAL4';
export const ALL_VARIABLES_SUCCESS_GOAL4 = 'ALL_VARIABLES_SUCCESS_GOAL4';
export const ALL_VARIABLES_FAIL_GOAL4 = 'ALL_VARIABLES_FAIL_GOAL4';

export const SUMMARY_REQUEST_GOAL4 = 'SUMMARY_REQUEST_GOAL4';
export const SUMMARY_SUCCESS_GOAL4 = 'SUMMARY_SUCCESS_GOAL4';
export const SUMMARY_FAIL_GOAL4 = 'SUMMARY_FAIL_GOAL4';

export const SELECTED_VARIABLES_REQUEST_GOAL4 = 'SELECTED_VARIABLES_REQUEST_GOAL4';
export const SELECTED_VARIABLES_SUCCESS_GOAL4 = 'SELECTED_VARIABLES_SUCCESS_GOAL4';
export const SELECTED_VARIABLES_FAIL_GOAL4 = 'SELECTED_VARIABLES_FAIL_GOAL4';


export const CORRELATION_ALL_VARIABLES_REQUEST_GOAL4 = 'CORRELATION_ALL_VARIABLES_REQUEST_GOAL4';
export const CORRELATION_ALL_VARIABLES_SUCCESS_GOAL4 = 'CORRELATION_ALL_VARIABLES_SUCCESS_GOAL4';
export const CORRELATION_ALL_VARIABLES_FAIL_GOAL4 = 'CORRELATION_ALL_VARIABLES_FAIL_GOAL4';

export const GET_CHART_RESULT_REQUEST_GOAL4 = 'GET_CHART_RESULT_REQUEST_GOAL4';
export const GET_CHART_RESULT_SUCCESS_GOAL4 = 'GET_CHART_RESULT_SUCCESS_GOAL4';
export const GET_CHART_RESULT_FAIL_GOAL4 = 'GET_CHART_RESULT_FAIL_GOAL4';

export const ALL_VARIABLES_REQUEST_GOAL5 = 'ALL_VARIABLES_REQUEST_GOAL5';
export const ALL_VARIABLES_SUCCESS_GOAL5 = 'ALL_VARIABLES_SUCCESS_GOAL5';
export const ALL_VARIABLES_FAIL_GOAL5 = 'ALL_VARIABLES_FAIL_GOAL5';

export const SUMMARY_REQUEST_GOAL5 = 'SUMMARY_REQUEST_GOAL5';
export const SUMMARY_SUCCESS_GOAL5 = 'SUMMARY_SUCCESS_GOAL5';
export const SUMMARY_FAIL_GOAL5 = 'SUMMARY_FAIL_GOAL5';

export const SELECTED_VARIABLES_REQUEST_GOAL5 = 'SELECTED_VARIABLES_REQUEST_GOAL5';
export const SELECTED_VARIABLES_SUCCESS_GOAL5 = 'SELECTED_VARIABLES_SUCCESS_GOAL5';
export const SELECTED_VARIABLES_FAIL_GOAL5= 'SELECTED_VARIABLES_FAIL_GOAL5';

export const CORRELATION_ALL_VARIABLES_REQUEST_GOAL5 = 'CORRELATION_ALL_VARIABLES_REQUEST_GOAL5';
export const CORRELATION_ALL_VARIABLES_SUCCESS_GOAL5 = 'CORRELATION_ALL_VARIABLES_SUCCESS_GOAL5';
export const CORRELATION_ALL_VARIABLES_FAIL_GOAL5 = 'CORRELATION_ALL_VARIABLES_FAIL_GOAL5';

export const GET_CHART_RESULT_REQUEST_GOAL5 = 'GET_CHART_RESULT_REQUEST_GOAL5';
export const GET_CHART_RESULT_SUCCESS_GOAL5 = 'GET_CHART_RESULT_SUCCESS_GOAL5';
export const GET_CHART_RESULT_FAIL_GOAL5 = 'GET_CHART_RESULT_FAIL_GOAL5';

export const ALL_VARIABLES_REQUEST_GOAL6 = 'ALL_VARIABLES_REQUEST_GOAL6';
export const ALL_VARIABLES_SUCCESS_GOAL6 = 'ALL_VARIABLES_SUCCESS_GOAL6';
export const ALL_VARIABLES_FAIL_GOAL6 = 'ALL_VARIABLES_FAIL_GOAL6';

export const SUMMARY_REQUEST_GOAL6 = 'SUMMARY_REQUEST_GOAL6';
export const SUMMARY_SUCCESS_GOAL6 = 'SUMMARY_SUCCESS_GOAL6';
export const SUMMARY_FAIL_GOAL6 = 'SUMMARY_FAIL_GOAL6';

export const SELECTED_VARIABLES_REQUEST_GOAL6 = 'SELECTED_VARIABLES_REQUEST_GOAL6';
export const SELECTED_VARIABLES_SUCCESS_GOAL6 = 'SELECTED_VARIABLES_SUCCESS_GOAL6';
export const SELECTED_VARIABLES_FAIL_GOAL6 = 'SELECTED_VARIABLES_FAIL_GOAL6';

export const CORRELATION_ALL_VARIABLES_REQUEST_GOAL6 = 'CORRELATION_ALL_VARIABLES_REQUEST_GOAL6';
export const CORRELATION_ALL_VARIABLES_SUCCESS_GOAL6 = 'CORRELATION_ALL_VARIABLES_SUCCESS_GOAL6';
export const CORRELATION_ALL_VARIABLES_FAIL_GOAL6 = 'CORRELATION_ALL_VARIABLES_FAIL_GOAL6';

export const GET_CHART_RESULT_REQUEST_GOAL6 = 'GET_CHART_RESULT_REQUEST_GOAL6';
export const GET_CHART_RESULT_SUCCESS_GOAL6 = 'GET_CHART_RESULT_SUCCESS_GOAL6';
export const GET_CHART_RESULT_FAIL_GOAL6 = 'GET_CHART_RESULT_FAIL_GOAL6';

export const ALL_VARIABLES_REQUEST_GOAL7 = 'ALL_VARIABLES_REQUEST_GOAL7';
export const ALL_VARIABLES_SUCCESS_GOAL7 = 'ALL_VARIABLES_SUCCESS_GOAL7';
export const ALL_VARIABLES_FAIL_GOAL7 = 'ALL_VARIABLES_FAIL_GOAL7';

export const SUMMARY_REQUEST_GOAL7 = 'SUMMARY_REQUEST_GOAL7';
export const SUMMARY_SUCCESS_GOAL7 = 'SUMMARY_SUCCESS_GOAL7';
export const SUMMARY_FAIL_GOAL7 = 'SUMMARY_FAIL_GOAL7';

export const SELECTED_VARIABLES_REQUEST_GOAL7 = 'SELECTED_VARIABLES_REQUEST_GOAL7';
export const SELECTED_VARIABLES_SUCCESS_GOAL7 = 'SELECTED_VARIABLES_SUCCESS_GOAL7';
export const SELECTED_VARIABLES_FAIL_GOAL7 = 'SELECTED_VARIABLES_FAIL_GOAL7';

export const CORRELATION_ALL_VARIABLES_REQUEST_GOAL7 = 'CORRELATION_ALL_VARIABLES_REQUEST_GOAL7';
export const CORRELATION_ALL_VARIABLES_SUCCESS_GOAL7 = 'CORRELATION_ALL_VARIABLES_SUCCESS_GOAL7';
export const CORRELATION_ALL_VARIABLES_FAIL_GOAL7 = 'CORRELATION_ALL_VARIABLES_FAIL_GOAL7';

export const GOAL_RESULT_REQUEST = 'GOAL_RESULT_REQUEST';
export const GOAL_RESULT_SUCCESS = 'GOAL_RESULT_SUCCESS';
export const GOAL_RESULT_FAIL = 'GOAL_RESULT_FAIL';

export const SELECT_TRAINED_MODEL = 'SELECT_TRAINED_MODEL';
export const USER_LOGIN_SAVE = 'USER_LOGIN_SAVE';

export const USER_LOGOUT = 'USER_LOGOUT';
