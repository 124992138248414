import { sendVariablesApiGoal6 } from "../../../utils/api";
import { GET_CHART_RESULT_FAIL_GOAL6, GET_CHART_RESULT_REQUEST_GOAL6, GET_CHART_RESULT_SUCCESS_GOAL6 } from "../../constants";

// result action for goal 6
export const getResultGoal6 = (variables) => async(dispatch) => {
    dispatch({
        type: GET_CHART_RESULT_REQUEST_GOAL6
    })

    try{
      const {data} = await sendVariablesApiGoal6(variables);
      dispatch({
          type: GET_CHART_RESULT_SUCCESS_GOAL6,
          payload: data
      });
    }
    catch(error){
        dispatch({
            type: GET_CHART_RESULT_FAIL_GOAL6,
            payload: error.message,
        });
    }
}