import { GET_CHART_RESULT_FAIL_GOAL6, GET_CHART_RESULT_REQUEST_GOAL6, GET_CHART_RESULT_SUCCESS_GOAL6, RESET_DATA_GOAL6 } from "../../constants"

// result reducer for goal 6
export const gotModalResultReducerGoal6 = (state = {hasResultGoal6: []}, action) => {
    switch(action.type){
        case GET_CHART_RESULT_REQUEST_GOAL6:
            return {loading: true}
        case GET_CHART_RESULT_SUCCESS_GOAL6:
            return{loading: false, hasResultGoal6: action.payload }
        case GET_CHART_RESULT_FAIL_GOAL6:
            return {loading : false, error: action.payload}
        case RESET_DATA_GOAL6:
            return {loading : false, hasResultGoal6: []}
        default:
            return state
    }
}
