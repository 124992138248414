import { useEffect } from 'react'
import capitalize from '../../utils/capitalize'
import * as d3 from 'd3'

const LineChart = ({title,yLabel,dataset,styleProps}) => {
    const margin = {top: 90, right: 0, bottom: 180, left: 150}
    const change = (str) => {
        return capitalize(str.replace(/_/g, ' '))
    }
    useEffect(() => {  
        let svg = d3.select('.line-chart svg')
        svg.selectAll("*").remove();

        var chartHeight = 640;
        var viewBoxHeight = 650;

        var width = 950 - margin.left - margin.right;
        var height = chartHeight - margin.top - margin.bottom;

        svg = svg
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `80 25 950 ${viewBoxHeight}`)
        .append("g")
        .attr("width", "100%")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        

        var xScale = d3.scaleBand().domain(dataset.map(d => change(d[0]))).range([0, width])
        var yScale = d3.scaleLinear().domain(d3.extent(dataset.map(d => d[1]))).range([height, 0])


        svg.append('text')
        .attr('x', (width / 2) + 35)
        .attr('y', -20)
        .attr('text-anchor', 'middle')
        .style('font-size','2rem')
        .text(title);

        //x axis
        let xAxis = svg.append("g")
         .attr("transform", "translate(20," + height + ")")
         .call(d3.axisBottom(xScale).tickSizeOuter(0))
         .call(g => g.select(".domain").style('stroke','#ffffff').style("transform", "translate(10px,0px)"))

        //removing ticks in x axis
        xAxis.selectAll('line') 
        .style('display', 'none')

        xAxis.selectAll('text') 
        .style('transform', 'translateY(100px) rotate(-90deg)')
        .style('font-size', '.9rem')
        
        let yAxis = svg.append("g")
         .call(d3.axisLeft(yScale))
         .call(g => g.select(".domain").remove())

        //removing ticks in y axis
        yAxis.selectAll('line') 
         .style('display', 'none')

        yAxis.selectAll('text') 
         .style('font-size', '.9rem')

        //adding dots in the line 
        svg.append('g')
        .selectAll("dot")
        .data(dataset)
        .enter()
        .append("circle")
            .attr("cx", function (d) { return xScale(change(d[0])); } )
            .attr("cy", function (d) { return yScale(d[1]); } )
            .attr("r", 5)
            .attr("fill-opacity", 1)
            .attr("fill", "transparent")
            .attr("stroke-width", 2)
            .attr("stroke", "#ffffff")
            .style("transform", "translate(30px,0px)")
        .on("mouseover",(e,d) => {mouseover(e,d,yLabel)})
        .on("mousemove",(e,d) => {mousemove(e,d)})
        .on("mouseleave",(e,d) => {mouseleave(e,d)})

        // console.log(yScale.ticks(5))

        //adding grid lines in the chart
        svg.selectAll("line.horizontalGrid").data(yScale.ticks(5)).enter()
        .append("line")
            .attr('class','horizontalGrid')
            .attr('x1',margin.right)
            .attr('x2',width)
            .attr('y1',function(d){ return yScale(d)})
            .attr('y2',function(d){ return yScale(d)})
            .attr('fill','none')
            .attr('stroke','#ffffff')
            .attr('stroke-width','1px')
            .attr('shape-rendering','crispEdges')
            .style("transform", "translate(30px,0px)")


        var line = d3.line()
        .x(function(d) { return xScale(change(d[0])); }) 
        .y(function(d) { return yScale(d[1]); }) 

        
        // line
        svg.append("path")
        .datum(dataset) 
        .attr("class", "line") 
        .attr("d", line)
        .style("fill", "none")
        .style("stroke", "#ffffff")
        .style("stroke-width", "4")    
        .style("transform", "translate(30px,0px)");    
 
        // tooltip on hover
        const tooltip = d3.select(".tooltip")
        .style("opacity", 0)
        .attr("class", "tooltip")

        var mouseover = (e,d,y) => {
            tooltip
                .html(change("ind_vars") + ": " + change(d[0]) + "<br>" + y + ": " + d[1])
                .style("opacity", 1)
          }
          var mousemove = (e,d) => {
            tooltip
              .style("left", (d3.pointer(e)[0]) + "px") 
              .style("top", (d3.pointer(e)[1]) + "px")
          }
          var mouseleave = () => {
            tooltip
              .style("opacity", 0)
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataset])
    return (
        <div style={styleProps} className='line-chart'>
            <svg></svg>
            <div className='tooltip'></div>
        </div>
    )
}
export default LineChart