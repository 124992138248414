import React, { useEffect, useState } from 'react';
import './SelectVariableScreen.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Dimension from '../../../Images/dimension.png';
import Data from '../../../Images/icons8-document-60.png';
import Count from '../../../Images/count.png';
import IndVardsScreenNewProject from './IndVarsScreenNewProject/IndVardsScreenNewProject';
import help from '../../../Images/help2.svg';
// import HelpTooltip from '../../HelpTooltip';

function SelectVariableScreen({ setisNextButtonDisabled, newProjectState, setNewProjectState }) {


    const [open, setOpen] = useState(false);
    const [validationMsg, setValidationMsg] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [depVarList, setDepVarList] = useState([])



  const handleChangefeatureSelectionValue = (event) => {
    setNewProjectState((current) => {
        return ({
            ...current,
            featureSelectionValue: event.target.value
        })
    })
  };


  const handleChangeprojectTypeValue = (event) => {

    setNewProjectState((current) => {
        return ({
            ...current,
            projectType: event.target.value,
            DependentVar:[]
        })
    })
  };


 


    const handleChange = (event) => {
        const {
            target: { value },
          } = event;
        setNewProjectState((current) => {
            return ({
                ...current,
                DependentVar:  value,
            })
        })
    };



    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };



    useEffect(() => {
        if (newProjectState.DependentVar.length === 0) {
            setisNextButtonDisabled(true)
            setValidationMsg('Please select a Dependent Variable')
        } else if(newProjectState.IndependentVar.length < 2){
            setisNextButtonDisabled(true)
            setValidationMsg('Please select atleast two checkboxes')
        } else {
            setValidationMsg('')
            setisNextButtonDisabled(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState.DependentVar, newProjectState.IndependentVar])


    
   
   useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const list= newProjectState.projectType === 'Binary Classification Problem' ? newProjectState.BinaryClassification_Vars : newProjectState.Regression_Vars;
    setDepVarList(list)
   if(depVarList.length === 0){
    setIsDisabled(true);


   }else{
    setIsDisabled(false)
   }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[isDisabled,depVarList,newProjectState.projectType])

    return (
        <div className='thirdScreenContainer'>
            <div className='dataStatsContainer'>
                <div className='dataDimension'>
                    <p className='name'>Data Dimensions</p>
                    <img src={Dimension} alt="dimension-icon" />
                    <p className='value'>{`${newProjectState.NumberOfRows} x ${newProjectState.NumberOfColumns}`}</p>
                </div>
                <div className='dataSize'>
                    <p className='name'>Size</p>
                    <img src={Data} alt="size-icon" />
                    <p className='value'>{`${newProjectState.fileDetail[0].size / 1000000} MB`}</p>
                </div>
                <div className='VarsCount'>
                    <p className='name'>Number Of Variables</p>
                    <img src={Count} alt="count-icon" />
                    <p className='value'>{newProjectState.AllVars.length}</p>
                </div>
            </div>
          
             <div className='radioButtonContainer'>
                <div className='featureSelectionContainer'>
                    <p>Feature Selection <Tooltip title="Feature Selection is the method of reducing the input variable to your model by using only relevant data and getting rid of noise in data." arrow><img style={{width: '14px'}} src={help} alt='icon' /></Tooltip></p>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={newProjectState.featureSelectionValue}
                            onChange={handleChangefeatureSelectionValue}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className='featureSelectionContainer'>
                    <p>Project Type</p> 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={newProjectState.projectType}
                            onChange={handleChangeprojectTypeValue}
                        >
                            <p style={{margin: '0px'}}><FormControlLabel value="Binary Classification Problem" control={<Radio />} label="Binary Classification Problem" /><Tooltip title="Binary classification refers to predicting one of two classes." arrow><img style={{width: '14px'}} src={help} alt='icon' /></Tooltip></p>
                            <p style={{margin: '0px', textAlign: 'left'}}><FormControlLabel value="Regression Problem" control={<Radio />} label="Regression Problem" /><Tooltip title="A regression problem is when the output variable is a real or continuous value, such as “salary” or “weight”." arrow><img style={{width: '14px'}} src={help} alt='icon' /></Tooltip></p>
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className='dependentVarContainer'>
                <FormControl sx={{ m: 1, minWidth: 120 }}  style={{minWidth: '80%', }} >
                    <InputLabel id="demo-controlled-open-select-label"  >Dependent Variable</InputLabel>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={newProjectState.DependentVar}
                        label="Dependent Variable"
                        onChange={handleChange}
                        disabled={isDisabled}
                    
                    >

{/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
           labelId="demo-controlled-open-select-label"
           id="demo-multiple-checkbox"
           multiple
           open={open}
           onClose={handleClose}
           onOpen={handleOpen}
           value={newProjectState.DependentVar}
           label="Dependent Variable"
           onChange={handleChange}
         renderValue={(selected) => selected.join(', ')}
        //   MenuProps={MenuProps}
     > */}
                        {depVarList.map((item, i) => {
                            return (<MenuItem value={item} key={i}>{item} </MenuItem>)
                        }

                        )}
                    </Select>
                    {(newProjectState.DependentVar.length === 0 && !isDisabled)&& <FormHelperText>Please Select A Dependent Variable</FormHelperText>}
                </FormControl>
            </div>
        
            <div className='indVarContainder'>
                <IndVardsScreenNewProject newProjectState={newProjectState} setNewProjectState={setNewProjectState} />
                <p className='infoMsg'>{isDisabled?'No Variable available to be selected as Dependent Variable ': validationMsg}</p>
            </div>

        </div>
    );
}

export default SelectVariableScreen;