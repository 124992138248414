import capitalize from "./capitalize";
import HelpTooltip from '../Components/SubComponent/HelpTooltip'
import TruncateTooltip from '../Components/SubComponent/TruncateTooltip'

export const getTrainedModelsHeaders = (handleViewButtonClick) => {
    const headerContent = [];
    // in case of different headers for each goal
    headerContent.push({
        Header: 'Model Name',
        accessor: 'name'
    },
        {
            Header: 'Accuracy',
            accessor: (row) => (row.accuracy).toFixed(2)
        },
        {
            id: 'Dep Variable',
            Header: <p className="table-header-tooltip">Dependent Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
            accessor: (row) => {
                if (row.dependentVariables) return capitalize(row.dependentVariables.replaceAll('_', ' '))
                return ''
            }
        },
        {
            id: 'Ind. Variable',
            Header: <p className="table-header-tooltip">Independent Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
            accessor: (row) => {
                if (row.independentVariables) {

                    let ind = '';
                    row.independentVariables.forEach((element, i, arr) => {

                        ind += capitalize(element.replaceAll('_', ' '))
                        if (i !== arr.length - 1) {
                            ind += ', '
                        }
                    });
                    return <TruncateTooltip content={ind} />
                }


                return ''
            }
        },
        {
            Header: 'Check prediction',
            accessor: () => 'coming soon',
        }, {
        Header: 'Customize ML model',
        accessor: () => 'coming soon',
    }, {
        Header: 'Action',
        accessor: (row) => {

            return <button className='trainedmodels-view' onClick={() => handleViewButtonClick(row)}><span>View</span></button>
        },
    }
    )

    return headerContent;
}

export const getModelsAccuracies = (goal) => {
    let ModelsAccuracies = []
    switch (goal) {
        case 'goal1':
            ModelsAccuracies.push({ name: 'DecisionTree Classifier', accuracy: 90.70 })
            ModelsAccuracies.push({ name: 'Logistic Regression', accuracy: 99.77 })
            ModelsAccuracies.push({ name: 'Random Forest', accuracy: 99.70 })
            ModelsAccuracies.push({ name: 'SGD Classifier', accuracy: 99.62 })
            ModelsAccuracies.push({ name: 'Gaussian Naive Bayes', accuracy: 92.17 })
            break;
        case 'goal2':
            ModelsAccuracies.push({ name: 'Logistic Regression', accuracy: 98.60 })
            ModelsAccuracies.push({ name: 'Gradient Boosting Classifier', accuracy: 98 })
            ModelsAccuracies.push({ name: 'XGBoost Classifier', accuracy: 96.78 })
            ModelsAccuracies.push({ name: 'Random Forest Classifier', accuracy: 90.81 })
            ModelsAccuracies.push({ name: 'Gaussian Naive Bayes', accuracy: 56.87 })
            break;
        case 'goal3':
            ModelsAccuracies.push({ name: 'Random Forest', accuracy: 98.9275 })
            ModelsAccuracies.push({ name: 'XGBoost Regressor', accuracy: 97.5679 })
            ModelsAccuracies.push({ name: 'Lasso Regression', accuracy: 95.0340 })
            ModelsAccuracies.push({ name: 'ElasticNet Regression', accuracy: 95.0340 })
            ModelsAccuracies.push({ name: 'Logistic Regression', accuracy: 95.0296 })
            ModelsAccuracies.push({ name: 'Ridge Regression', accuracy: 95.0295 })
            break;
        case 'goal4':
            ModelsAccuracies.push({ name: 'Random Forest', accuracy: 99.315 })
            ModelsAccuracies.push({ name: 'Decision Tree Classifier', accuracy: 99.036 })
            ModelsAccuracies.push({ name: 'SGD Classifier', accuracy: 95.643 })
            ModelsAccuracies.push({ name: 'Logistic Regression', accuracy: 95.574 })
            ModelsAccuracies.push({ name: 'Gaussian Naive Bayes', accuracy: 85.161 })
            break;
        case 'goal5':
            ModelsAccuracies.push({ name: 'SGD Classifier', accuracy: 98.55 })
            ModelsAccuracies.push({ name: 'Logistic Regression', accuracy: 98.82 })
            ModelsAccuracies.push({ name: 'Decision Tree Classifier', accuracy: 99.36 })
            ModelsAccuracies.push({ name: 'Gaussian Naive Bayes', accuracy: 54.94 })
            ModelsAccuracies.push({ name: 'Random Forest', accuracy: 99.52})
            break;
        case 'goal6':
            ModelsAccuracies.push({ name: 'SGD Classifier', accuracy: 98.79 })
            ModelsAccuracies.push({ name: 'Logistic Regression', accuracy: 98.91 })
            ModelsAccuracies.push({ name: 'Decision Tree Classifier', accuracy: 99.62 })
            ModelsAccuracies.push({ name: 'Gaussian Naive Bayes', accuracy: 95.40 })
            ModelsAccuracies.push({ name: 'Random Forest Classifier', accuracy: 99.74 })
            ModelsAccuracies.push({ name: 'XGBoost Classifier', accuracy: 99.73 })
            ModelsAccuracies.push({ name: 'Gradient Boosting Classifier', accuracy: 98.81 })
            break;
        case 'goal7':
            ModelsAccuracies.push({ name: 'Random Forest', accuracy: 99.3876650636755 })
            ModelsAccuracies.push({ name: 'XGBoost Regressor', accuracy: 98.5491075708129 })
            ModelsAccuracies.push({ name: 'Linear Regression', accuracy: 95.6008550253934 })
            ModelsAccuracies.push({ name: 'Elastic Net', accuracy: 95.5873174076038 })
            ModelsAccuracies.push({ name: 'Lasso', accuracy: 95.5873174076038 })
            ModelsAccuracies.push({ name: 'Ridge', accuracy: 95.6008558243243 })
        default:


    }
    return ModelsAccuracies
}