import { getSummaryApiGoal3 } from "../../../utils/api";
import {SUMMARY_FAIL_GOAL3, SUMMARY_REQUEST_GOAL3, SUMMARY_SUCCESS_GOAL3 } from "../../constants";

// summary action for goal 3
export const getSummaryGoal3 = () => async(dispatch) => {
    dispatch({
        type: SUMMARY_REQUEST_GOAL3
    })

    try{
      const {data} = await getSummaryApiGoal3();
      dispatch({
          type: SUMMARY_SUCCESS_GOAL3,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SUMMARY_FAIL_GOAL3,
            payload: error.message
        })
    }
}