import React, { useState } from 'react';
import '../ResultScreen/resultScreen.css';
import resultBase from '../../Images/resultBase.svg';
import ModelResult from './ModelResultTab/ModelResultTab';
import MasterPage from '../../MasterPage';
import { useEffect } from 'react/cjs/react.development';
import { useNavigate, useLocation  } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { getGoalResult } from '../../../Redux/Reducers/GoalResult/GoalResultAction'

function ResultScreen({setCurrentlyActiveTab,currentlyActiveTab, selectedGoal,setSelectedGoal,savedVariables}) {
    const dispatch = useDispatch();
    const [result ,setResult] = useState();
    const [isloading, setIsLoading] = useState(true);
    let navigate = useNavigate();
    let location = useLocation();

    const goalResultReducer = useSelector(state => state.goalResultReducer);
    const { loading, error: error, goalResult } = goalResultReducer;

    const  SelectedTrainedModelReducer = useSelector(state => state. SelectedTrainedModelReducer);
    const {  selectedTrainedModel } =  SelectedTrainedModelReducer;

    const handleButtonClick = () => {
        navigate('/Predict')
        // navigate('/Predict', { state: {from: location.state.from,modelName: location.state.modelName}})
    }
    
    useEffect(() => {
        // if(!location.state) return
       
        dispatch(getGoalResult(selectedTrainedModel.from))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTrainedModel])

    useEffect(() => {
        if(!loading && goalResult && goalResult[0] && goalResult[0].result){
         
            setIsLoading(false)
            setResult(goalResult[0].result)
        }
    },[loading])

    const override = css`position: absolute; transform: translate(-20%, 300%);`;
  
    return (
       
        <>
           <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} needSidebar={false} />
                
            <div className='screen nosidebar-screen savedresult-modelresult'>
                 <div style={{
                    background: `url(${resultBase})`,
                }}>
                    <div className='modelresult-header'>
                    <label className='table-title'>Model Result</label>
                    <button className='view-variables-button' onClick={handleButtonClick}>View Predict</button>
                    </div>
                </div>
              {(!isloading) ?  <ModelResult selectedGoal={selectedGoal} result={result} /> : <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isloading} css={override} /></div>}
             
            
            </div>
        </>
    ); 
}

export default ResultScreen;