import { sendVariablesApiGoal2 } from "../../../utils/api";
import { GET_CHART_RESULT_FAIL_GOAL2, GET_CHART_RESULT_REQUEST_GOAL2, GET_CHART_RESULT_SUCCESS_GOAL2 } from "../../constants";

// result action for goal 2
export const getResultGoal2 = (variables) => async(dispatch) => {
    dispatch({
        type: GET_CHART_RESULT_REQUEST_GOAL2
    })

    try{
      const {data} = await sendVariablesApiGoal2(variables);

      dispatch({
          type: GET_CHART_RESULT_SUCCESS_GOAL2,
          payload: data
      });
    }
    catch(error){
        dispatch({
            type: GET_CHART_RESULT_FAIL_GOAL2,
            payload: error.message,
        });
    }
}