import React, { useState } from 'react';
import './resultScreen.css';
import model from '../../Images/icon_1.svg';
import charticon from '../../Images/icon_2.svg';
import resulticon from '../../Images/icon_3.svg';
import resultBase from '../../Images/resultBase.svg';
import ChartScreen from './ChartScreen/ChartScreen';
import PerdictiveModels from '../PerdictiveModels';
import ModelResult from '../ModelResult';
import MasterPage from '../../MasterPage';
import { useEffect } from 'react/cjs/react.development';
import { useNavigate, useParams, useLocation  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Popup from 'reactjs-popup';

function ResultScreen({setCurrentlyActiveTab,currentlyActiveTab, selectedGoal,setSelectedGoal,savedVariables}) {

    const [tab ,setTab] = useState();
    const [isloading, setIsLoading] = useState(true);
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    const haveResult = useSelector(state => state.gotModalResultReducer);
    const {loading } = haveResult;

    // const haveResultGoal2 = useSelector(state => state.gotModalResultReducerGoal2);
    // const {loading: loadingGoal2, hasResultGoal2: hasResultGoal2 } = haveResultGoal2;

    const haveResultGoal2 = useSelector(state => state.gotModalResultReducerGoal2);
    const {loading: loadingGoal2 } = haveResultGoal2;

    const haveResultGoal3 = useSelector(state => state.gotModalResultReducerGoal3);
    const {loading: loadingGoal3 } = haveResultGoal3;

    const haveResultGoal4 = useSelector(state => state.gotModalResultReducerGoal4);
    const {loading: loadingGoal4 } = haveResultGoal4;

    const haveResultGoal5 = useSelector(state => state.gotModalResultReducerGoal5);
    const {loading: loadingGoal5 } = haveResultGoal5;

    const haveResultGoal6 = useSelector(state => state.gotModalResultReducerGoal6);
    const {loading: loadingGoal6 } = haveResultGoal6;

    // selecting the predictive models tab
    const handleClickPerdictive_Models = () => {
        navigate(`/${selectedGoal}/result/Perdictive_Models`)
        setCurrentlyActiveTab("Perdictive_Models");
        setTab("Perdictive_Models")
    }

    // selecting the comaprison tab
    const handleClickComparision = () => {
        navigate(`/${selectedGoal}/result/Comparision`)
        setCurrentlyActiveTab("Comparision");
        setTab("Comparision")
    }

    // selecting the model result tab
    const handleClickModel_Result = () => {
        navigate(`/${selectedGoal}/result/Model_Result`)
        setCurrentlyActiveTab("Model_Result");
        setTab("Model_Result")
    }

    // setting selected goal based on the route
    useEffect(() => {
        if(!selectedGoal.length && location.pathname.split('/')[1] && location.pathname.split('/')[1].includes('goal')){
            setSelectedGoal(location.pathname.split('/')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])

    //handling tab change
    useEffect(()=> {
        
       if(params.tab === 'Perdictive_Models'){
        setCurrentlyActiveTab("Perdictive_Models");
        setTab("Perdictive_Models") 
       }

       if(params.tab === 'Comparision'){
        setCurrentlyActiveTab("Comparision");
        setTab("Comparision") 
       }

       if(params.tab === 'Model_Result'){
        setCurrentlyActiveTab("Model_Result");
        setTab("Model_Result") 
       }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[params,selectedGoal])

    useEffect(() => {
        if(selectedGoal === 'goal1' && !loading){
            setIsLoading(false);
        }
        if(selectedGoal === 'goal2' && !loadingGoal2){
            setIsLoading(false);
        }
        if(selectedGoal === 'goal3' && !loadingGoal3){
            setIsLoading(false);
        }
        if(selectedGoal === 'goal4' && !loadingGoal4){
            setIsLoading(false);
        }
        if(selectedGoal === 'goal5' && !loadingGoal5){
            setIsLoading(false);
        }
        if(selectedGoal === 'goal6' && !loadingGoal6){
            setIsLoading(false);
        }
    },[selectedGoal,loading,loadingGoal2,loadingGoal3,loadingGoal4,loadingGoal5,loadingGoal6])

    const override = css`position: absolute; transform: translate(-20%, 300%);`;

    const [open, setOpen] = useState(true);
    const closeModal = () => setOpen(false);
  
    return (
       
        <>
           <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} isResultLoading={isloading} />
                
            <div className='resultscreen screen'>
                <div className='tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <div className={`tab ${tab === "Comparision" && "active"}`} onClick={handleClickComparision}>
                        <img src={charticon} className='icon-small' alt='chart' />
                        <span className="tabStyle">
                            <label>Comparison</label>
                        </span>
                    </div>
                    <div className={`tab ${tab === "Model_Result" && "active"}`} onClick={handleClickModel_Result}>
                    <img src={resulticon} className='icon-small' alt='chart' />
                    <span className="tabStyle">
                        <label>Model Result</label>

                    </span>
                    </div>
                    <div className={`tab ${tab === "Perdictive_Models" && "active"}`} onClick={handleClickPerdictive_Models}>
                        <img src={model} className='icon-small' alt='model' />
                        <span className="tabStyle"><label>Predictive Models</label></span>
                    </div>
                </div>
                
              {isloading && <Popup open={open} closeOnDocumentClick onClose={closeModal} className='result-model'>
                <div className="modal">
                    <button className="close" onClick={closeModal}>
                    &times;
                    </button>
                    <p>We are training the model based on your selection. will populate the trained model results when it is finished. Estimated time is 10 minutes. Please do not close the window.</p>
                </div>
                </Popup>}
              {tab === "Perdictive_Models" &&  <PerdictiveModels /> }
              {(tab === "Model_Result" && !isloading) ?  <ModelResult selectedGoal={selectedGoal} /> : <div>{!open && <ScaleLoader color={"#fff"} height={40} width={5} loading={isloading} css={override} />}</div> }
              {(tab === "Comparision" && !isloading) ?  <ChartScreen selectedGoal={selectedGoal} /> : <div>{!open && <ScaleLoader color={"#fff"} height={40} width={5} loading={isloading} css={override} />}</div> }
            
            </div>
        </>
    ); 
}

export default ResultScreen;