import { getCorrelationAllVar } from "../../../utils/api";
import { CORRELATION_ALL_VARIABLES_FAIL, CORRELATION_ALL_VARIABLES_REQUEST, CORRELATION_ALL_VARIABLES_SUCCESS } from "../../constants";

// correlation action for goal 1
export const Correlation_getAllVariable = () => async(dispatch) => {
    dispatch({
        type: CORRELATION_ALL_VARIABLES_REQUEST
    })

    try{
      const {data} = await getCorrelationAllVar();
      dispatch({
          type: CORRELATION_ALL_VARIABLES_SUCCESS,
          payload: data
      });
    }catch(error){
        dispatch({
            type: CORRELATION_ALL_VARIABLES_FAIL,
            payload: error.message
        })
    }
}