import {useState} from 'react';
import logo from '../Images/logo.png';
import thumb from '../Images/thumb.png';
import arrow3 from '../Images/arrow3.png';
import base2 from '../Images/base2.png';
import './header.css';
import { useDispatch } from 'react-redux';
import { RESET_DATA, RESET_DATA_GOAL2, RESET_DATA_GOAL3, RESET_DATA_GOAL4 } from '../../Redux/constants'
import { useNavigate } from 'react-router-dom';
import { saveResultApi } from '../../utils/api.js'
import { useSelector } from 'react-redux';
import save from '../Images/save-svgrepo-com.svg'
import { Popup } from 'reactjs-popup'
import {ClipLoader} from 'react-spinners'
import tick from '../Images/tick (2).png'
import { logOutUser } from '../../Redux/Reducers/UserLogin/UserAction';

//header of each page
function Header({ selectedGoal,setSelectedGoal, savedVariables, isResultLoading = true }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');
  const [isMenuActive, setIsMenuActive] = useState(false)

  const closeModal = () => { setOpen(false) }

  const haveResult = useSelector(state => state.gotModalResultReducer);
  const { hasResult } = haveResult;

  const haveResultGoal2 = useSelector(state => state.gotModalResultReducerGoal2);
  const { hasResultGoal2 } = haveResultGoal2;

  const haveResultGoal3 = useSelector(state => state.gotModalResultReducerGoal3);
  const { hasResultGoal3 } = haveResultGoal3;

  const haveResultGoal4 = useSelector(state => state.gotModalResultReducerGoal4);
  const { hasResultGoal4 } = haveResultGoal4;

  const haveResultGoal5 = useSelector(state => state.gotModalResultReducerGoal5);
  const { hasResultGoal5 } = haveResultGoal5;

  const haveResultGoal6 = useSelector(state => state.gotModalResultReducerGoal6);
  const { hasResultGoal6 } = haveResultGoal6;


  const handleClickLogout = () => {
    dispatch(logOutUser());
    localStorage.removeItem("userInfo");
    navigate('/login');
  }

  const handleClickProfile = () => {
    navigate('/viewprofile');
  }

  // resetting the results for each goal and navigating to /
  const handleReset = () => {
      navigate('/')
      dispatch({
          type:RESET_DATA
      });
      dispatch({
          type:RESET_DATA_GOAL2
      });
      dispatch({
          type:RESET_DATA_GOAL3
      });
      dispatch({
          type:RESET_DATA_GOAL4
      });
      setSelectedGoal('')
  }
  const handleSaveResultClick = async () => {
    
    setOpen(true)
    
    let result
    
    if(selectedGoal === 'goal1'){
      
        result = hasResult
    } 
    else if(selectedGoal === 'goal2'){
        result = hasResultGoal2
    }
    else if(selectedGoal === 'goal3'){
        result = hasResultGoal3
    }
    else if(selectedGoal === 'goal4'){
        result = hasResultGoal4
    }
    else if(selectedGoal === 'goal5'){
        result = hasResultGoal5
    }
    else if(selectedGoal === 'goal6'){
        result = hasResultGoal6
    }


    setTimeout(
        async function() {
            const response = await saveResultApi({...savedVariables,goal: selectedGoal,result: result})
        
            if(response.data){
                setLoading(false)
                setMsg(response.data.msg)
                setTimeout(
                    function() {
                        setOpen(false);
                    },
                    3000
                );
            }
        },
        3000
    );
    
   setMsg('')
    setLoading(true)
    
  }

  return (
      <div className='main-header' style={{
          background: `url(${base2})`,
        }}>
          <img onClick={handleReset} className="logo" src={logo} alt='' />
          
          <div>
            <h1 className='title'>Dashboard</h1>
            {!isResultLoading && <button className="save-result" onClick={handleSaveResultClick}>
                <img src={save} alt='save-icon' />
            </button>}
            <img className="arrow-2" src={arrow3}  alt='' onClick={() => {setIsMenuActive(!isMenuActive)}}/>
            <img className="thumb" src={thumb} alt=''  />
            {isMenuActive && <div className='menu'>
                <p  onClick={handleClickProfile}>Profile</p>
                <p onClick={handleClickLogout}>Logout</p>
            </div>}
          </div>
          <Popup open={open} closeOnDocumentClick onClose={closeModal} className='saveresult-model'>
            <div className="modal">
            <a className="close" href=" " onClick={closeModal}>
                &times;
            </a>
         
            <ClipLoader color={'#3EB6C6'} loading={loading} size={65} />
            {!loading && <img style={{width: '4em'}} src={tick} alt='tick' /> }
            <p>{msg}</p>
            </div>
        </Popup>
      </div>
  );
}

export default Header;