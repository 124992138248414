import { GET_CHART_RESULT_FAIL_GOAL4, GET_CHART_RESULT_REQUEST_GOAL4, GET_CHART_RESULT_SUCCESS_GOAL4, RESET_DATA_GOAL4 } from "../../constants"

// result reducer for goal 4
export const gotModalResultReducerGoal4 = (state = {hasResultGoal4: []}, action) => {
    switch(action.type){
        case GET_CHART_RESULT_REQUEST_GOAL4:
            return {loading: true}
        case GET_CHART_RESULT_SUCCESS_GOAL4:
            return{loading: false, hasResultGoal4: action.payload }
        case GET_CHART_RESULT_FAIL_GOAL4:
            return {loading : false, error: action.payload}
        case RESET_DATA_GOAL4:
            return {loading : false, hasResultGoal4: []}
        default:
            return state
    }
}
