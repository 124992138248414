import { sendVariablesApiGoal5 } from "../../../utils/api";
import { GET_CHART_RESULT_FAIL_GOAL5, GET_CHART_RESULT_REQUEST_GOAL5, GET_CHART_RESULT_SUCCESS_GOAL5 } from "../../constants";

// result action for goal 5
export const getResultGoal5 = (variables) => async(dispatch) => {
    dispatch({
        type: GET_CHART_RESULT_REQUEST_GOAL5
    })

    try{
      const {data} = await sendVariablesApiGoal5(variables);
     
      dispatch({
          type: GET_CHART_RESULT_SUCCESS_GOAL5,
          payload: data
      });
    }
    catch(error){
        dispatch({
            type: GET_CHART_RESULT_FAIL_GOAL5,
            payload: error.message,
        });
    }
}