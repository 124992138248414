/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './SavedResultScreen.css'
import resultBase from '../../Images/resultBase.svg';
import { useSelector,useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getCorrelationHeaders } from '../../../utils/getCorrelationHeaders';
import Table from '../HomeScreen/Table/Table';
import MasterPage from '../../MasterPage';
import { useNavigate, useLocation } from 'react-router-dom';
import { Correlation_getAllVariable } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarAction';
import { Correlation_getAllVariableGoal2 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal2';
import { Correlation_getAllVariableGoal3 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal3';
import { Correlation_getAllVariableGoal4 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal4';
import { Correlation_getAllVariableGoal5 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal5';
import { Correlation_getAllVariableGoal6 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal6';
import { Correlation_getAllVariableGoal7 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal7';

function Correlation({setCurrentlyActiveTab,currentlyActiveTab, selectedGoal,setSelectedGoal}) {

    const dispatch = useDispatch();
    const [header, setHeader] = useState();
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const correlation_allVariable = useSelector(state => state.correlation_allVariableReducer);
    const { loading, CorrelationallVariableList } = correlation_allVariable;

    const correlation_allVariable_goal2 = useSelector(state => state.correlation_allVariableReducerGoal2);
    const {loading: loadingGoal2, CorrelationallVariableListGoal2} = correlation_allVariable_goal2;

    const correlation_allVariable_goal3 = useSelector(state => state.correlation_allVariableReducerGoal3);
    const {loading: loadingGoal3, CorrelationallVariableListGoal3} = correlation_allVariable_goal3;

    const correlation_allVariable_goal4 = useSelector(state => state.correlation_allVariableReducerGoal4);
    const {loading: loadingGoal4, CorrelationallVariableListGoal4} = correlation_allVariable_goal4;

    const correlation_allVariable_goal5 = useSelector(state => state.correlation_allVariableReducerGoal5);
    const {loading: loadingGoal5, CorrelationallVariableListGoal5} = correlation_allVariable_goal5;

    const correlation_allVariable_goal6 = useSelector(state => state.correlation_allVariableReducerGoal6);
    const {loading: loadingGoal6, CorrelationallVariableListGoal6} = correlation_allVariable_goal6;

    const correlation_allVariable_goal7 = useSelector(state => state.correlation_allVariableReducerGoal7);
    const {loading: loadingGoal7, CorrelationallVariableListGoal7} = correlation_allVariable_goal7;

    const  SelectedTrainedModelReducer = useSelector(state => state. SelectedTrainedModelReducer);
    const {  selectedTrainedModel } =  SelectedTrainedModelReducer;


    let location = useLocation();

    let navigate = useNavigate();

    const override = css`position: absolute; transform: translate(-20%, 300%);`;

    const handleButtonClick = () => {
        // navigate('/modelresult', { state: {from: location.state.from,modelName: location.state.modelName}})
        navigate('/modelresult')
    }

    useEffect(() => {
 
        // if(!location.state) return
        const columns = getCorrelationHeaders('All_var',selectedTrainedModel.from);
        
        setHeader(columns);
        switch(selectedTrainedModel.from){
            case 'goal1':
                dispatch(Correlation_getAllVariable())
                break;
            case 'goal2':
                dispatch(Correlation_getAllVariableGoal2())
                break;
            case 'goal3':
                dispatch(Correlation_getAllVariableGoal3())
                break;
            case 'goal4':
               
                dispatch(Correlation_getAllVariableGoal4())
                break;
            case 'goal5':
                dispatch(Correlation_getAllVariableGoal5())
                break;
            case 'goal6':
                dispatch(Correlation_getAllVariableGoal6())
                break;
            case 'goal7':
                dispatch(Correlation_getAllVariableGoal7())
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTrainedModel])

    //setting headers for table
    useEffect(() => {
    
        // if(!location.state) return
       
        if(selectedTrainedModel.from === 'goal1'){
            setRow(CorrelationallVariableList); setIsLoading(loading)
        }
        if(selectedTrainedModel.from === 'goal2'){
           setRow(CorrelationallVariableListGoal2); setIsLoading(loadingGoal2)
        }
        if(selectedTrainedModel.from === 'goal3'){
           setRow(CorrelationallVariableListGoal3);setIsLoading(loadingGoal3)
        }
        if(selectedTrainedModel.from === 'goal4'){
          
           
            setRow(CorrelationallVariableListGoal4); setIsLoading(loadingGoal4)
        }
        if(selectedTrainedModel.from === 'goal5'){
            setRow(CorrelationallVariableListGoal5); setIsLoading(loadingGoal5) 
        }
        if(selectedTrainedModel.from === 'goal6'){
            setRow(CorrelationallVariableListGoal6); setIsLoading(loadingGoal6)
        }
        if(selectedTrainedModel.from === 'goal7'){
            setRow(CorrelationallVariableListGoal7); setIsLoading(loadingGoal7)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading,loadingGoal2,loadingGoal3,loadingGoal4,loadingGoal5,loadingGoal6,loadingGoal7])

    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab}  currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal}  needSidebar={false} />
            <div className='screen nosidebar-screen correlation'>
                <div className='tableHeader savedresult-tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <label className='table-title'>Correlation</label>
                    <button className='view-variables-button' onClick={handleButtonClick}>View Model Results</button>
                </div>
                <div className="Container boundary">
                <div className='table-view__container'>
                    {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                        <>
                            {(header && row) && <Table columns={header} data={row} />}
                        </>
                    }
                </div>

            </div>
            </div>


        </>
    );
}

export default Correlation;