import { SUMMARY_FAIL_GOAL7, SUMMARY_REQUEST_GOAL7, SUMMARY_SUCCESS_GOAL7 } from "../../constants";

// summary reducer for goal 7
export const summaryReducerGoal7 = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST_GOAL7:
            return {loading: true}
        case SUMMARY_SUCCESS_GOAL7:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL_GOAL7:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}