import { ALL_VARIABLES_FAIL_GOAL2, ALL_VARIABLES_REQUEST_GOAL2, ALL_VARIABLES_SUCCESS_GOAL2 } from "../../constants";

// all variable reducer for goal 2
export const allVariableReducerGoal2 = (state = {allVariableList: []}, action) => {
    switch(action.type){
        case ALL_VARIABLES_REQUEST_GOAL2:
            return {loading: true}
        case ALL_VARIABLES_SUCCESS_GOAL2:
            return{loading: false, allVariableList: action.payload }
        case ALL_VARIABLES_FAIL_GOAL2:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}