import { sendVariablesApiGoal4 } from "../../../utils/api";
import { GET_CHART_RESULT_FAIL_GOAL4, GET_CHART_RESULT_REQUEST_GOAL4, GET_CHART_RESULT_SUCCESS_GOAL4 } from "../../constants";

// result action for goal 4
export const getResultGoal4 = (variables) => async(dispatch) => {
    dispatch({
        type: GET_CHART_RESULT_REQUEST_GOAL4
    })

    try{
      const {data} = await sendVariablesApiGoal4(variables);
      console.log(data);
      dispatch({
          type: GET_CHART_RESULT_SUCCESS_GOAL4,
          payload: data
      });
    }
    catch(error){
        dispatch({
            type: GET_CHART_RESULT_FAIL_GOAL4,
            payload: error.message,
        });
    }
}