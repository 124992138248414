import { ALL_VARIABLES_FAIL_GOAL4, ALL_VARIABLES_REQUEST_GOAL4, ALL_VARIABLES_SUCCESS_GOAL4 } from "../../constants";

// all variable reducer for goal 4
export const allVariableReducerGoal4 = (state = {allVariableList: []}, action) => {
    switch(action.type){
        case ALL_VARIABLES_REQUEST_GOAL4:
            return {loading: true}
        case ALL_VARIABLES_SUCCESS_GOAL4:
            return{loading: false, allVariableList: action.payload }
        case ALL_VARIABLES_FAIL_GOAL4:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}