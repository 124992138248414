import {Popup} from 'reactjs-popup'
import help from '../Images/help2.svg';

const Tooltip = ({content}) => (
    <Popup
      trigger={open => (
        <button className="button"><img style={{width: '14px'}} src={help} alt='icon' /></button>
      )}
      position="right center"
      on={['hover']}
      closeOnDocumentClick
      className='help-tooltip'
    >
      <span className='help-tooltip-body'>{content}</span>
    </Popup>
  );

export default Tooltip; 