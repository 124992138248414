import React from 'react';
import { useTable,useRowSelect,useSortBy } from 'react-table';
import './Table.css';
import IndeterminateCheckbox from './CheckBox';

function Table({ columns, data,currentTab, getVariables,sortedField='' }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows
      } = useTable({
        columns,
        data,
        initialState: {
      sortBy: [{ id:`${sortedField}`, desc: true }]
    }
      },
      useSortBy,
      useRowSelect,
      hooks => {
        if(currentTab !== 2) return
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          ...columns,
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div style={{display: 'flex',justifyContent: 'space-around'}}>
                Action <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div style={{margin : '0 40px'}}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          }
        ])
      }
      
      )

    var variablesToSend = [];
    React.useEffect(() => {
      selectedFlatRows.forEach(row => {
      
        variablesToSend.push(row.original.Independent_Variable)
      });
    
      if(currentTab !== 2) return;
      getVariables(variablesToSend)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedFlatRows])
    return (
        <table className='table-responsive' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();
                  return (
                    <th key={key} {...restColumn}>
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={key} {...restCellProps}>
                      <span>{cell.render("Cell")}</span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
}

export default Table;