import { getCorrelationAllVarGoal5 } from "../../../utils/api";
import { CORRELATION_ALL_VARIABLES_FAIL_GOAL5, CORRELATION_ALL_VARIABLES_REQUEST_GOAL5, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL5 } from "../../constants";

// correlation action for goal 5
export const Correlation_getAllVariableGoal5 = () => async(dispatch) => {
    dispatch({
        type: CORRELATION_ALL_VARIABLES_REQUEST_GOAL5
    })

    try{
      const {data} = await getCorrelationAllVarGoal5();
      dispatch({
          type: CORRELATION_ALL_VARIABLES_SUCCESS_GOAL5,
          payload: data
      });
    }catch(error){
        dispatch({
            type: CORRELATION_ALL_VARIABLES_FAIL_GOAL5,
            payload: error.message
        })
    }
}