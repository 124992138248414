import { getAllVariableApi } from "../../../utils/api";
import { ALL_VARIABLES_FAIL, ALL_VARIABLES_REQUEST, ALL_VARIABLES_SUCCESS } from "../../constants";

// all variable action for goal 1 
export const getAllVariable = () => async(dispatch) => {
    dispatch({
        type: ALL_VARIABLES_REQUEST
    })

    try{
      const {data} = await getAllVariableApi();
      dispatch({
          type: ALL_VARIABLES_SUCCESS,
          payload: data
      });
    }catch(error){
        dispatch({
            type: ALL_VARIABLES_FAIL,
            payload: error.message
        })
    }
}