import React, { useEffect, useState } from 'react';
import { getNewProjectsummarycategoricalVariablesHeaderHeader, getNewProjectsummaryHeader } from '../../../../utils/getNewProjectTableHeader';
import Table from '../../HomeScreen/Table/Table';
import './SummaryScreen.css';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';

function SummaryScreen({errorMsg,newProjectState,setisNextButtonDisabled}) {
    // eslint-disable-next-line no-unused-vars
    const [currentVar, setCurrentVar] = useState(0)
    const [numericalVariablesHeader, setNumericalVariablesHeader] = useState();
    const [numericalVariablesData, setNumericalVariablesData] = useState();

    const [categoricalVariablesHeader, setCategoricalVariablesHeader] = useState();
    const [categoricalVariablesData, setCategoricalVariablesData] = useState();

    const [isLoadingTable1, setIsLoadingTable1] = useState(false);
    const [isLoadingTable2, setIsLoadingTable2] = useState(false)



    useEffect(() => {
        const columns = getNewProjectsummaryHeader();
        setNumericalVariablesHeader(columns);
        if(Object.keys(newProjectState.summary).length === 0){
            setIsLoadingTable1(true)
            setIsLoadingTable2(true)
            setisNextButtonDisabled(true)
        }else{
            setIsLoadingTable1(false)
            setIsLoadingTable2(false)
            setisNextButtonDisabled(false)
        }
        setNumericalVariablesData(newProjectState?.summary[currentVar]?.SummaryStatsNumerical)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newProjectState,currentVar])

    useEffect(() => {
        const columns = getNewProjectsummarycategoricalVariablesHeaderHeader();
        setCategoricalVariablesHeader(columns);
        setCategoricalVariablesData(newProjectState?.summary[currentVar]?.SummaryStatsCategorical)

    },[currentVar, newProjectState])


    const override = css`position: absolute; transform: translate(-20%, 300%);`;
    const override2 = css`position: absolute; transform: translate(0%, 150%);`;


    const handleClickPreviousVar = () => {

        if(currentVar === 0){
             return;
        }

        setCurrentVar((prevState) => prevState-1)

    }


    const handleClickNextVar = () => {

        if(newProjectState.DependentVar.length === currentVar + 1) {return;}
        setCurrentVar((prevState) => prevState+1)

    }



    return (
        <div className='summaryScreenContainer'>
            {errorMsg.length === 0 ?
            <>
            <div className='SummaryStatsNumericalContainer'>
                <div className='SummaryStatsNumericalContainer-header'>
                <h2>Numerical Variables Summary</h2>
                </div>
                <div className='table-view__container'>
                {(numericalVariablesData && numericalVariablesHeader ) ? <Table columns={numericalVariablesHeader} data={numericalVariablesData} sortedField="Mean" /> : <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoadingTable1} css={override} /></div>}
                </div>
            </div>
            <div className='SummaryStatsCategoricalContainer'>
            <h2>Categorical Variables Summary</h2>
            <div className='table-view__container'>
                {(categoricalVariablesHeader && categoricalVariablesData ) ? <Table columns={categoricalVariablesHeader} data={categoricalVariablesData} />  : <div className='loaderCotainer'><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoadingTable2} css={override2} /></div> }
                </div>
            </div>
            </>
             : <p className='error'>{`Error: ${errorMsg}`}</p>         }
        </div>
    );
}

export default SummaryScreen;