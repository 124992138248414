import capitalize from "./capitalize";
import HelpTooltip from '../Components/SubComponent/HelpTooltip'
export const getCorrelationHeaders = (selectedTab,selectedGoal) =>{
    const headerContent = [];

    if(selectedTab === 'All_var'){
        // in case of different headers for each goal
        switch(selectedGoal){
            case 'goal2':
                headerContent.push({
                    id: 'Dep Variable',
                    Header: <p className="table-header-tooltip">Dep Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
                    accessor: (row) => capitalize(row.Dep_Varaible.replaceAll('_', ' '))
                },
                {
                    id: 'Ind. Variable',
                    Header: <p className="table-header-tooltip">Ind. Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
                    accessor: (row) => capitalize(row.ind_Varaible.replaceAll('_', ' '))
                },
                {
                    Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
                    accessor: 'n',
                },{
                    Header: <p className="table-header-tooltip">F_Score <HelpTooltip content="The F-score, also called the F1-score, is a measure of a model's accuracy on a dataset. It is used to evaluate binary classification systems, which classify examples into 'positive' " /></p>,
                    accessor: 'F_Score',
                },{
                    Header: <p className="table-header-tooltip">P-value <HelpTooltip content="probability of getting a sample like ours or more extreme than ours if the null hypothesis is correct." /></p>,
                    accessor: 'P-value',
                }
                )
                break;
            case 'goal6':
                headerContent.push({
                    id: 'Dep Variable',
                    Header: <p className="table-header-tooltip">Dep Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
                    accessor: (row) => capitalize(row.Dep_Varaible.replaceAll('_', ' '))
                },
                {
                    id: 'Ind. Variable',
                    Header: <p className="table-header-tooltip">Ind. Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
                    accessor: (row) => capitalize(row.ind_Varaible.replaceAll('_', ' '))
                },
                {
                    Header: <p className="table-header-tooltip">P-value <HelpTooltip content="probability of getting a sample like ours or more extreme than ours if the null hypothesis is correct." /></p>,
                    accessor: 'P-value',
                },
                {
                    Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
                    accessor: 'n',
                },
                {
                    Header: 'Correlation',
                    accessor: 'Correlation',
                },
                )
                break;
            default:
                headerContent.push({
                    id: 'Dep Variable',
                    Header: <p className="table-header-tooltip">Dep Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
                    accessor: (row) => capitalize(row.Dep_Varaible.replaceAll('_', ' '))
                },
                {
                    id: 'Ind. Variable',
                    Header: <p className="table-header-tooltip">Ind. Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
                    accessor: (row) => capitalize(row.ind_Varaible.replaceAll('_', ' '))
                },
                {
                    Header: 'Correlation',
                    accessor: 'Correlation',
                },
                {
                    Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
                    accessor: 'n',
                },{
                    Header: 'Point Biserial Result (Correlation)',
                    accessor: 'point biserial result (correlation)',
                },{
                    Header: <p className="table-header-tooltip">P-value <HelpTooltip content="probability of getting a sample like ours or more extreme than ours if the null hypothesis is correct." /></p>,
                    accessor: 'P-value',
                }
                )
        }
    }

    return headerContent;
}