/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSavedResultTableHeader } from '../../../../utils/getSavedResultHeaders';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Table from '../../HomeScreen/Table/Table';
import './TableView.css';

function TableView({ currentTab, selectedGoal,setSavedVariables,sortedField }) {

    const [header, setHeader] = useState();
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [variables, setVariables] = useState();
    const [formValidationErr, setFormValidationErr] = useState('');

    const allVariable = useSelector(state => state.allVariableReducer);
    const summary = useSelector(state => state.summaryReducer);

    const { loading: loading1, error: error1, allVariableList } = allVariable;
    const { loading: loading2, error: error2, summaryStatistics } = summary;

    const allVariableGoal2 = useSelector(state => state.allVariableReducerGoal2);
    const summaryGoal2 = useSelector(state => state.summaryReducerGoal2);

    const { loading: loading1Goal2, error: error1Goal2, allVariableList: allVariableListGoal2 } = allVariableGoal2;
    const { loading: loading2Goal2, error: error2Goal2, summaryStatistics: summaryStatisticsGoal2 } = summaryGoal2;

    const allVariableGoal3 = useSelector(state => state.allVariableReducerGoal3);
    const summaryGoal3 = useSelector(state => state.summaryReducerGoal3);
    const selectedVariableGoal3 = useSelector(state => state.selectedVariableReducerGoal3);

    const { loading: loading1Goal3, error: error1Goal3, allVariableList: allVariableListGoal3 } = allVariableGoal3;
    const { loading: loading2Goal3, error: error2Goal3, summaryStatistics: summaryStatisticsGoal3 } = summaryGoal3;

    const allVariableGoal4 = useSelector(state => state.allVariableReducerGoal4);
    const summaryGoal4 = useSelector(state => state.summaryReducerGoal4);
    const selectedVariableGoal4 = useSelector(state => state.selectedVariableReducerGoal4);

    const { loading: loading1Goal4, error: error1Goal4, allVariableList: allVariableListGoal4 } = allVariableGoal4;
    const { loading: loading2Goal4, error: error2Goal4, summaryStatistics: summaryStatisticsGoal4 } = summaryGoal4;

    const allVariableGoal5 = useSelector(state => state.allVariableReducerGoal5);
    const summaryGoal5 = useSelector(state => state.summaryReducerGoal5);
    const selectedVariableGoal5 = useSelector(state => state.selectedVariableReducerGoal5);

    const { loading: loading1Goal5, error: error1Goal5, allVariableList: allVariableListGoal5 } = allVariableGoal5;
    const { loading: loading2Goal5, error: error2Goal5, summaryStatistics: summaryStatisticsGoal5 } = summaryGoal5;

    const allVariableGoal6 = useSelector(state => state.allVariableReducerGoal6);
    const summaryGoal6 = useSelector(state => state.summaryReducerGoal6);
    const selectedVariableGoal6 = useSelector(state => state.selectedVariableReducerGoal6);

    const { loading: loading1Goal6, error: error1Goal6, allVariableList: allVariableListGoal6 } = allVariableGoal6;
    const { loading: loading2Goal6, error: error2Goal6, summaryStatistics: summaryStatisticsGoal6 } = summaryGoal6;

    const allVariableGoal7 = useSelector(state => state.allVariableReducerGoal7);
    const summaryGoal7 = useSelector(state => state.summaryReducerGoal6);
    const selectedVariableGoal7 = useSelector(state => state.selectedVariableReducerGoal7);

    const { loading: loading1Goal7, error: error1Goal7, allVariableList: allVariableListGoal7 } = allVariableGoal7;
    const { loading: loading2Goal7, error: error2Goal7, summaryStatistics: summaryStatisticsGoal7 } = summaryGoal7;

    const correlation_allVariable = useSelector(state => state.correlation_allVariableReducer);
    const { loading: loading3, CorrelationallVariableList } = correlation_allVariable;

    const correlation_allVariable_goal2 = useSelector(state => state.correlation_allVariableReducerGoal2);
    const {loading: loading3Goal2, CorrelationallVariableListGoal2} = correlation_allVariable_goal2;

    const correlation_allVariable_goal3 = useSelector(state => state.correlation_allVariableReducerGoal3);
    const {loading: loading3Goal3, CorrelationallVariableListGoal3} = correlation_allVariable_goal3;

    const correlation_allVariable_goal4 = useSelector(state => state.correlation_allVariableReducerGoal4);
    const {loading: loading3Goal4, CorrelationallVariableListGoal4} = correlation_allVariable_goal4;

    const correlation_allVariable_goal5 = useSelector(state => state.correlation_allVariableReducerGoal5);
    const {loading: loading3Goal5, CorrelationallVariableListGoal5} = correlation_allVariable_goal5;

    const correlation_allVariable_goal6 = useSelector(state => state.correlation_allVariableReducerGoal6);
    const {loading: loading3Goal6, CorrelationallVariableListGoal6} = correlation_allVariable_goal6;

    const correlation_allVariable_goal7 = useSelector(state => state.correlation_allVariableReducerGoal7);
    const {loading: loading3Goal7, CorrelationallVariableListGoal7} = correlation_allVariable_goal7;

    const SelectedTrainedModelReducer = useSelector(state => state.SelectedTrainedModelReducer);
    const { selectedTrainedModel } = SelectedTrainedModelReducer;


    const dispatch = useDispatch();
    let navigate = useNavigate();

    const getVariables = (variables) => {
        console.log(variables)
        setVariables(variables)
    }

    const override = css`position: absolute; transform: translate(-20%, 300%);`;


    useEffect(() => {
        const columns = getSavedResultTableHeader(currentTab, selectedTrainedModel.from);
        setHeader(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    //setting the table data based on the goal selected
    useEffect(() => {
        
        if(selectedTrainedModel.from === 'goal1'){
            if (currentTab === 0) { setRow(allVariableList); setIsLoading(loading1) }
            if (currentTab === 1) { setRow(summaryStatistics); setIsLoading(loading2) }
            if (currentTab === 2) { setRow(CorrelationallVariableList); setIsLoading(loading3) }
        }
        if(selectedTrainedModel.from === 'goal2'){
            
            if (currentTab === 0) { setRow(allVariableListGoal2); setIsLoading(loading1Goal2) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal2 ); setIsLoading(loading2Goal2) }
            if (currentTab === 2) { setRow(CorrelationallVariableListGoal2); setIsLoading(loading3Goal2) }
        }
        if(selectedTrainedModel.from === 'goal3'){
            if (currentTab === 0) { setRow(allVariableListGoal3); setIsLoading(loading1Goal3) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal3 ); setIsLoading(loading2Goal3) }
            if (currentTab === 2) { setRow(CorrelationallVariableListGoal3); setIsLoading(loading3Goal3) }
        }
        if(selectedTrainedModel.from === 'goal4'){
            if (currentTab === 0) { setRow(allVariableListGoal4); setIsLoading(loading1Goal4) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal4 ); setIsLoading(loading2Goal4) }
            if (currentTab === 2) { setRow(CorrelationallVariableListGoal4); setIsLoading(loading3Goal4) }
        }
        if(selectedTrainedModel.from === 'goal5'){
            if (currentTab === 0) { setRow(allVariableListGoal5); setIsLoading(loading1Goal5) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal5 ); setIsLoading(loading2Goal5) }
            if (currentTab === 2) { setRow(CorrelationallVariableListGoal5); setIsLoading(loading3Goal5) }
        }
        if(selectedTrainedModel.from === 'goal6'){
            if (currentTab === 0) { setRow(allVariableListGoal6); setIsLoading(loading1Goal6) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal6 ); setIsLoading(loading2Goal6) }
            if (currentTab === 2) { setRow(CorrelationallVariableListGoal6); setIsLoading(loading3Goal6) }
        }
        if(selectedTrainedModel.from === 'goal7'){
            if (currentTab === 0) { setRow(allVariableListGoal7); setIsLoading(loading1Goal7) }
            if (currentTab === 1) { setRow(summaryStatisticsGoal7 ); setIsLoading(loading2Goal7) }
            if (currentTab === 2) { setRow(CorrelationallVariableListGoal7); setIsLoading(loading3Goal7) }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, loading1, loading2, loading3, 
        loading1Goal2, loading2Goal2, loading3Goal2,
        loading1Goal3,loading2Goal3,loading3Goal3,
        loading1Goal4,loading2Goal4,loading3Goal4,
        loading1Goal5,loading2Goal5,loading3Goal5,
        loading1Goal6,loading2Goal6,loading3Goal6,
        loading1Goal7,loading2Goal7,loading3Goal7])




    return (
        <div className={`tableContainer boundary savedresult-table ${currentTab !== 2 && "tableContainer-padding"}`}>
            <div className='table-view__container'>
                {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                    <>
                        {(header && row) && <Table columns={header} data={row} getVariables = {getVariables} sortedField={sortedField} />}
                    </>
                }
            </div>
        </div>
    );
}

export default TableView;