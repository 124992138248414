import capitalize from "./capitalize";
import HelpTooltip from '../Components/SubComponent/HelpTooltip'

export const getHeader = (currentTab, selectedGoal) => {
  const headerContent = [];
  if (currentTab === 0) {
    // in case of different headers for each goal
      headerContent.push({
        Header: 'Variables',
        accessor: (row) => {return capitalize(row.Variables.replaceAll('_', ' '))}
      },
        {
          Header: 'Definitions',
          accessor: 'Definitions', // accessor is the "key" in the data
        },
        )    
  }

  if (currentTab === 1) {
    headerContent.push({
      Header: 'Numerical Variables',
      columns: [
        {
          Header: 'Variable Name',
          accessor: (row) => capitalize(row.Variable_Name.replaceAll('_', ' '))
        },
        {
          Header: <p className="table-header-tooltip">Unit <HelpTooltip content="A unit of observation is the unit described by the data that one analyzes. A study may treat groups as a unit of observation with a country as the unit of analysis, drawing " /></p>,
          accessor: 'Unit',
        },
        {
          Header: <p className="table-header-tooltip">Mean <HelpTooltip content="The Mean value of a dataset is the average value i.e. a number around which a whole data is spread out. All values used in calculating the average are weighted equally when defining the Mean." /></p>,
          accessor: 'Mean',
        },
        {
          Header: <p className="table-header-tooltip">Std. Dev. <HelpTooltip content="The standard deviation (σ) is a measure of the spread of the distribution. The larger the standard deviation, the bigger the spread. For a discrete set of values, the standard " /></p>,
          accessor: 'Std',
        },
        {
          Header: <p className="table-header-tooltip">Min <HelpTooltip content="The minimum is the smallest value in the data set. " /></p>,
          accessor: 'Min',
        },
        {
          Header: <p className="table-header-tooltip">Max <HelpTooltip content="The maximum is the largest value in the data set." /></p>,
          accessor: 'Max',
        },
        {
          Header: <p className="table-header-tooltip">Median <HelpTooltip content="Median is a statistical measure that determines the middle value of a dataset listed in ascending order (i.e., from smallest to largest value). The measure divides the lower " /></p>,
          accessor: 'Median',
        },
        {
          Header: <p className="table-header-tooltip">NA's <HelpTooltip content="missing values " /></p>,
          accessor: 'NA',
        },
        {
          Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
          accessor: 'N',
        }
      ],
    },
      {
        Header: 'Factors',
        columns: [
          {
            Header: 'Variable_Name',
            accessor: 'Factor_Varable_Name',
          },
          {
            Header: '',
            accessor: 'Factor_Varable_category',
          },
          {
            Header: 'Counts',
            accessor: 'Counts',
          },{
            Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
            accessor: 'n',
          }
        ],
      },
    )
  }

  if (currentTab === 2) {
    headerContent.push(
      {
        Header:  <p className="table-header-tooltip">Independent Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
        id: 'Independent Variable',
        accessor: (row) => capitalize(row.Independent_Variable.replaceAll('_', ' ')),
      }
      ,
      {
        Header: 'Definition',
        accessor: 'Independent_Variable_Definitions',
      },
    )
  }




  return headerContent;
}
