import { CORRELATION_ALL_VARIABLES_FAIL, CORRELATION_ALL_VARIABLES_REQUEST, CORRELATION_ALL_VARIABLES_SUCCESS } from "../../constants"

// correlation reducer for goal 1
export const correlation_allVariableReducer = (state = {CorrelationallVariableList: []}, action) => {
    switch(action.type){
        case CORRELATION_ALL_VARIABLES_REQUEST:
            return {loading: true}
        case CORRELATION_ALL_VARIABLES_SUCCESS:
            return{loading: false, CorrelationallVariableList: action.payload }
        case CORRELATION_ALL_VARIABLES_FAIL:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}