import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Predict from './Predict';

function PredictContainer({ builtInProjectState, setBuiltInProjectState, setCurrentlyActiveTab, currentlyActiveTab, selectedGoal, setSelectedGoal }) {


    const location = useLocation();
    const [dataToSend, setDataToSend] = useState({})
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [inVar, setInVar] = useState([]);
    const [tableData, setTableData] = useState();
    const [result, setResult] = useState(location.state ? location.state.result:'');

    let bestValues = {}

    const goalResultReducer = useSelector(state => state.goalResultReducer);
    const { loading, goalResult } = goalResultReducer;

    useEffect(() => {
        if (!loading) {
            setIsLoading(false)
            setRow(addDetails(goalResult[0]))
            setDataToSend(location.state ? location.state.predictVarList: bestValues)
            setCurrentlyActiveTab(location.state ? 'predict_page': currentlyActiveTab )

            if(location.state){
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        ProjectName: location.state.Project_Name,
                        modelName:location.state.modelName,
                        result:location.state.result,
                        Useremail:location.state.Useremail,
                        UserName:location.state.UserName,
                        goal:location.state.goal,
                        depVar:location.state.depVar,
                        isFav:location.state.isFav

                    })
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    const addDetails = (response) => {
        let data = [];
        let inVarsData = [];


        if (response) {

            response.result.Weightage.forEach((el, i) => {
                const depVar = response.dependentVariables
                data.push({ 'Weightage': el, 'Driving Force': response.result['Driving Force'][i], 'Dep Var': depVar, 'Ind Var': response.result['Independable Variable'][i] })
                inVarsData.push(response.result['Independable Variable'][i])
            })

            // data = data.filter(row => {
            //     return response.independentVariables.includes(row['Ind Var'])
            // })

        }

        
        inVarsData.forEach((element) => {
            if (!(inputValues[element])) {
                bestValues[element] = ""
            } else {
                bestValues[element] = inputValues[element]
            }
        })
        
        setTableData(data);
        return data
    }


    const inputValues = {
        'loan_amnt': 15506.872973889134,
        'funded_amnt': 15506.857387835233,
        'funded_amnt_inv': 15501.819603076641,
        'term': 43.29141519846597,
        'int_rate': 13.042466650118937,
        'installment': 456.2062956547644,
        'emp_length': 5.944250352684913,
        'annual_inc': 81151.777668373,
        'dti': 18.935760514171307,
        'delinq_2yrs': 0.30879650385090507,
        'inq_last_6mths': 0.5569103400637878,
        'open_acc': 11.844183470468273,
        'pub_rec': 0.18718249220925728,
        'revol_bal': 17171.806980237463,
        'revol_util': 50.41699869909994,
        'total_acc': 24.447074268498596,
        'out_prncp': 5337.104482353337,
        'out_prncp_inv': 5335.8922574841,
        'total_pymnt': 11595.768574190157,
        'total_pymnt_inv': 11591.544803448971,
        'total_rec_prncp': 9059.390779546622,
        'total_rec_int': 2411.866665722277,
        'total_rec_late_fee': 1.4850598392873442,
        'recoveries': 123.02638339352336,
        'collection_recovery_fee': 20.734502899948698,
        'last_pymnt_amnt': 3144.958055211498,
        'collections_12_mths_ex_med': 0.017965915652875204,
        'policy_code': 1.0,
        'acc_now_delinq': 0.0039512588232140985,
        'tot_coll_amt': 222.87178639476832,
        'tot_cur_bal': 147453.9660239253,
        'total_rev_hi_lim': 35450.889662768655,
        'grade': 'B',
        'sub_grade': 'C1',
        'home_ownership': 'MORTGAGE',
        'verification_status': 'Source Verified',
        'loan_status': 'Current',
        'pymnt_plan': 'n',
        'purpose': 'debt_consolidation',
        'zip_code': '750xx',
        'addr_state': 'CA',
        'initial_list_status': 'w',
        'application_type': 'Individual'
    }






           
        

    


    return (
        <>        {Object.keys(dataToSend).length !==0 && 
            <Predict 
            result={result}
            setResult={setResult}
            goalResult={goalResult} 
            tableData={tableData} 
            setIsLoading={setIsLoading} 
            isLoading={isLoading} row={row} 
            setRow={setRow} setDataToSend={setDataToSend} 
            dataToSend={dataToSend} builtInProjectState={builtInProjectState} 
            setBuiltInProjectState={setBuiltInProjectState} 
            setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal}  />
        }
        </>
    )
}

export default PredictContainer;