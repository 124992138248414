import { SELECTED_VARIABLES_REQUEST_GOAL7, SELECTED_VARIABLES_SUCCESS_GOAL7, SELECTED_VARIABLES_FAIL_GOAL7 } from "../../constants";

// selected variables reducer for goal 7
export const selectedVariableReducerGoal7 = (state = {selectedVariableList: []}, action) => {
    switch(action.type){
        case SELECTED_VARIABLES_REQUEST_GOAL7:
            return {loading: true}
        case SELECTED_VARIABLES_SUCCESS_GOAL7:
            return{loading: false, selectedVariableList: action.payload }
        case SELECTED_VARIABLES_FAIL_GOAL7:
            return {loading : false, error: action.payload}
        
        default:
            return state
    }
}