import { getCorrelationAllVarGoal2 } from "../../../utils/api";
import { CORRELATION_ALL_VARIABLES_FAIL_GOAL2, CORRELATION_ALL_VARIABLES_REQUEST_GOAL2, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL2 } from "../../constants";

// correlation action for goal 2
export const Correlation_getAllVariableGoal2 = () => async(dispatch) => {
    dispatch({
        type: CORRELATION_ALL_VARIABLES_REQUEST_GOAL2
    })

    try{
      const {data} = await getCorrelationAllVarGoal2();
      dispatch({
          type: CORRELATION_ALL_VARIABLES_SUCCESS_GOAL2,
          payload: data
      });
    }catch(error){
        dispatch({
            type: CORRELATION_ALL_VARIABLES_FAIL_GOAL2,
            payload: error.message
        })
    }
}