import { SELECTED_VARIABLES_REQUEST_GOAL6, SELECTED_VARIABLES_SUCCESS_GOAL6, SELECTED_VARIABLES_FAIL_GOAL6 } from "../../constants";

// selected variables reducer for goal 6
export const selectedVariableReducerGoal6 = (state = {selectedVariableList: []}, action) => {
    switch(action.type){
        case SELECTED_VARIABLES_REQUEST_GOAL6:
            return {loading: true}
        case SELECTED_VARIABLES_SUCCESS_GOAL6:
            return{loading: false, selectedVariableList: action.payload }
        case SELECTED_VARIABLES_FAIL_GOAL6:
            return {loading : false, error: action.payload}
        
        default:
            return state
    }
}