import { getSummaryApiGoal6 } from "../../../utils/api";
import {SUMMARY_FAIL_GOAL6, SUMMARY_REQUEST_GOAL6, SUMMARY_SUCCESS_GOAL6 } from "../../constants";

// summary action for goal 6
export const getSummaryGoal6 = () => async(dispatch) => {
    dispatch({
        type: SUMMARY_REQUEST_GOAL6
    })

    try{
      const {data} = await getSummaryApiGoal6();
      dispatch({
          type: SUMMARY_SUCCESS_GOAL6,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SUMMARY_FAIL_GOAL6,
            payload: error.message
        })
    }
}