import React, { useEffect, useState } from 'react';
import { useLocation,useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { removePredictionAPI, GetProjectPredictionsApi, shuffledPredictionsApi } from '../../../../utils/api';
import capitalize from '../../../../utils/capitalize';
import MasterPage from '../../../MasterPage';
import './ProjectPredictions.css';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import AddBoxIcon from '@mui/icons-material/AddBox';
import Popup from 'reactjs-popup';
import { useLongPress } from 'use-long-press';
import ClickAwayListener from 'react-click-away-listener';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import options from '../../../Images/options.png'


function ProjectPredictions({ currentlyActiveTab, setCurrentlyActiveTab, selectedGoal, setSelectedGoal }) {
    let navigate = useNavigate();
    let location = useLocation();
    let params = {...location.state}

    const [predictionData, setPredictionData] = useState([])
    const [open, setOpen] = useState(false);
    const [popUpValue, setPopUpValue] = useState('useDefault');
    const [item, setItem] = useState();
    const [enabled, setEnabled] = useState();
    const [multipleSelected, setMultipleSelected] = useState([]);
    const [isOptionsMenu, setIsOptionsMenu] = useState(false);
    const [selectedOption, setselectedOption] = useState('all')



    const PER_PAGE = 12;

    const [currentPage, setCurrentPage] = useState(0);

    const offset = currentPage * PER_PAGE;
    let currentPageData = selectedOption === 'fav' ? predictionData.filter((item) => item.isFav === true).slice(offset, offset + PER_PAGE): predictionData.slice(offset, offset + PER_PAGE)
    // currentPageData = currentPageData.filter((item) => item !== undefined)
    // console.log('currentPageData',currentPageData)

    const pageCount = Math.ceil(predictionData.length / PER_PAGE)

    const closeModal = () => { setOpen(false) }

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    async function getProjectPredictionsData() {

        try {
            const res = await GetProjectPredictionsApi(params);
            setPredictionData(res.data)
        } catch (error) {
            console.log(error);
        }
    }

    // let params = useParams();
    useEffect(() => {
        getProjectPredictionsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // async function deleteProject(ProjectName, Username) {

    //     let data = {
    //         'Projectname': ProjectName,
    //         'Username': Username
    //     }
    //     try {
    //         const res = await DeleteProjectApi(data);
    //         navigate(`/myProjects`)

    //     } catch (e) {
    //         console.log(e);
    //     }

    // }


    async function deleteProjectPrediction(id) {

        console.log(id);

        try {

            const delParams = {
                ...params,
                p_id: id,

            }

            const res = await removePredictionAPI(delParams)
            console.log(res);
            if (res.status === 200) {
                const newData = predictionData.filter((item) => item.uid !== id)
                setPredictionData(newData)
            }
        } catch (e) {
            console.log(e);
        }

    }


    const handleClickChangeOptions = (optionSelected) => {

        setselectedOption(optionSelected)
        setIsOptionsMenu(!isOptionsMenu)

    }


    const handleClickSelectFav = (id) => {


        const updateData = predictionData.map(item => 
            (item.uid === id && item.isFav === false) ? {...item, isFav: true} : (item.uid === id && item.isFav === true) ? {...item, isFav: false} : item
            
            )

            setPredictionData(updateData)
            shuffledPredictionsApi({
                "UserName": updateData[0].UserName,
                "Project_Name": updateData[0].Project_Name.toLowerCase(),
                'Prediction_info': updateData
            })

    }






    const handleClickViewResultDetails = (selectedItem) => {

        setItem(selectedItem)
        if (!enabled) {
            setOpen(true)
        } else {
            const newArr = [...multipleSelected]
            newArr.push(selectedItem)
            setMultipleSelected(newArr)
            console.log("Multiple");
        }


    }

    const handleClickProcessMultiple = () => {

        const newArr = [];
        const newIndVar = [];
        const newIndVarVal = [];
        const predictionValues = {};
        newArr.push({
            filename: multipleSelected[0].fileName,
            size: multipleSelected[0].fileSize
        })

        multipleSelected.forEach((item) => {
            newIndVar.push(item.Dependent_Variables)
            newIndVarVal.push(item.Result)

        })



        newIndVar.forEach((element, i) => {
            predictionValues[element] = newIndVarVal[i]
        });

        console.log(predictionValues);

        const dataToSend = { ...multipleSelected[0].projectData, fileDetail: [...newArr], summary: [], predictVarList: { ...predictionValues }, IndependentVar: [], AllVars: [...newIndVar], DependentVar: '' }

        navigate('/createNewProject', {
            state: {
                projectState: dataToSend,
                activeTab: 2,



            }
        })

    }


    const handleClickProceed = () => {
        console.log(popUpValue === 'useDefault');
        console.log(popUpValue === 'newVar');
        if (popUpValue === 'useDefault') {
            const predictionValues = {};
            const resultData = { ...item }
            delete resultData["projectData"]
            item.Independent_Variables.forEach((element, i) => {
                predictionValues[element] = item.Independent_Variables_Values[i]
            });
            const dataToSend = { ...item.projectData, predictVarList: { ...predictionValues } }
            navigate('/createNewProject', {
                state: {
                    projectState: dataToSend, result: {
                        data: {
                            PridicitonResult: resultData.Result,
                            resultOptions: resultData.resultOptions
                        }
                    },
                    activeTab: 4

                }
            })
        } else if (popUpValue === 'newVar') {
            const newArr = [];
            newArr.push({
                filename: item.fileName,
                size: item.fileSize
            })
            const dataToSend = { ...item.projectData, fileDetail: [...newArr], summary: [] }

            navigate('/createNewProject', {
                state: {
                    projectState: dataToSend,
                    activeTab: 2,
                    prevResult: item.Result


                }
            })
        }
    }


    const bind = useLongPress(() => {
        console.log('Long pressed!');

        if (multipleSelected.length !== 0) {
            setMultipleSelected([])
        }
        setEnabled(true)
    });


    function handleOnDragEnd(result) {

        let oldpredictionData = [...predictionData]

        const items = Array.from(currentPageData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        let j = 0;
        for (let i = offset; i <= offset + PER_PAGE - 1; i++) {
            oldpredictionData[i] = items[j]
            j++;
        }

        oldpredictionData = oldpredictionData.filter((item) => item !== undefined)

        setPredictionData(oldpredictionData);
        shuffledPredictionsApi({
            "UserName": oldpredictionData[0].UserName,
            "Project_Name": oldpredictionData[0].Project_Name.toLowerCase(),
            'Prediction_info': oldpredictionData
        })
    }




    // const handleClickNewPredictionDetails = (item) => {



    //     const dataToSend = { ...item.projectData, predictVarList: { ...item.initalistValues } }

    //     navigate('/createNewProject', { state: { projectState: dataToSend, result: {}, activeTab: 4 } })

    // }


    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} needSidebar={false} needHomePageSidebar={true} />
            <div className='myProjectPredictionContainer'>
                <h1 id='myPredictionResultHeader'>{capitalize(params.projectname)}</h1>
                <div className='myProjectPredictionSection'>
                    {/* <div id='head'> <h1>  </h1>    <Button style={{ color: '#8a9191' }} onClick={() => { deleteProject(params.projectname, params.username) }}>
                        <DeleteIcon />
                    </Button></div> */}
                    {currentPageData.length === 0 ?
                        <h1>{`All the predictions made under ${capitalize(params.projectname)} Project will be Displayed here.`}</h1>
                        :
                        <>

                            <ClickAwayListener onClickAway={() => { if (enabled) { setItem({}); setEnabled(false); } }}>
                                <div className='Card-BtnContainer'>
                                <ClickAwayListener onClickAway={() => { if (isOptionsMenu) { setIsOptionsMenu(!isOptionsMenu) } }}>
                                    <div className='page-Options'>
                                        <img src={options} alt='options' onClick={() => {setIsOptionsMenu(!isOptionsMenu)}}/>
                                        {isOptionsMenu &&
                                            <div className='Optionmenu'>
                                                <p className={selectedOption === "fav" && 'activeOption'} onClick={() => {handleClickChangeOptions('fav')}} >Show Favourites</p>
                                                <p className={selectedOption === "all" && 'activeOption'} onClick={() => {handleClickChangeOptions('all')}}>Show All</p>
                                            </div>
                                        }
                                    </div>
                                    </ClickAwayListener>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="characters" direction="horizontal" >
                                            {(provided) => (
                                                <div className='project-Cards-group' {...provided.droppableProps} ref={provided.innerRef} >
                                                    {/* <div className='create-project-card' onClick={() => handleClickNewPredictionDetails(currentPageData[0])} >
                                                    <div>

                                                        <div id='add'><AddBoxIcon fontSize='inherit' id='add-icon' style={{ color: '#8a9191' }} /></div>
                                                        <h2 style={{ width: '100%' }}>Make New Prediction</h2>
                                                    </div>
                                                </div> */}



                                                    {currentPageData && currentPageData.map((element, i) =>

                                                    (
                                                        <Draggable key={element.uid} draggableId={element.uid} index={i} >
                                                            {(provided) => (
                                                                <div className='prediction-project-card' key={element.uid} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className='resultHeader'>
                                                                        <div className='favbtn'>
                                                                            <Checkbox color='error' icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={element.isFav} onClick={() => handleClickSelectFav(element.uid)} />
                                                                        </div>
                                                                        <div className='deltn'>
                                                                            <Button style={{ color: '#8a9191', justifyContent: 'end' }} onClick={() => deleteProjectPrediction(element.uid)} >
                                                                                <DeleteIcon />
                                                                            </Button>

                                                                        </div>
                                                                    </div>
                                                                    <div {...bind()} onClick={() => handleClickViewResultDetails(element)} className="card-clickable">
                                                                        <h1>{`Result: ${isNaN(element.Result) ? capitalize(element.Result) : Number(element.Result).toFixed(3)}`}</h1>

                                                                        <h4>{`Algorithm: ${capitalize(element.Algorithm)}`}</h4>
                                                                        <h4>{`Dependent Variable: ${element.Dependent_Variables.replaceAll('_', ' ')}`}</h4>
                                                                        <h4>{`DataSet: ${element.projectData.DataSet}`}</h4>
                                                                        {enabled && <Checkbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} checked={multipleSelected.filter(function (e) { return e.uid === element.uid; }).length > 0} />}
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                    }
                                                    {provided.placeholder}

                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    {enabled && <div className='proceedBtn-container'>
                                        <Button variant="contained" id="proceedBtn" onClick={handleClickProcessMultiple}>Proceed</Button>
                                    </div>}
                                </div>
                            </ClickAwayListener>

                            <Popup open={open} closeOnDocumentClick onClose={closeModal} className='saveresult-model'>
                                <div className="modal">
                                    <span className="close" onClick={closeModal}>
                                        &times;
                                    </span>
                                    <p className="mypara" id="box1" style={{ fontSize: "25px", position: "relative", top: "-30px", width: '100%' }} >Please Select One</p>
                                    <div className="mybttons">
                                        <FormControl>
                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                defaultValue="useDefault">
                                                <FormControlLabel value="useDefault" onChange={e => setPopUpValue(e.target.value)} control={<Radio />} label="Use the same variable" />
                                                <FormControlLabel value="newVar" onChange={e => setPopUpValue(e.target.value)} control={<Radio />} label="Select new dependent variable" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="next">
                                        <Button variant="contained" id="nextbtn" onClick={handleClickProceed}>Proceed</Button>
                                    </div>
                                </div>
                            </Popup>

                            <ReactPaginate
                                previousLabel={"← Previous"}
                                nextLabel={"Next →"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            /></>}
                </div>
            </div>
        </>
    );
}

export default ProjectPredictions;

