import { SUMMARY_FAIL_GOAL3, SUMMARY_REQUEST_GOAL3, SUMMARY_SUCCESS_GOAL3 } from "../../constants";

// summary reducer for goal 3
export const summaryReducerGoal3 = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST_GOAL3:
            return {loading: true}
        case SUMMARY_SUCCESS_GOAL3:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL_GOAL3:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}