/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './SavedResultScreen.css'
import resultBase from '../../Images/resultBase.svg';
import { useSelector,useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getSummary } from '../../../Redux/Reducers/summary/summaryAction';
import { getSummaryStatsHeader } from '../../../utils/getSavedResultHeaders';
import Table from '../HomeScreen/Table/Table';
import MasterPage from '../../MasterPage';
import { useNavigate, useLocation } from 'react-router-dom';

function SummaryStats({setCurrentlyActiveTab,currentlyActiveTab, selectedGoal,setSelectedGoal}) {

    const dispatch = useDispatch();
    const [header, setHeader] = useState();
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    let location = useLocation();

    const override = css`position: absolute; transform: translate(-20%, 300%);`;

    const summary = useSelector(state => state.summaryReducer);
    const { loading, error: error2, summaryStatistics } = summary;

    let navigate = useNavigate();

    const handleButtonClick = () => {
       
        navigate('/correlation')
        // navigate('/correlation', { state: {from: location.state.from,dependentVariables: location.state.dependentVariables,independentVariables: location.state.independentVariables, id: location.state.id,modelName: location.state.modelName}})
    }

    useEffect(() => {
        dispatch(getSummary())
        const columns = getSummaryStatsHeader();
        setHeader(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //setting headers for table
    useEffect(() => {
        setIsLoading(loading)
        
       
        setRow(summaryStatistics)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab}  currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal}  needSidebar={false} />
            <div className='nosidebar-screen screen'>
                <div className='tableHeader savedresult-tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <label className='table-title'>Summary Statistics</label>
                    <button className='view-variables-button' onClick={handleButtonClick}>View Correlation</button>
                </div>
                <div className="tableContainer boundary">
                <div className='table-view__container'>
                    {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                        <>
                            {(header && row) && <Table columns={header} data={row} />}
                        </>
                    }
                </div>

            </div>
            </div>


        </>
    );
}

export default SummaryStats;