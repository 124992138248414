import MasterPage from '../../MasterPage';
import './ExistingData.css'
import Dimension from '../../Images/dimension.png'
import Data from '../../Images/icons8-document-60.png'
import Count from '../../Images/count.png'
import { useNavigate } from 'react-router-dom';
import Variables from '../../Images/variable.png'
import ML from '../../Images/machine-learning.png'

function ExistingData({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal}) {
    let navigate = useNavigate();

    const handleCardClick = () => {
        navigate('/goals')
    }

    const handleTrainedModelsCardClick = () => {
        navigate('/trainedmodels')
    }

    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal = {selectedGoal} setSelectedGoal = {setSelectedGoal} needSidebar={false} />
        <div className='existingdatascreen screen'>
            <div className='existingdatascreen-main'>
                <h1>Dataset Details</h1>
                <div className='data-info'>
                    <div className='info'>
                        <img src={Dimension} alt="dimension-icon" />
                        <div>
                        <p className='name'>Data Dimensions</p>
                        <p className='value'>6,566,768 x 47</p>
                        </div>
                    </div>

                    <div className='info'>
                        <img src={Data} alt="size-icon" style={{width: '5em',height: '5em'}} />
                        <div>
                        <p className='name'>Size</p>
                        <p className='value'>1.37GB</p>
                        </div>
                    </div>

                    <div className='info'>
                        <img src={Count} alt="count-icon" style={{width: '4.3em',height: '4.3em'}} />
                        <div>
                        <p className='name'>Number Of Variables</p>
                        <p className='value'>47</p>
                        </div>
                    </div>
                </div>
                <h1>Please select an option</h1>
                <div className='next-cards-group'>
                    <div className='next-card'>
                        <div className='icon'>
                            <img src={ML} alt="ml-icon" />
                        </div>
                        <h1>View Trained Models</h1>
                        {/* <h4>Short description</h4> */}
                        <button className="get-started" onClick={() => handleTrainedModelsCardClick()}>Get Started</button>
                    </div>
                    <div className='next-card'>
                        <div className='icon'>
                            <img src={Variables} alt="variables-icon" />
                        </div>
                        <h1>View Variable And Train It</h1>            
                        {/* <h4>Short description</h4> */}
                        <button className="get-started" onClick={() => handleCardClick()}>Get Started</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ExistingData;