import { getCorrelationAllVarGoal6 } from "../../../utils/api";
import { CORRELATION_ALL_VARIABLES_FAIL_GOAL6, CORRELATION_ALL_VARIABLES_REQUEST_GOAL6, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL6 } from "../../constants";

// correlation action for goal 6
export const Correlation_getAllVariableGoal6 = () => async(dispatch) => {
    dispatch({
        type: CORRELATION_ALL_VARIABLES_REQUEST_GOAL6
    })

    try{
      const {data} = await getCorrelationAllVarGoal6();
      dispatch({
          type: CORRELATION_ALL_VARIABLES_SUCCESS_GOAL6,
          payload: data
      });
    }catch(error){
        dispatch({
            type: CORRELATION_ALL_VARIABLES_FAIL_GOAL6,
            payload: error.message
        })
    }
}