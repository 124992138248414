export const getOverViewCardsDetails = (selectedTrainedModel,setDetailsProps) => {

    if (selectedTrainedModel.from === 'goal1') {
        setDetailsProps({
            goalName: 'Loan Approval Prediction',
            highestAccuracy: '99.77%',
            highestAccuracyModelName: 'Logistic Regression',
            depVariable: 'Will Default',
            depVariableDef: 'Current status of the loan',
            algorithms: 5,
            selectedVariables: 20,
            selectedGoal: "Goal1"
        })
    }
    else if (selectedTrainedModel.from === 'goal2') {
        setDetailsProps({
            goalName: 'Self Learning Credit Risk',
            highestAccuracy: '98.60%',
            highestAccuracyModelName: 'XGBoost Classifier',
            depVariable: 'Good Bad',
            depVariableDef: 'Good or Bad credit score (0 for bad and 1 for good)',
            algorithms: 5,
            selectedVariables: 15,
            selectedGoal: "Goal2"
        })
    }
    else if (selectedTrainedModel.from === 'goal3') {
        setDetailsProps({
            goalName: 'Predictive analytics to determine individual interest rates',
            highestAccuracy: '98.93%',
            highestAccuracyModelName: 'Random Forest',
            depVariable: 'Int Rate',
            depVariableDef: 'Interest Rate on the loan',
            algorithms: 6,
            selectedVariables: 39,
            selectedGoal: "Goal3"
        })
    }
    else if (selectedTrainedModel.from === 'goal4') {
        setDetailsProps({
            goalName: 'Model to estimate the risk of liquidity based on payment history',
            highestAccuracy: '99.31%',
            highestAccuracyModelName: 'Random Forest',
            depVariable: 'High Low',
            depVariableDef: 'The risk of incurring losses',
            algorithms: 5,
            selectedVariables: 33,
            selectedGoal: "Goal4"
        })
    }
    else if (selectedTrainedModel.from === 'goal5') {
        setDetailsProps({
            goalName: 'Cashflow based credit model',
            highestAccuracy: '99.52%',
            highestAccuracyModelName: 'Random Forest',
            depVariable: 'Good Bad',
            depVariableDef: 'Good or Bad credit score (0 for bad and 1 for good',
            algorithms: 5,
            selectedVariables: 26,
            selectedGoal: "Goal5"
        })
    }
    else if (selectedTrainedModel.from === 'goal6') {
        setDetailsProps({
            goalName: 'Automated Credit Decisioning',
            highestAccuracy: '99.74%',
            highestAccuracyModelName: 'Random Forest Classifier	',
            depVariable: 'Approved',
            depVariableDef: 'Customer with good credit score is approved and vice versa',
            algorithms: 5,
            selectedVariables: 25,
            selectedGoal: "Goal6"
        })
    }
    else {
        setDetailsProps({
            goalName: 'Behavioral analytics',
            highestAccuracy: '60.3174%',
            highestAccuracyModelName: 'Random Forest',
            depVariable: 'High Low',
            depVariableDef: 'The risk of incurring losses',
            algorithms: 6,
            selectedVariables: 33,
            selectedGoal: "Goal7"
            
        })
    }

}