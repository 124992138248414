import MasterPage from '../../MasterPage';
import './TrainedModels.css'
import { getselectedVariable } from '../../../Redux/Reducers/selectedVariable/selectedVariableAction';
import { getselectedVariableGoal2 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal2';
import { getselectedVariableGoal3 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal3';
import { getselectedVariableGoal4 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal4';
import { getselectedVariableGoal5 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal5';
import { getselectedVariableGoal6 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal6';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSelectedTrainedModel } from '../../../Redux/Reducers/SelectedTrainedModel/SelectedTrainedModelAction';
import { Correlation_getAllVariableGoal6 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal6';
import { Correlation_getAllVariableGoal5 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal5';
import { Correlation_getAllVariableGoal4 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal4';
import { Correlation_getAllVariableGoal3 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal3';
import { Correlation_getAllVariableGoal2 } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarActionGoal2';
import { Correlation_getAllVariable } from '../../../Redux/Reducers/correlation_all_var/correlation_allVarAction';
import { getAllVariable } from '../../../Redux/Reducers/allVariable/allVariableAction';
import { getAllVariableGoal2 } from '../../../Redux/Reducers/allVariable/allVariableActionGoal2';
import { getAllVariableGoal3 } from '../../../Redux/Reducers/allVariable/allVariableActionGoal3';
import { getAllVariableGoal4 } from '../../../Redux/Reducers/allVariable/allVariableActionGoal4';
import { getAllVariableGoal5 } from '../../../Redux/Reducers/allVariable/allVariableActionGoal5';
import { getAllVariableGoal6 } from '../../../Redux/Reducers/allVariable/allVariableActionGoal6';
import { getSummaryGoal2 } from '../../../Redux/Reducers/summary/summaryActionGoal2';
import { getSummaryGoal3 } from '../../../Redux/Reducers/summary/summaryActionGoal3';
import { getSummaryGoal4 } from '../../../Redux/Reducers/summary/summaryActionGoal4';
import { getSummaryGoal5 } from '../../../Redux/Reducers/summary/summaryActionGoal5';
import { getSummaryGoal6 } from '../../../Redux/Reducers/summary/summaryActionGoal6';
import { getSummary } from '../../../Redux/Reducers/summary/summaryAction';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import { getModelsAccuracies } from '../../../utils/getTrainedModelsHeaders';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import TextField from '@mui/material/TextField';




function TrainedModels({builtInProjectState,setBuiltInProjectState, currentlyActiveTab, setCurrentlyActiveTab, selectedGoal, setSelectedGoal }) {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    // eslint-disable-next-line no-unused-vars
    const [selectedTrainedGoal, setselectedTrainedGoal] = useState('goal1');
    const [selectedModel, setSelectedModel] = useState('');
    const [modelName, setModelName] = useState([]);
    const [open, setOpen] = useState(false);
    const [openTitle, setOpenTitle] = useState(false);


    const handleChange = (event) => {
        setSelectedModel(event.target.value);
        setBuiltInProjectState((current) => {
            return({
                ...current,
                modelName: event.target.value
            })
        })
      };


      const closeTitleModal = () => {
        setOpenTitle(false)
    }

    const handleChangeProjectName = (e) => {

        setBuiltInProjectState((current) => {
            return({
                ...current,
                ProjectName: e.target.value
            })
        })

    }


    const handleViewButtonClick = (num) => {

        setSelectedModel('');

        switch (num) {
            case 0:
                setModelName(getModelsAccuracies('goal1'))
                setselectedTrainedGoal('goal1');
                dispatch(getAllVariable());
                dispatch(getSummary());
                dispatch(getselectedVariable());
                dispatch(Correlation_getAllVariable());
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal1'
                    })
                })
                break;
            case 1:
                setModelName(getModelsAccuracies('goal2'))
                setselectedTrainedGoal('goal2');
                dispatch(getAllVariableGoal2())
                dispatch(getSummaryGoal2());
                dispatch(getselectedVariableGoal2());
                dispatch(Correlation_getAllVariableGoal2());
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal2'
                    })
                })
                break;
            case 2:
                setModelName(getModelsAccuracies('goal3'))
                setselectedTrainedGoal('goal3');
                dispatch(getAllVariableGoal3())
                dispatch(getSummaryGoal3());
                dispatch(getselectedVariableGoal3());
                dispatch(Correlation_getAllVariableGoal3());
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal3'
                    })
                })
                break;
            case 3:
                setModelName(getModelsAccuracies('goal4'))
                setselectedTrainedGoal('goal4');
                dispatch(getAllVariableGoal4())
                dispatch(getSummaryGoal4());
                dispatch(getselectedVariableGoal4());
                dispatch(Correlation_getAllVariableGoal4());
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal4'
                    })
                })
                break;
            case 4:
                setModelName(getModelsAccuracies('goal5'))
                setselectedTrainedGoal('goal5');
                dispatch(getAllVariableGoal5())
                dispatch(getSummaryGoal5());
                dispatch(getselectedVariableGoal5());
                dispatch(Correlation_getAllVariableGoal5());
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal5'
                    })
                })
                break;
            case 5:
                setModelName(getModelsAccuracies('goal6'))
                setselectedTrainedGoal('goal6');
                dispatch(getAllVariableGoal6())
                dispatch(getSummaryGoal6());
                dispatch(getselectedVariableGoal6());
                dispatch(Correlation_getAllVariableGoal6());
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal6'
                    })
                })
                break;

            default:
                setModelName(getModelsAccuracies('goal1'))
                setselectedTrainedGoal('goal1');
                dispatch(getselectedVariable())
                setBuiltInProjectState((current) => {
                    return({
                        ...current,
                        goal: 'goal1'
                    })
                })
        }

        setCurrentlyActiveTab('Models_info')

        // setBuiltInProjectState((current) => {
        //     return({
        //         ...current,
        //         ProjectName: ''
        //     })
        // })

        if(builtInProjectState.ProjectName.length !== 0){
            setBuiltInProjectState((current) => {
                return({
                    ...current,
                    ProjectName: '',
                    modelName:'',
                    result:''
                })
            })

        }
       
        handleClickTitleButtonOpen();
        
    }


    const closeModal = () => { setOpen(false) }

    const handleClickTitleButtonOpen = () => { setOpenTitle(true) }

    const handleClickCreateProject = () => {
        handleClickButtonOpen();
    }

    const handleClickButtonOpen = () => { setOpen(true) }

    const handleClickProceedToUseBtn = () => {

        dispatch(getSelectedTrainedModel({ from: selectedTrainedGoal ,modelName: selectedModel }))
        navigate('/savedresult')

    }




    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} needSidebar2={true} />


            <div className='goalscreen screen'>
                <div className='goalscreen-main'>
                    <h1>Please Select a goal</h1>
                    <div className='goal-cards-group'>
                        <div className='goal-card' onClick={() => handleViewButtonClick(0)} >
                            <h1>Goal 1</h1>
                            <h4>Loan Approval Prediction</h4>
                            <h4>Accuracy: 99.77%</h4>
                        </div>
                        <div className='goal-card' onClick={() => handleViewButtonClick(1)} >
                            <h1>Goal 2</h1>
                            <h4>Self Learning Credit Risk</h4>
                            <h4>Accuracy: 98.60%</h4>
                        </div>
                        <div className='goal-card' onClick={() => handleViewButtonClick(2)} >
                            <h1>Goal 3</h1>
                            <h4>predictive analytics to determine individual interest rates</h4>
                            <h4>Accuracy: 98.93%</h4>
                        </div>
                        <div className='goal-card' onClick={() => handleViewButtonClick(3)} >
                            <h1>Goal 4</h1>
                            <h4>model to estimate the risk of liquidity based on payment history</h4>
                            <h4>Accuracy: 99.31%</h4>
                        </div>
                        <div className='goal-card' onClick={() => handleViewButtonClick(4)} >
                            <h1>Goal 5</h1>
                            <h4>Cashflow based credit model</h4>
                            <h4>Accuracy: 99.52%</h4>
                        </div>
                        <div className='goal-card' onClick={() => handleViewButtonClick(5)} >
                            <h1>Goal 6</h1>
                            <h4>Automated Credit Decisioning</h4>
                            <h4>Accuracy: 99.74%</h4>
                        </div>

                    </div>
                </div>
                <Popup open={open} closeOnDocumentClick onClose={closeModal} className='saveresult-model'>
                    <div className="modal">
                        <span className="close" onClick={closeModal}>
                            &times;
                        </span>
                        <p className="mypara" id="box1" style={{ fontSize: "25px", position: "relative", top: "-30px", width: '100%' }} >Please Select One</p>
                        <FormControl>
                            {modelName.map(y => {
                                return (
                                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={selectedModel}
                                        onChange={handleChange}
                                        >
                                        <FormControlLabel value={y.name} control={<Radio />} label={`${y.name} - ${y.accuracy}%`} />
                                    </RadioGroup>
                                    // <p>{`${y.name} - ${y.accuracy}`}</p>
                                )
                            }
                            )
                            }
                        </FormControl>
                        {selectedModel.length !== 0 && <div className='proceedToUseBtn-container'>
                                        <Button variant="contained" id="proceedBtn" onClick={handleClickProceedToUseBtn}>Proceed</Button>
                                    </div>}
                    </div>
                </Popup>

                <Popup open={openTitle} closeOnDocumentClick onClose={closeTitleModal} className='saveresult-model'>
                        <div className="modal">
                            <span className="close" onClick={closeTitleModal}>
                                &times;
                            </span>
                            <p className="mypara" id="box1" style={{ fontSize: "25px", position: "relative", top: "-30px", width: '100%', fontFamily: 'NeonDesmon' }} >Please Add Project Title</p>
                            <div className='project-details-form'>
                                <TextField id="outlined-basic" label="Project Name" variant="outlined" value={builtInProjectState.ProjectName} onChange={(e) => handleChangeProjectName(e)} InputLabelProps={{
                                    style: { color: '#fff' },
                                }}/>


                            </div>
                            {builtInProjectState.ProjectName.length > 1 && <div className="next">
                                <Button variant="contained" id="nextbtn" onClick={handleClickCreateProject}>Proceed</Button>
                            </div> }
                            
                        </div>
                    </Popup>
            </div >
        </>
    );
}

export default TrainedModels;