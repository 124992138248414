import './SlideBarXd.css';
import shadow from '../Images/shadow.png';
import home from '../Images/home.png';
import orders from '../Images/orders.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function HomePageSideBar({ currentlyActiveTab, setCurrentlyActiveTab, selectedGoal }) {

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if(location.pathname === '/'){
            setCurrentlyActiveTab('home_page')
        }else if(location.pathname === '/myProjects'){
            setCurrentlyActiveTab('myProject')
        }
        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])

  

    const handleHomePageButtonClick = () => {
        setCurrentlyActiveTab('home_page')
        navigate('/')
    }

    const handlemyProjectPageButtonClick = () => {
        setCurrentlyActiveTab('myProject')
        navigate('/myProjectMainScreen')
     
    }

    

    

    return (
        <>
              <div className='sidebar'>
                <img className="shadow" src={shadow} alt='shadow' />
                <div className='listItems'>
                    <ul>
                        <li className={`homeIcon firstTab ${currentlyActiveTab === 'home_page' && "activeTab"}`}>
                            <button onClick={handleHomePageButtonClick} >
                                <img className="home" src={home} alt='home' />
                            </button>
                        </li>
                        
                        <li className='seprator'></li>
                        <li className={`secondIcon2 fourthTab ${currentlyActiveTab === 'myProject' && "activeTab"} `}>
                            <button onClick={handlemyProjectPageButtonClick}>
                                <img className="orders2" src={orders} alt='' />
                            </button>                                 
                        </li>
                       
                    </ul>
                </div>
            </div>
        </>
    );
}

export default HomePageSideBar;