import AllVarList from '../../Images/icons8-list-64.png'
import Algo from '../../Images/icons8-algorithm-42.png'
import SelectedList from '../../Images/icons8-checklist-50 (1).png'

const OverViewCard = (props) => {
    
    return (
        <div className='goal-info-wrapper'>
            <div className='goal-overview'>
                <div className='card-header'><h1 className='card-title'>Goal Overview</h1></div>
                <div className='goal-overview-content'>
                <div className='goal-overview-content-block'>
                    <h3 className='goal-info-heading'>{props.goalName}</h3>
                    <p className='goal-info-subText'>{props.selectedGoal}</p>
                </div>
            
                <div  className='goal-overview-content-block'>
                    <h3 className='goal-info-heading'>{props.depVariable}</h3>
                    <p className='goal-info-subText'>Dependent Variable</p>
                </div>
                <div  className='goal-overview-content-block'>
                    <h3 className='goal-info-heading'>{props.depVariableDef}</h3>
                    <p className='goal-info-subText'>Definition</p>
                </div>
                <div  className='goal-overview-content-block'>
                    <h3 className='goal-info-heading'>{props.highestAccuracyModelName}</h3>
                    <p className='goal-info-subText'>Highest Accurate Algorithm</p>
                </div>
                <div  className='goal-overview-content-block'>
                    <h3 className='goal-info-heading'>{props.highestAccuracy}</h3>
                    <p className='goal-info-subText'>Highest Accuracy</p>
                </div>
                </div>
            </div>
            <div className='goal-stats'>
                <div className='card-header'><h1 className='card-title'>Goal Statistics</h1></div>
                <div className='goal-stats-wrapper'>
                    <div className='stats-block'>
                        <div className='avatar'>
                            <span className='avatar-content'>
                                <img src={AllVarList} alt="" />
                            </span>
                        </div>
                        <div className='stats-block-body'>
                            <h4 className='numValue'>150</h4>
                            <p className='card-text'>All Variables</p>
                        </div>

                    </div>
                    <div className='stats-block'>
                        <div className='avatar'>
                            <span className='avatar-content'>
                                <img src={Algo} alt="" />
                            </span>
                        </div>
                        <div className='stats-block-body'>
                            <h4 className='numValue'>{props.algorithms}</h4>
                            <p className='card-text'>Algorithms</p>
                        </div>

                    </div>
                    <div className='stats-block'>
                        <div className='avatar'>
                            <span className='avatar-content'>
                                <img src={SelectedList} alt="" />
                            </span>
                        </div>
                        <div className='stats-block-body'>
                            <h4 className='numValue'>{props.selectedVariables}</h4>
                            <p className='card-text'>Selected<br></br> Variables</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default OverViewCard


