import { SUMMARY_FAIL_GOAL5, SUMMARY_REQUEST_GOAL5, SUMMARY_SUCCESS_GOAL5 } from "../../constants";

// summary reducer for goal 5
export const summaryReducerGoal5 = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST_GOAL5:
            return {loading: true}
        case SUMMARY_SUCCESS_GOAL5:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL_GOAL5:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}