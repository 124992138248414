import React from 'react';


// error boundary
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return (<div style={{display: 'grid',placeItems: 'center',height: '90vh'}} >
        <h2 style={{fontFamily: 'sans-serif',fontSize: '28px',color: '#ffffff'}}>Something went wrong. Please Refresh the page.</h2>
      </div>)
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
