import React, { useEffect, useState } from 'react';
import './PredictionScreen.css';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Table from '../../HomeScreen/Table/Table';
import { getNewProjectPredictHeaders } from '../../../../utils/getNewProjectTableHeader';
import resultBase from '../../../Images/resultBase.svg';
import PredictSection from './PredictSection/PredictSection';
import Popup from 'reactjs-popup';
import { BarLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { hypetuneAlgoApi } from '../../../../utils/api';

import { chunk } from 'lodash';


function PredictionScreen({totalCombinations,SetTotalCombinations,hyperdataaftertest,sethyperdataaftertest,hyperdataaftervalidation,sethyperdataaftervalidation,hyperdatabeforetest,sethyperdatabeforetest,sethyperdatabeforevalidation,hyperdatabeforevalidation,flag,setflag,showToast, setErrorMsg, errorMsg, predictApiData, newProjectState, setNewProjectState }) {

    const [openAlgorithmType, setOpenAlgorithmType] = useState(false);
    const [open, setOpen] = useState(false);

    const [header, setHeader] = useState([]);
    const [row, setRow] = useState([]);

    const [loading, setLoading] = useState(true);


    let tableRow = [];
    let InitialTableList = [...newProjectState.IndependentVar]
    let deletedListObject = newProjectState.deletedVar
    let deletedListArray = Object.values(deletedListObject)
    let finalTableList = InitialTableList.filter((el) => !deletedListArray.includes(el));

    finalTableList.forEach(element => {

        tableRow.push({
            varName: element
        })

    });


    const handleAlgorithmTypeClose = () => {
        setOpenAlgorithmType(false);
    };

    const handleAlgorithmTypeOpen = () => {
        setOpenAlgorithmType(true);
    };

    const handleChangeAlgorithmTypeValue = (event) => {
        setNewProjectState((current) => {
            return ({
                ...current,
                algoSelected: event.target.value
            })
        })
    };


    const handleInputChange = (e, indVar, type) => {

        setNewProjectState((current) => {
            return ({
                ...current,
                predictVarList: { ...current.predictVarList, [indVar]: e.target.value }
            })
        })

    }


    useEffect(() => {
        if (newProjectState.algoSelected.length !== 0) {
            setErrorMsg('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState.algoSelected])



    useEffect(() => {
        const columns = getNewProjectPredictHeaders(handleInputChange, newProjectState);
        setHeader(columns)
        setRow(tableRow)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState])


    const handleClickButtonOpen = () => {
        if (newProjectState.algoSelected.length === 0) {
            setErrorMsg('Please Select An Algorithm to hyper tune');
            return;
        }

        const algoToHyperTune = newProjectState.algoSelected;

        setNewProjectState((current) => {
            return ({
                ...current,
                hyperTunedAlgo: algoToHyperTune
            })
        })






        setOpen(true)
    }




    const closeModal = () => { setOpen(false) }

    const override = css`position: absolute; transform: translate(-50%, 30%);`;
    //const searchmethod=["gridSearch","randomSearch"];
    const [displaygrid, setgrid] = useState("HalvingGridSearchCV");
    //const [data,setdata]=useState(null);
    const [mess, newmess] = useState("");
    
    
    
    
   
  


    let arrObj = [hyperdatabeforevalidation, hyperdatabeforetest, hyperdataaftervalidation, hyperdataaftertest];

    // new table code

    let arraykeys = [];
    Object.keys(hyperdataaftertest).map(x => {
        arraykeys.push(x.split('_').join(' '))
    })

    let arrayElements = [];
    arrayElements.push(arraykeys)
    arrObj.forEach(x => arrayElements.push(Object.values(x)))
    console.log(arrayElements)

    let arrayElement = []
    
    

    for (let i = 0; i < arrayElements[0].length; i++) {

        arrayElements.map(x => {
            arrayElement.push(x[i])
        })

    }
    console.log(arrayElement)

    let upto3Digits =[];
    arrayElement.map(x =>{
      if(isNaN(x)){
     upto3Digits.push(x)
      } else{
         upto3Digits.push(Number(x).toFixed(3))
      }
         
        
    })
    console.log(upto3Digits)






    let allHypertunedDataElements = []
    while (upto3Digits.length > 0) {

        chunk = upto3Digits.splice(0, 5)
        allHypertunedDataElements.push(chunk)
        // console.log(chunk)

    }

    console.log(arrayElement)
    console.log(allHypertunedDataElements)


    async function hypetuneAlgo() {
        try {
            let data = { HyperParameterTuning_Technique: displaygrid, projectData: newProjectState };
            const res = await hypetuneAlgoApi(data);
            
            showToast(); 
            
            SetTotalCombinations(res.data.total_combinations)
            let beforehypertuningvalidation = res.data.evaluation.BeforeHyperParameterTuning.ValidationSet;
            let beforehypertuningtest = res.data.evaluation.BeforeHyperParameterTuning.TestSet;
            let afterhypertuningvalidation = res.data.evaluation.AfterHyperParameterTuning.ValidationSet;
            let afterhypertuningtest = res.data.evaluation.AfterHyperParameterTuning.TestSet;
            let afterhypertuning = res.data.evaluation.AfterHyperParameterTuning.ValidationSet

            sethyperdatabeforevalidation(beforehypertuningvalidation);
            sethyperdatabeforetest(beforehypertuningtest)
            sethyperdataaftervalidation(afterhypertuningvalidation)
            sethyperdataaftertest(afterhypertuningtest)


        }
        catch (error) {
            console.log(error);
        }
    }


    function funct1() {

        let messag = `Hyperparameter tuning ${newProjectState.hyperTunedAlgo} Algorithm, hyperparameter tuning takes a lot of computation and a lot of time (upto Hours), So please do not close the window.`

        newmess(messag);
        setflag(0);

        let data = { userval: displaygrid, newProjectState };
        hypetuneAlgo();
    }


    async function proceedHypertune() {
         console.log('clicked')
         closeModal()
        // try {
        //     let data = {
        //         Username: newProjectState.Username,
        //         ProjectName: newProjectState.ProjectName,
        //         projectType: newProjectState.projectType,
        //         predictVarList: newProjectState.predictVarList,
        //         algoSelected: newProjectState.algoSelected,
        //         train_cat_dummies: newProjectState.train_cat_dummies,
        //         train_cat_vars: newProjectState.train_cat_vars,
        //         train_vars: newProjectState.train_vars,
        //         dep_val: newProjectState.dep_val
        //     }
        //     const res = newProjectGetResultApi(data);
        //     console.log(data);
        //     console.log(res)
        // }
        // catch (error) {
        //     console.log(error);
        // }


    }
    const [currentVar, setCurrentVar] = useState(0)
    const handleClickPreviousVar = () => {

        if(currentVar === 0){
             return;
        }

        setCurrentVar((prevState) => prevState-1)

    }


    const handleClickNextVar = () => {

        if(newProjectState.DependentVar.length === currentVar + 1) {return;}
        setCurrentVar((prevState) => prevState+1)

    }

    return (
        <div className='predictionScreenContainer'>
    <div className='SummaryStatsNumericalContainer-header'>
             {/* {newProjectState.DependentVar.length === Object.keys(newProjectState.summary).length && <p onClick={handleClickPreviousVar}>&larr;</p>}<h2>{`Numerical Variables Summary(${newProjectState.DependentVar[currentVar]})`}</h2> {newProjectState.DependentVar.length === Object.keys(newProjectState.summary).length && <p onClick={handleClickNextVar}>&rarr;</p>}
              */}   </div>

            <div className='algoListContainer'>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }}  style={{minWidth: '80%', }}>
                        <InputLabel id="demo-controlled-open-select-label">Algorithm Type</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openAlgorithmType}
                            onClose={handleAlgorithmTypeClose}
                            onOpen={handleAlgorithmTypeOpen}
                            value={newProjectState.algoSelected}
                            label="Algorithm Type"
                            onChange={handleChangeAlgorithmTypeValue}
                            inputlabel={{ 'color': '#ffffff' }}

                        >


                            {newProjectState.algoAccuracies.map((item, i) => {
                                const keyValue = Object.keys(item);
                                return (<MenuItem value={keyValue[0]} key={keyValue[0]}>{item[keyValue[0]]}</MenuItem>)
                            }

                            )}
                        </Select>
                        {newProjectState.algoSelected.length === 0 && <FormHelperText>Please Select The Algorithm</FormHelperText>}
                    </FormControl>
                </div>
                <div style={{ 'marginTop': '15px' }}>
                    <Button variant="contained" style={{ 'width': '90%' }} onClick={handleClickButtonOpen}  >HyperTune Algorithm</Button>
                    {errorMsg.length !== 0 && <p className='error'>{errorMsg}</p>}
                </div>
                <Popup open={open} closeOnDocumentClick onClose={closeModal} className='saveresult-model'>
                    <div className="modal">
                        <span className="close" onClick={closeModal}>
                            &times;
                        </span>
                        {
                            flag ? (
                                <>
                                    <p className="mypara" id="box1" style={{ fontSize: "25px", position: "relative", top: "-30px", width: '100%' }} >Please Select One</p>
                                    <div className="mybttons">

                                        <FormControl>
                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                defaultValue="HalvingGridSearchCV">
                                                <FormControlLabel value="HalvingGridSearchCV" onChange={e => setgrid(e.target.value)} control={<Radio />} label="HalvingGridSearchCV" />
                                                <FormControlLabel value="HalvingRandomSearchCV" onChange={e => setgrid(e.target.value)} control={<Radio />} label="HalvingRandomSearchCV" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="next">
                                        <Button variant="contained" id="nextbtn" onClick={funct1}>Proceed</Button>
                                    </div>
                                </>

                            ) :


                                !hyperdatabeforevalidation ? (
                                    <>  {mess}<p /> <BarLoader size={20} color='blue' loading={loading} width="75%" css={override} /></>
                                ) :


                                    (<>


                                        <h1>HyperTuning Results(Total Combinations: {totalCombinations}) </h1>
                                      
          <div class="HyperTuningData_view__container">
                                        <table className='customTable'>


                                            <tr>
                                                <th></th>
                                                <th colSpan="2"><h2>Before Tuning Hyperparameters</h2></th>
                                                <th colSpan="2"><h2>After Tuning Hyperparameters</h2></th>
                                            </tr>
                                            <tr>
                                                <td className='table-topic'></td>
                                                <th className='table-topic'>Performance on Validation Set</th>
                                                <th className='table-topic'>Performance on Test Set</th>
                                                <th className='table-topic'>Performance on Validation Set</th>
                                                <th className='table-topic'>Performance on Test Set</th>
                                            </tr>



                                            {allHypertunedDataElements.map(x => {
                                                return (
                                                    <tr>
                                                        {x.map(y => {
                                                            return (
                                                                <td>{y}</td>
                                                            )
                                                        }
                                                        )
                                                        } </tr>
                                                )
                                            })}


                                        </table>
                                        <Button variant="contained" id="proceed" onClick={proceedHypertune}>Proceed</Button>
                                        </div>

                                        

                                    
                                    </>



                                    )



                        }



                    </div>
                </Popup>
            </div>
            <div className='predictionSection'>
                <div className='predictionResultBlock'>
                    <div className='savedresult-tableHeader' style={{
                        background: `url(${resultBase})`,
                    }}>
                        <label className='prediction-result-block-title'>Prediction Result</label>

                    </div>
                    <div className='predictionresultcontainer'>

                        {Object.keys(predictApiData).length === 0 ? <h4>Please fill in the input values and click on Get Result button to make a prediction</h4> : <PredictSection predictionData={predictApiData.data} newProjectState={newProjectState} />}


                    </div>
                </div>
                <div className='predictionInputBlock'>
                    <div className='table-view__container'>
                        {(header && row) && <Table columns={header} data={row} />}

                    </div>

                </div>
            </div>
            {errorMsg.length !== 0 && <p className='error'>{errorMsg}</p>}
        </div>
    );
}


export default PredictionScreen;