import { CORRELATION_ALL_VARIABLES_FAIL_GOAL4, CORRELATION_ALL_VARIABLES_REQUEST_GOAL4, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL4 } from "../../constants"

// correlation reducer for goal 4
export const correlation_allVariableReducerGoal4 = (state = {CorrelationallVariableListGoal4: []}, action) => {
    switch(action.type){
        case CORRELATION_ALL_VARIABLES_REQUEST_GOAL4:
            return {loading: true}
        case CORRELATION_ALL_VARIABLES_SUCCESS_GOAL4:
            return{loading: false, CorrelationallVariableListGoal4: action.payload }
        case CORRELATION_ALL_VARIABLES_FAIL_GOAL4:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}