import { sendVariablesApi } from "../../../utils/api";
import { GET_CHART_RESULT_FAIL, GET_CHART_RESULT_REQUEST, GET_CHART_RESULT_SUCCESS } from "../../constants";

// result action for goal 1
export const getResult = (variables) => async(dispatch) => {
    dispatch({
        type: GET_CHART_RESULT_REQUEST
    })

    try{
      const {data} = await sendVariablesApi(variables);
     
      dispatch({
          type: GET_CHART_RESULT_SUCCESS,
          payload: data
      });
    }catch(error){
        dispatch({
            type: GET_CHART_RESULT_FAIL,
            payload: error.message
        });
    }
}