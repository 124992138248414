import { SUMMARY_FAIL_GOAL4, SUMMARY_REQUEST_GOAL4, SUMMARY_SUCCESS_GOAL4 } from "../../constants";

// summary reducer for goal 4
export const summaryReducerGoal4 = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST_GOAL4:
            return {loading: true}
        case SUMMARY_SUCCESS_GOAL4:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL_GOAL4:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}