import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';

function Chart({ chartID,value }) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_dark);
        am4core.useTheme(am4themes_animated);
        let x = am4core.create(`${chartID}`, am4charts.GaugeChart);
        if(x.logo){
            x.logo.disabled = true;
        }
        x.innerRadius = -15;
        var axis = x.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;

        var colorSet = new am4core.ColorSet();

        var range0 = axis.axisRanges.create();
        range0.value = 0;
        range0.endValue = 50;
        range0.axisFill.fillOpacity = 1;
        range0.axisFill.fill = colorSet.getIndex(0);

        var range1 = axis.axisRanges.create();
        range1.value = 50;
        range1.endValue = 80;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = colorSet.getIndex(2);

        var range2 = axis.axisRanges.create();
        range2.value = 80;
        range2.endValue = 100;
        range2.axisFill.fillOpacity = 1;
        range2.axisFill.fill = colorSet.getIndex(4);
        var hand = x.hands.push(new am4charts.ClockHand());
        hand.value = parseInt(value);

        chart.current = x;

        return () => {
            x.dispose();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartID]);
    return (
        <div id={chartID} className='chart' style={{ width: "100%", height: "175px" }}></div>
    );
}

export default Chart;