import React from 'react';
import MasterPage from '../../MasterPage';
import userPic from '../../Images/userPic.png';
import './profile.css';
import { useSelector } from 'react-redux';

function ViewProfilePage({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal}) {

    const User =  useSelector(state => state.User);
    const {UserInfo} = User;

    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal = {selectedGoal} setSelectedGoal = {setSelectedGoal} needSidebar={false} />
        <div className='landingscreen screen'>
            <div>
            
            <div className='landing-cards-group'>
                <div  className='profile-card'>               
                    
                    <img src={userPic} className="userPic"  alt="prediction-icon" />
                    <p>{UserInfo.username}</p>
                    <h3>{UserInfo.email}</h3>
                    <h3>Role : {UserInfo.isAdmin ? 'Admin': 'User'}</h3>
                    <button className='continue-button edit-button'>
                    <span className='button-text'>Edit Profile</span>
                    <svg fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </button>
                </div>
                
            </div>

                
            </div>
        </div>
        
        </>
    );
}

export default ViewProfilePage;