import capitalize from "./capitalize";
import HelpTooltip from '../Components/SubComponent/HelpTooltip';

export const getNewProjectTableHeader = () => {
    const headerContent = [];
    headerContent.push({
        Header: 'Variables',
        accessor: (row) => {return capitalize(row.varName.replaceAll('_', ' '))}
      }
        ) 

   return headerContent;

}


export const getNewProjectsummaryHeader = () => {
  const headerContent = [];
  headerContent.push({
      Header: 'Variables',
      accessor: (row) => {return capitalize(row.VariableName.replaceAll('_', ' '))}
    },
    {
      Header: <p className="table-header-tooltip">Unit <HelpTooltip content="A unit of observation is the unit described by the data that one analyzes. A study may treat groups as a unit of observation with a country as the unit of analysis, drawing " /></p>,
      accessor: 'Unit',
    },{
      Header: <p className="table-header-tooltip">Mean <HelpTooltip content="The Mean value of a dataset is the average value i.e. a number around which a whole data is spread out. All values used in calculating the average are weighted equally when defining the Mean." /></p>,
      accessor: 'Mean',
    },{
      Header: <p className="table-header-tooltip">Std. Dev. <HelpTooltip content="The standard deviation (σ) is a measure of the spread of the distribution. The larger the standard deviation, the bigger the spread. For a discrete set of values, the standard " /></p>,
      accessor: 'StandardDeviation',
    },
    {
      Header: <p className="table-header-tooltip">Min <HelpTooltip content="The minimum is the smallest value in the data set. " /></p>,
      accessor: 'Minimum',
    },
    {
      Header: <p className="table-header-tooltip">Max <HelpTooltip content="The maximum is the largest value in the data set." /></p>,
      accessor: 'Maximum',
    },
    {
      Header: <p className="table-header-tooltip">Median <HelpTooltip content="Median is a statistical measure that determines the middle value of a dataset listed in ascending order (i.e., from smallest to largest value). The measure divides the lower " /></p>,
      accessor: 'Median',
    },
    {
      Header: <p className="table-header-tooltip">NA's <HelpTooltip content="missing values " /></p>,
      accessor: 'NA',
    },
    {
      Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
      accessor: 'N',
    }
      ) 

 return headerContent;

}


export const getNewProjectsummarycategoricalVariablesHeaderHeader = () => {
  const headerContent = [];
  headerContent.push({
      Header: 'Variable Name',
      accessor: (row) => {return capitalize(row.VariableName.replaceAll('_', ' '))}
    },
    {
      Header: 'Unique Values',
      accessor: 'TotalNumberOfUniqueValues'
    },
    
      ) 

 return headerContent;

}



export const getNewProjectPredictHeaders = (handleInputChange, newProjectState) => {
  const headerContent = [];


  const InputOrSelectOrDate = (indVar) => {
    let obj;

    obj = newProjectState.cat_var.find(obj => obj.name === indVar)
    if (obj) {
      if (obj.type === 'select') {
        return (<select value={newProjectState.predictVarList[indVar]}  className="predict-select" onChange={(e) => handleInputChange(e, indVar)}>
          {obj.options.map(option => <option value={option} key={option}>{option}</option>)}
        </select>)
      }
  
    }
    return <input value={newProjectState.predictVarList[indVar]} onChange={(e) => handleInputChange(e, indVar)} className="predict-input" type="text" />
  }

  headerContent.push(
    {

      id: 'Ind. Variable',
      Header: <p className="table-header-tooltip">Independent Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
      accessor: (row) => capitalize(row['varName'].replaceAll('_', ' '))
    },
    
    {
      Header: 'Action',
      accessor: (row, i) => {
       
        return InputOrSelectOrDate(row['varName'])
      }
    }
  )
  return headerContent
}