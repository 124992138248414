import { getSummaryApiGoal5 } from "../../../utils/api";
import {SUMMARY_FAIL_GOAL5, SUMMARY_REQUEST_GOAL5, SUMMARY_SUCCESS_GOAL5 } from "../../constants";

// summary action for goal 5
export const getSummaryGoal5 = () => async(dispatch) => {
    dispatch({
        type: SUMMARY_REQUEST_GOAL5
    })

    try{
      const {data} = await getSummaryApiGoal5();
      dispatch({
          type: SUMMARY_SUCCESS_GOAL5,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SUMMARY_FAIL_GOAL5,
            payload: error.message
        })
    }
}