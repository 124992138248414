import { getAllVariableApiGoal6 } from "../../../utils/api";
import { ALL_VARIABLES_FAIL_GOAL6, ALL_VARIABLES_REQUEST_GOAL6, ALL_VARIABLES_SUCCESS_GOAL6 } from "../../constants";

// all variable action for goal 6
export const getAllVariableGoal6 = () => async(dispatch) => {
    dispatch({
        type: ALL_VARIABLES_REQUEST_GOAL6
    })

    try{
      const {data} = await getAllVariableApiGoal6();
      dispatch({
          type: ALL_VARIABLES_SUCCESS_GOAL6,
          payload: data
      });
    }catch(error){
        dispatch({
            type: ALL_VARIABLES_FAIL_GOAL6,
            payload: error.message
        })
    }
}