import '../../ModelResult.css'
import capitalize from '../../../../utils/capitalize'
import LineChart from '../../LineChart'

// import { useSelector } from 'react-redux';

const ModelResult = ({result}) => {

    if (result) {
       
        const dataset = result["Independable Variable"].map((el,i) => {
            return [el,result["Weightage"][i]]
        })

        return (
            <div className='model_result'>       
                <div className="charts-group">
                    <LineChart title='Independent variable  vs Weightage' yLabel='Weightage' dataset={dataset} />
                </div>
                
                <div className="cards-group">
                    {result["Independable Variable"].map((el,i) => {
                        return (
                            <ModelResultCard key={i} independVariable={el} dependVariable={result["Dependable Variable"][i]} drivingForce={result["Driving Force"][i]} weightage = {result["Weightage"][i]} pValue={result["P Value"][i]} />
                        )
                    })}
                </div>
            </div>
        )
    }

    return  <div> loading </div>
}

const ModelResultCard = ({independVariable, dependVariable, weightage, drivingForce, pValue} ) => {
    const change = (str) => {
        return capitalize(str.replace(/_/g, ' '))
    }
    return (
        <div className="card">
            <div>
            <span className="card-title">{change(independVariable)}</span>
            <div className='card-body'>
                <div>
                    <span className="card-number">{weightage}</span>
                    <span className='card-text'>Weightage</span>
                </div>
                <div>
                    <span className="card-number"> {pValue}</span>
                    <span className='card-text'> P value</span>
                </div>
            </div>
            <p>Dependent variable: {change(dependVariable)}</p>
            <p>Driving force: {drivingForce} {(drivingForce === 'High' || drivingForce === 'high') ? <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
</svg> : <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
</svg>}</p>
            </div>
        </div> 
    )
}

export default ModelResult