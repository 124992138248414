import { USER_LOGIN_SAVE, USER_LOGOUT } from "../../constants";

export const getloggedInUser = (data) => async(dispatch) => {
    dispatch({
        type: USER_LOGIN_SAVE,
        payload: data
    })
}


export const logOutUser = () => async(dispatch) => {
    dispatch({
        type: USER_LOGOUT,
       
    })
}