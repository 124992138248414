import { SUMMARY_FAIL_GOAL6, SUMMARY_REQUEST_GOAL6, SUMMARY_SUCCESS_GOAL6 } from "../../constants";

// summary reducer for goal 6
export const summaryReducerGoal6 = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST_GOAL6:
            return {loading: true}
        case SUMMARY_SUCCESS_GOAL6:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL_GOAL6:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}