import { GET_CHART_RESULT_FAIL_GOAL2, GET_CHART_RESULT_REQUEST_GOAL2, GET_CHART_RESULT_SUCCESS_GOAL2, RESET_DATA_GOAL2 } from "../../constants"

// result reducer for goal 1
export const gotModalResultReducerGoal2 = (state = {hasResultGoal2: []}, action) => {
    switch(action.type){
        case GET_CHART_RESULT_REQUEST_GOAL2:
            return {loading: true}
        case GET_CHART_RESULT_SUCCESS_GOAL2:
            return{loading: false, hasResultGoal2: action.payload }
        case GET_CHART_RESULT_FAIL_GOAL2:
            return {loading : false, error: action.payload}
        case RESET_DATA_GOAL2:
            return {loading : false, hasResultGoal2: []}
        default:
            return state
    }
}
