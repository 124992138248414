import { getCorrelationAllVarGoal3 } from "../../../utils/api";
import { CORRELATION_ALL_VARIABLES_FAIL_GOAL3, CORRELATION_ALL_VARIABLES_REQUEST_GOAL3, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL3 } from "../../constants";

// correlation action for goal 3
export const Correlation_getAllVariableGoal3 = () => async(dispatch) => {
    dispatch({
        type: CORRELATION_ALL_VARIABLES_REQUEST_GOAL3
    })

    try{
      const {data} = await getCorrelationAllVarGoal3();
      dispatch({
          type: CORRELATION_ALL_VARIABLES_SUCCESS_GOAL3,
          payload: data
      });
    }catch(error){
        dispatch({
            type: CORRELATION_ALL_VARIABLES_FAIL_GOAL3,
            payload: error.message
        })
    }
}