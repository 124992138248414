import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './PerdictiveModels.css';
import MathJax from 'react-mathjax';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function PerdictiveModels() {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //mathematical formulae and symbols to be written as a string
  const gaussianNaiveBayes = `\\color{white}{p(x = v | C_k) = \\frac{1}{\\sqrt{2\\pi\\ \\sigma^2_k}}\\  e^- ({\\frac{v-u_k^2}{2\\sigma^2_k}})}`; 
  const xgBoost = `\\color{white}{\\hat{\\phi_{m}} = arg\\min_{\\phi \\in Φ} \\sum_{i=1}^N \\frac{1}{2} h_m (x_i) [- \\frac{g_m(x_i)}{h_m(x_i)} - \\phi (x_i)]^2}`
  const logisticRegression = "\\color{white}{p(x) = \\sigma(t) = \\frac {1}{1+e^-(\\beta_0 + \\beta_1)}}"
  const gradientBoosting = "\\color{white}{F_m(x) = F_{m-1}(x) + arg\\min_{h_m \\in \\ H} [\\sum_{i=1}^n L(y_i,F_{m-1}(x^i) + h_m(x_i))]}"
  const randomForest = "\\color{white}{\\hat{y} = \\frac{1}{m} \\sum_{j=1}^m \\sum_{i=1}^n W_j (x_i,x')y_i = \\sum_{i=1}^n (\\frac{1}{m} \\sum_{j=1}^m W_j (x_i,x'))y_i}"
  return (
    <div>
      <MathJax.Provider>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Random Forest</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <MathJax.Node formula={randomForest} />

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>XGBoosting</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <MathJax.Node formula={xgBoost} />

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Gradient Boosting</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <MathJax.Node formula={gradientBoosting} />

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Logistic Regression</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <MathJax.Node formula={logisticRegression} />

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Gaussian Naive Bayes</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <MathJax.Node formula={gaussianNaiveBayes} />

        </AccordionDetails>
      </Accordion>
      </MathJax.Provider>
    </div>
  );
}
