/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './SavedResultScreen.css'
import resultBase from '../../Images/resultBase.svg';
import { useSelector, useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getAllVariable } from '../../../Redux/Reducers/allVariable/allVariableAction';
import { getAllVariableHeader,getVarUsedHeader } from '../../../utils/getSavedResultHeaders';
import Table from '../HomeScreen/Table/Table';
import MasterPage from '../../MasterPage';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
// import AllVarList from '../../Images/icons8-list-64.png'
// import Accuracy from '../../Images/icons8-precision-64.png'
// import Algo from '../../Images/icons8-algorithm-42.png'
// import SelectedList from '../../Images/icons8-checklist-50 (1).png'
import OverViewCard from '../OverviewCard/OverviewCard';
import base from '../../Images/base.png';
import TableView from './TableLayout/TableView';
import { getGoalResult } from '../../../Redux/Reducers/GoalResult/GoalResultAction'
import ModelResult from './ModelResultTab/ModelResultTab';
import { json } from 'd3';

function SavedResultScreen({ setCurrentlyActiveTab, currentlyActiveTab, selectedGoal, setSelectedGoal }) {

    const dispatch = useDispatch();
    const [header, setHeader] = useState();
    const [VariableUsedHeader, setVariableUsedHeader] = useState();
    const [row, setRow] = useState();
    const [VariableUsedRow, setVariableUsedRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isVariableUsedLoading, setIsVariableUsedLoading] = useState(false);
    const [detailsProps, setDetailsProps] = useState({})
    const [currentTab, setCurrentTab] = useState(0);
    const [result ,setResult] = useState();
    // let location = useLocation();
    let params = useParams();

 


    let navigate = useNavigate();

    const override = css`position: absolute; transform: translate(-20%, 300%);`;

    // const allVariable = useSelector(state => state.allVariableReducer);
    // const { loading, error: error1, allVariableList } = allVariable;

    const SelectedTrainedModelReducer = useSelector(state => state.SelectedTrainedModelReducer);
    const { selectedTrainedModel } = SelectedTrainedModelReducer;

    const selectedVariable = useSelector(state => state.selectedVariableReducer);
    const { loading: loading, selectedVariableList } = selectedVariable;

    const selectedVariableGoal2 = useSelector(state => state.selectedVariableReducerGoal2);
    const { loading: loadingGoal2, selectedVariableList: selectedVariableListGoal2 } = selectedVariableGoal2;

    const selectedVariableGoal3 = useSelector(state => state.selectedVariableReducerGoal3);
    const { loading: loadingGoal3, selectedVariableList: selectedVariableListGoal3 } = selectedVariableGoal3;

    const selectedVariableGoal4 = useSelector(state => state.selectedVariableReducerGoal4);
    const { loading: loadingGoal4, selectedVariableList: selectedVariableListGoal4 } = selectedVariableGoal4;

    const selectedVariableGoal5 = useSelector(state => state.selectedVariableReducerGoal5);
    const { loading: loadingGoal5, selectedVariableList: selectedVariableListGoal5 } = selectedVariableGoal5;

    const selectedVariableGoal6 = useSelector(state => state.selectedVariableReducerGoal6);
    const { loading: loadingGoal6, selectedVariableList: selectedVariableListGoal6 } = selectedVariableGoal6;

    const selectedVariableGoal7 = useSelector(state => state.selectedVariableReducerGoal7);
    const { loading: loadingGoal7, selectedVariableList: selectedVariableListGoal7 } = selectedVariableGoal7;

    //Saved Model Result
    const goalResultReducer = useSelector(state => state.goalResultReducer);
    const { loading: loadingModelResult, error: error, goalResult } = goalResultReducer;

    const [sortedField, setSortedField] = useState('')

    const handleButtonClick = () => {
        navigate('/modelvariable');
    }

    // useEffect(() => {
    //     dispatch(getAllVariable())
    //     const columns = getAllVariableHeader();
    //     setHeader(columns);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    //setting headers for table
    // useEffect(() => {
    //     setIsLoading(loading)
    //     setRow(allVariableList)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loading])

    useEffect(() => {
        const columns = getVarUsedHeader();
        setVariableUsedHeader(columns);
        dispatch(getGoalResult(selectedTrainedModel.from))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTrainedModel])

    useEffect(() => {
        if(!loading && goalResult && goalResult[0] && goalResult[0].result){
      
            setIsLoading(false)
            setResult(goalResult[0].result)
        }
    },[loadingModelResult])

    useEffect(() => {
 
        if (selectedTrainedModel.from === 'goal1') {
            setVariableUsedRow(selectedVariableList); setIsVariableUsedLoading(loading)
        }
        if (selectedTrainedModel.from === 'goal2') {
            setVariableUsedRow(selectedVariableListGoal2); setIsVariableUsedLoading(loadingGoal2)
        }
        if (selectedTrainedModel.from === 'goal3') {
            setVariableUsedRow(selectedVariableListGoal3); setIsVariableUsedLoading(loadingGoal3)
        }
        if (selectedTrainedModel.from === 'goal4') {
            setVariableUsedRow(selectedVariableListGoal4); setIsVariableUsedLoading(loadingGoal4)
        }
        if (selectedTrainedModel.from === 'goal5') {
            setVariableUsedRow(selectedVariableListGoal5); setIsVariableUsedLoading(loadingGoal5)
        }
        if (selectedTrainedModel.from === 'goal6') {
            setVariableUsedRow(selectedVariableListGoal6); setIsVariableUsedLoading(loadingGoal6)
        }
        if (selectedTrainedModel.from === 'goal7') {
            setVariableUsedRow(selectedVariableListGoal7); setIsVariableUsedLoading(loadingGoal7)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, loadingGoal2, loadingGoal3, loadingGoal4, loadingGoal5, loadingGoal6, loadingGoal7])

    useEffect(() => {
      
        if (selectedTrainedModel.from === 'goal1') {
            setDetailsProps({
                goalName: 'Loan Approval Prediction',
                highestAccuracy: '99.77%' ,
                highestAccuracyModelName: 'Logistic Regression' ,
                depVariable: 'Will Default',
                depVariableDef: 'Current status of the loan',
                algorithms: 5,
                selectedVariables: 20,
                selectedGoal: "Goal1"
            })
        }
        else if (selectedTrainedModel.from === 'goal2') {
            setDetailsProps({
                goalName: 'Self Learning Credit Risk',
                highestAccuracy: '98.60%' ,
                highestAccuracyModelName: 'XGBoost Classifier' ,
                depVariable: 'Good Bad',
                depVariableDef: 'Good or Bad credit score (0 for bad and 1 for good)',
                algorithms: 5,
                selectedVariables: 15,
                selectedGoal: "Goal2"
            })
        }
        else if (selectedTrainedModel.from === 'goal3') {
            setDetailsProps({
                goalName: 'Predictive analytics to determine individual interest rates',
                highestAccuracy: '98.93%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'Int Rate',
                depVariableDef: 'Interest Rate on the loan',
                algorithms: 6,
                selectedVariables: 39,
                selectedGoal: "Goal3"
            })
        }
        else if (selectedTrainedModel.from === 'goal4') {
            setDetailsProps({
                goalName: 'Model to estimate the risk of liquidity based on payment history',
                highestAccuracy: '99.31%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'High Low',
                depVariableDef: 'The risk of incurring losses',
                algorithms: 5,
                selectedVariables: 33,
                selectedGoal: "Goal4"
            })
        }
        else if (selectedTrainedModel.from === 'goal5') {
            setDetailsProps({
                goalName: 'Cashflow based credit model',
                highestAccuracy: '99.52%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'Good Bad',
                depVariableDef: 'Good or Bad credit score (0 for bad and 1 for good',
                algorithms: 5,
                selectedVariables: 26,
                selectedGoal: "Goal5"
            })
        }
        else if (selectedTrainedModel.from === 'goal6') {
            setDetailsProps({
                goalName: 'Automated Credit Decisioning',
                highestAccuracy: '99.74%' ,
                highestAccuracyModelName: 'Random Forest Classifier ' ,
                depVariable: 'Approved',
                depVariableDef: 'Customer with good credit score is approved and vice versa',
                algorithms: 7,
                selectedVariables: 17,
                selectedGoal: "Goal6"
            })
        }
        else {
            setDetailsProps({
                goalName: 'Behavioral analytics',
                highestAccuracy: '60.3174%',
                highestAccuracyModelName: 'Random Forest',
                depVariable: 'High Low',
                depVariableDef: 'The risk of incurring losses',
                algorithms: 6,
                selectedVariables: 33,
                selectedGoal: "Goal7"
                
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGoal])


console.log(VariableUsedRow)
let newVariableUsedRow =[];
const remove_unwanted =()=>{
    VariableUsedRow.forEach(element => {
     //   console.log(element)
        element = json.toString(element)
     element =   element.split('�').join(' ')
     newVariableUsedRow.push(element)
    
});
}
console.log(newVariableUsedRow)
if(VariableUsedRow){
    remove_unwanted()
}


    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} needSidebar={false} needSidebar2={true} />
            <div className='nosidebar-screen screen'>
                {/* <div className='tableHeader savedresult-tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <label className='table-title'>All Variables</label>
                    <button className='view-variables-button' onClick={handleButtonClick}>View Variables used for each model</button>
                </div>
                <div className="tableContainer boundary">
                <div className='table-view__container'>
                    {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                        <>
                            {(header && row) && <Table columns={header} data={row} />}
                        </>
                    }
                </div>

            </div> */}
                <OverViewCard {...detailsProps} />
                <div className='relative-info-wrapper'>
                    <div className='homeheader'>
                        <div className='tableHeader' style={{
                            background: `url(${base})`,
                        }}>

                            <span className="homeTabStyle tab" onClick={() => setCurrentTab(0)}><label>All Variables</label>
                                <div className={`${currentTab === 0 && "underline"}`}></div>
                            </span>
                            <span className="homeTabStyle tab" onClick={() => {setCurrentTab(1); setSortedField('Mean')}}>
                                <label>Summary Statistics</label>
                                <div className={`${currentTab === 1 && "underline"}`}></div>
                            </span>
                            <span className="homeTabStyle tab" onClick={() => {setCurrentTab(2);setSortedField('Correlation')}}>
                                <label>Correlation</label>
                                <div className={`${currentTab === 2 && "underline"}`}></div>
                            </span>
                        </div>
                        <TableView currentTab={currentTab} selectedGoal={selectedGoal} sortedField={sortedField} />

                    </div>
                </div>
                <div className='third-row-wrapper'>
                    <div className='variable-used'>
                        <div className='tableHeader' style={{
                            background: `url(${resultBase})`,
                        }}>
                            <span className="homeTabStyle tab"><label>Independent Variables</label>
                                <div className={`${currentTab === 0 && "underline"}`}></div>
                            </span>

                        </div>
                        <div className="tableContainer boundary">
                            <div className='table-view__container'>
                                {isVariableUsedLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isVariableUsedLoading} css={override} /></div> :
                                    <>
                                        {(VariableUsedHeader && VariableUsedRow) && <Table columns={VariableUsedHeader} data={VariableUsedRow} />}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='model-result-section'>
                    <div className='tableHeader' style={{
                            background: `url(${resultBase})`,
                        }}>
                            <span className="homeTabStyle tab"><label>Model Result</label>
                                <div className={`${currentTab === 0 && "underline"}`}></div>
                            </span>

                        </div>
                    {(!isLoading) ?  <ModelResult selectedGoal={selectedGoal} result={result} /> : <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div>}
                    </div>
                </div>
            </div>


        </>
    );
}

export default SavedResultScreen;