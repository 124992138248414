import capitalize from "./capitalize";
import HelpTooltip from '../Components/SubComponent/HelpTooltip'

export const getAllVariableHeader = () => {
  const headerContent = [];
  // in case of different headers for each goal
  headerContent.push({
    Header: 'Variables',
    accessor: (row) => {
      return capitalize(row.Variables.replaceAll('_', ' '))
    }
  },
    {
      Header: 'Definitions',
      accessor: 'Definitions', // accessor is the "key" in the data
    },
  )
  return headerContent
}

export const getModelVariableHeader = () => {
  const headerContent = [];

  headerContent.push(
    {
      id: 'Dep Variable',
      Header: <p className="table-header-tooltip">Dependent Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
      accessor: (row) => {
        if (row.Dependent_Variable) {
          return capitalize(row.Dependent_Variable.replaceAll('_', ' '))
        }
        return ''
      }
    },
    {
      id: 'Ind. Variable',
      Header: <p className="table-header-tooltip">Independent Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
      accessor: (row) => {
        if (row.Independent_Variable) {
          return capitalize(row.Independent_Variable.replaceAll('_', ' '))
        }
        return ''
      }
    },
  )
  return headerContent
}

export const getSummaryStatsHeader = () => {
  const headerContent = [];

  headerContent.push({
    Header: 'Numerical Variables',
    columns: [
      {
        Header: 'Variable Name',
        accessor: (row) => capitalize(row.Variable_Name.replaceAll('_', ' '))
      },
      {
        Header: <p className="table-header-tooltip">Unit <HelpTooltip content="A unit of observation is the unit described by the data that one analyzes. A study may treat groups as a unit of observation with a country as the unit of analysis, drawing " /></p>,
        accessor: 'Unit',
      },
      {
        Header: <p className="table-header-tooltip">Mean <HelpTooltip content="The Mean value of a dataset is the average value i.e. a number around which a whole data is spread out. All values used in calculating the average are weighted equally when defining the Mean." /></p>,
        accessor: 'Mean',
      },
      {
        Header: <p className="table-header-tooltip">Std. Dev. <HelpTooltip content="The standard deviation (σ) is a measure of the spread of the distribution. The larger the standard deviation, the bigger the spread. For a discrete set of values, the standard " /></p>,
        accessor: 'Std',
      },
      {
        Header: <p className="table-header-tooltip">Min <HelpTooltip content="The minimum is the smallest value in the data set. " /></p>,
        accessor: 'Min',
      },
      {
        Header: <p className="table-header-tooltip">Max <HelpTooltip content="The maximum is the largest value in the data set." /></p>,
        accessor: 'Max',
      },
      {
        Header: <p className="table-header-tooltip">Median <HelpTooltip content="Median is a statistical measure that determines the middle value of a dataset listed in ascending order (i.e., from smallest to largest value). The measure divides the lower " /></p>,
        accessor: 'Median',
      },
      {
        Header: <p className="table-header-tooltip">NA's <HelpTooltip content="missing values " /></p>,
        accessor: 'NA',
      },
      {
        Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
        accessor: 'N',
      }
    ],
  },
    {
      Header: 'Factors',
      columns: [
        {
          Header: 'Variable_Name',
          accessor: 'Factor_Varable_Name',
        },
        {
          Header: '',
          accessor: 'Factor_Varable_category',
        },
        {
          Header: 'Counts',
          accessor: 'Counts',
        }, {
          Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
          accessor: 'n',
        }
      ],
    },
  )

  return headerContent
}

export const getPredictHeaders = (dataToSend,handleInputChange, goal) => {
  const headerContent = [];

  const goalOptions = [
    { name: 'grade', type: 'select', options: ['---','C', 'D', 'B', 'A', 'E', 'F', 'G'] },
    {
      name: 'sub_grade', type: 'select', options: ['---','C1', 'D2', 'D1', 'C4', 'C3', 'B3', 'A4', 'B5', 'C5', 'D4', 'C2', 'E1', 'E4', 'B4',
        'D3', 'A1', 'E5', 'B2', 'B1', 'A5', 'A3', 'D5', 'E3', 'A2', 'E2', 'F4', 'G1', 'G2',
        'F1', 'F5', 'F2', 'F3', 'G4', 'G5', 'G3']
    },
    { name: 'home_ownership', type: 'select', options: ['---','RENT', 'MORTGAGE', 'OWN', 'ANY', 'NONE', 'OTHER'] },
    { name: 'verification_status', type: 'select', options: ['---','Not Verified', 'Source Verified', 'Verified'] },
    {
      name: 'loan_status', type: 'select', options: ['---','Current', 'Fully Paid', 'In Grace Period', 'Late (31-120 days)',
        'Late (16-30 days)', 'Charged Off', 'Default', 'Issued']
    },
    { name: 'pymnt_plan', type: 'select', options: ['---','n', 'y'] },
    {
      name: 'purpose', type: 'select', options: ['---','debt_consolidation', 'credit_card', 'house', 'car', 'other', 'vacation',
        'home_improvement', 'small_business', 'major_purchase', 'medical',
        'renewable_energy', 'moving', 'wedding', 'educational']
    },
    { name: 'initial_list_status', type: 'select', options: ['---','w', 'f'] },
    { name: 'application_type', type: 'select', options: ['---','Individual', 'Joint App', 'INDIVIDUAL', 'JOINT'] },
    { name: 'issue_d', type: 'date' },
    { name: 'last_pymnt_d', type: 'date' },
    { name: 'last_credit_pull_d', type: 'date' },
    { name: 'earliest_cr_line', type: 'date' }
  ]

  const inputValues = {'loan_amnt': 15506.872973889134,
  'funded_amnt': 15506.857387835233,
  'funded_amnt_inv': 15501.819603076641,
  'term': 43.29141519846597,
  'int_rate': 13.042466650118937,
  'installment': 456.2062956547644,
  'emp_length': 5.944250352684913,
  'annual_inc': 81151.777668373,
  'dti': 18.935760514171307,
  'delinq_2yrs': 0.30879650385090507,
  'inq_last_6mths': 0.5569103400637878,
  'open_acc': 11.844183470468273,
  'pub_rec': 0.18718249220925728,
  'revol_bal': 17171.806980237463,
  'revol_util': 50.41699869909994,
  'total_acc': 24.447074268498596,
  'out_prncp': 5337.104482353337,
  'out_prncp_inv': 5335.8922574841,
  'total_pymnt': 11595.768574190157,
  'total_pymnt_inv': 11591.544803448971,
  'total_rec_prncp': 9059.390779546622,
  'total_rec_int': 2411.866665722277,
  'total_rec_late_fee': 1.4850598392873442,
  'recoveries': 123.02638339352336,
  'collection_recovery_fee': 20.734502899948698,
  'last_pymnt_amnt': 3144.958055211498,
  'collections_12_mths_ex_med': 0.017965915652875204,
  'policy_code': 1.0,
  'acc_now_delinq': 0.0039512588232140985,
  'tot_coll_amt': 222.87178639476832,
  'tot_cur_bal': 147453.9660239253,
  'total_rev_hi_lim': 35450.889662768655,
  'grade': 'B',
  'sub_grade': 'C1',
  'home_ownership': 'MORTGAGE',
  'verification_status': 'Source Verified',
  'loan_status': 'Current',
  'pymnt_plan': 'n',
  'purpose': 'debt_consolidation',
  'zip_code': '750xx',
  'addr_state': 'CA',
  'initial_list_status': 'w',
  'application_type': 'Individual'}

  const InputOrSelectOrDate = (indVar) => {
    let obj;

    obj = goalOptions.find(obj => obj.name === indVar)
   
    if (obj) {
      if (obj.type === 'select') {
        return (<select className="predict-select" onChange={(e) => handleInputChange(e, indVar)}>
          {obj.options.map(option => <option value={option} key={option}>{option}</option>)}
        </select>)
      }
      return <input className="date-picker" type="date" onChange={(e) => handleInputChange(e, indVar, 'date')} />
    }
    return <input value={dataToSend[indVar]} onChange={(e) => {handleInputChange(e, indVar); console.log(dataToSend[indVar],'in header');}} className="predict-input" type="text"  />
  }

  headerContent.push({
    id: 'Dep Variable',
    Header: <p className="table-header-tooltip">Dependent Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
    accessor: (row) => capitalize(row['Dep Var'].replaceAll('_', ' '))
  },
    {
      Header: 'Weightage',
      accessor: 'Weightage'
    },
    {

      id: 'Ind. Variable',
      Header: <p className="table-header-tooltip">Independent Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
      accessor: (row) => capitalize(row['Ind Var'].replaceAll('_', ' '))
    },
    {
      Header: 'Driving Force',
      accessor: (row) => capitalize(row['Driving Force'])
    },
    {
      Header: 'Action',
      accessor: (row, i) => {
      
        return InputOrSelectOrDate(row['Ind Var'])
      }
    }
  )
  return headerContent
}

export const getSavedResultTableHeader = (currentTab,selectedGoal) => {
  const headerContent = [];
  if (currentTab === 0) {
    // in case of different headers for each goal
    headerContent.push({
      Header: 'Variables',
      accessor: (row) => { return capitalize(row.Variables.replaceAll('_', ' ')) }
    },
      {
        Header: 'Definitions',
        accessor: 'Definitions', // accessor is the "key" in the data
      },
    )
  }

  if (currentTab === 1) {
    headerContent.push({
      Header: 'Numerical Variables',
      columns: [
        {
          Header: 'Variable Name',
          accessor: (row) => capitalize(row.Variable_Name.replaceAll('_', ' '))
        },
        {
          Header: <p className="table-header-tooltip">Unit <HelpTooltip content="A unit of observation is the unit described by the data that one analyzes. A study may treat groups as a unit of observation with a country as the unit of analysis, drawing " /></p>,
          accessor: 'Unit',
        },
        {
          Header: <p className="table-header-tooltip">Mean <HelpTooltip content="The Mean value of a dataset is the average value i.e. a number around which a whole data is spread out. All values used in calculating the average are weighted equally when defining the Mean." /></p>,
          accessor: 'Mean',
        },
        {
          Header: <p className="table-header-tooltip">Std. Dev. <HelpTooltip content="The standard deviation (σ) is a measure of the spread of the distribution. The larger the standard deviation, the bigger the spread. For a discrete set of values, the standard " /></p>,
          accessor: 'Std',
        },
        {
          Header: <p className="table-header-tooltip">Min <HelpTooltip content="The minimum is the smallest value in the data set. " /></p>,
          accessor: 'Min',
        },
        {
          Header: <p className="table-header-tooltip">Max <HelpTooltip content="The maximum is the largest value in the data set." /></p>,
          accessor: 'Max',
        },
        {
          Header: <p className="table-header-tooltip">Median <HelpTooltip content="Median is a statistical measure that determines the middle value of a dataset listed in ascending order (i.e., from smallest to largest value). The measure divides the lower " /></p>,
          accessor: 'Median',
        },
        {
          Header: <p className="table-header-tooltip">NA's <HelpTooltip content="missing values " /></p>,
          accessor: 'NA',
        },
        {
          Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
          accessor: 'N',
        }
      ],
    },
      {
        Header: 'Factors',
        columns: [
          {
            Header: 'Variable_Name',
            accessor: 'Factor_Varable_Name',
          },
          {
            Header: '',
            accessor: 'Factor_Varable_category',
          },
          {
            Header: 'Counts',
            accessor: 'Counts',
          }, {
            Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
            accessor: 'n',
          }
        ],
      },
    )
  }

  if (currentTab === 2) {
    switch (selectedGoal) {
      case 'goal2':
        headerContent.push({
          id: 'Dep Variable',
          Header: <p className="table-header-tooltip">Dep Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
          accessor: (row) => capitalize(row.Dep_Varaible.replaceAll('_', ' '))
        },
          {
            id: 'Ind. Variable',
            Header: <p className="table-header-tooltip">Ind. Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
            accessor: (row) => capitalize(row.ind_Varaible.replaceAll('_', ' '))
          },
          {
            Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
            accessor: 'n',
          }, {
          Header: <p className="table-header-tooltip">F_Score <HelpTooltip content="The F-score, also called the F1-score, is a measure of a model's accuracy on a dataset. It is used to evaluate binary classification systems, which classify examples into 'positive' " /></p>,
          accessor: 'F_Score',
        }, {
          Header: <p className="table-header-tooltip">P-value <HelpTooltip content="probability of getting a sample like ours or more extreme than ours if the null hypothesis is correct." /></p>,
          accessor: 'P-value',
        }
        )
        break;
      case 'goal6':
        headerContent.push({
          id: 'Dep Variable',
          Header: <p className="table-header-tooltip">Dep Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
          accessor: (row) => capitalize(row.Dep_Varaible.replaceAll('_', ' '))
        },
          {
            id: 'Ind. Variable',
            Header: <p className="table-header-tooltip">Ind. Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
            accessor: (row) => capitalize(row.ind_Varaible.replaceAll('_', ' '))
          },
          {
            Header: <p className="table-header-tooltip">P-value <HelpTooltip content="probability of getting a sample like ours or more extreme than ours if the null hypothesis is correct." /></p>,
            accessor: 'P-value',
          },
          {
            Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
            accessor: 'n',
          },
          {
            Header: 'Correlation',
            accessor: 'Correlation',
          },
        )
        break;
      default:
        headerContent.push({
          id: 'Dep Variable',
          Header: <p className="table-header-tooltip">Dep Variable <HelpTooltip content="the variable that changes as a result of the independent variable manipulation. It's the outcome you're interested in measuring, and it “depends” on your independent " /></p>,
          accessor: (row) => capitalize(row.Dep_Varaible.replaceAll('_', ' '))
        },
          {
            id: 'Ind. Variable',
            Header: <p className="table-header-tooltip">Ind. Variable <HelpTooltip content="variables that are manipulated or are changed by researchers and whose effects are measured and compared. The other name for independent variables is Predictor(s)." /></p>,
            accessor: (row) => capitalize(row.ind_Varaible.replaceAll('_', ' '))
          },
          {
            Header: 'Correlation',
            accessor: 'Correlation',
          },
          {
            Header: <p className="table-header-tooltip">N <HelpTooltip content="number of observations" /></p>,
            accessor: 'n',
          }, {
          Header: 'Point Biserial Result (Correlation)',
          accessor: 'point biserial result (correlation)',
        }, {
          Header: <p className="table-header-tooltip">P-value <HelpTooltip content="probability of getting a sample like ours or more extreme than ours if the null hypothesis is correct." /></p>,
          accessor: 'P-value',
        }
        )
    }
  }
  return headerContent;
}

export const getVarUsedHeader = () => {
  const headerContent = [];
  // in case of different headers for each goal
  headerContent.push({
    Header: 'Variables',
    accessor: (row) => {
  
      return capitalize(row.Independent_Variable.replaceAll('_', ' '))
    }
  },
    {
      Header: 'Definitions',
      accessor: 'Independent_Variable_Definitions', // accessor is the "key" in the data
    },
  )
  return headerContent
}