import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getloggedInUser } from "../../../Redux/Reducers/UserLogin/UserAction";
import { loginApi, registerApi } from "../../../utils/api";
import "./LoginPage.css";

function LoginPage(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [errMsg, setErrMsg] = useState();
  return (
    <div className="main-loginContainer">
      <div className="form-Container">
        <div className="form-header">
          <div
            className="login-option"
            onClick={() => {
              setActiveTab(1);
              setErrMsg("");
            }}
          >
            <h3>Login</h3>
          </div>
          <div
            className="signup-option"
            onClick={() => {
              setActiveTab(2);
              setErrMsg("");
            }}
          >
            <h3>Sign up</h3>
          </div>
        </div>
        <div className={`bottom-line ${activeTab === 2 && "switchTab"}`}></div>
        <div className="header-base"></div>
        <div className="form-Content">
          {activeTab === 1 ? (
            <LoginComponent errMsg={errMsg} setErrMsg={setErrMsg} />
          ) : (
            <SignUpComponent errMsg={errMsg} setErrMsg={setErrMsg} />
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

function LoginComponent({ errMsg, setErrMsg }) {
  const [uname, setUName] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleChangeUserInput = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setUName(e.target.value);
  };

  const handleChangePdInput = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setPassword(e.target.value);
  };

  const handleloginbuttonClick = () => {
    const data = {
      username: uname,
      password: password,
    };
    loginApi(data)
      .then((res) => {
        if (res.data.error) {
          setErrMsg(res.data.error);
          return;
        }
        dispatch(getloggedInUser(res.data));
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        navigate("/");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="loginInputs">
      {errMsg && <p className="error">{errMsg}</p>}
      <div className="userNameInput-container">
        <input
          type="text"
          className="userNameInput"
          placeholder="Username"
          onChange={(e) => handleChangeUserInput(e)}
        />
      </div>
      <div className="pdInput-container">
        <input
          type="password"
          className="pdInput"
          placeholder="Password"
          onChange={(e) => handleChangePdInput(e)}
        />
      </div>
      <div className="loginButton" onClick={handleloginbuttonClick}>
        <h5>Login</h5>
      </div>
    </div>
  );
}

function SignUpComponent({ errMsg, setErrMsg }) {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const dispatch = useDispatch();

  let navigate = useNavigate();

  const handleChangeUserNameInput = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setUserName(e.target.value);
  };

  const handleChangeEmailInput = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setEmail(e.target.value);
  };

  const handleChangePDInput = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setPassword(e.target.value);
  };

  const handleChangeCPdInput = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setCpassword(e.target.value);
  };

  const handleClickSignUp = () => {
    const data = {
      username: userName,
      email: email,
      password: password,
      isAdmin: false,
    };

    if (!userName || !email || !password) {
      setErrMsg("Please fill in all the inputs");
      return;
    }

    if (password !== cpassword) {
      setErrMsg("Password and Confirm Password Should match");
      return;
    }

    registerApi(data)
      .then((res) => {
        if (res.data.err) {
          setErrMsg(res.data.err);
          return;
        }
        if (res.data.msg) {
          setErrMsg(res.data.msg);
          return;
        }
        dispatch(getloggedInUser(res.data));
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="loginInputs">
      {errMsg && <p className="error">{errMsg}</p>}
      <div className="userNameInput-container">
        <input
          type="text"
          className="userNameInput"
          placeholder="Username"
          onChange={(e) => handleChangeUserNameInput(e)}
        />
      </div>
      <div className="userNameInput-container">
        <input
          type="text"
          className="userNameInput"
          placeholder="Email"
          onChange={(e) => handleChangeEmailInput(e)}
        />
      </div>
      <div className="userNameInput-container">
        <input
          type="password"
          className="userNameInput"
          placeholder="Password"
          onChange={(e) => handleChangePDInput(e)}
        />
      </div>
      <div className="pdInput-container">
        <input
          type="password"
          className="pdInput"
          placeholder="Confirm Password"
          onChange={(e) => handleChangeCPdInput(e)}
        />
      </div>
      <div className="loginButton" onClick={handleClickSignUp}>
        <h5>Sign up</h5>
      </div>
    </div>
  );
}
