import { ALL_VARIABLES_FAIL_GOAL7, ALL_VARIABLES_REQUEST_GOAL7, ALL_VARIABLES_SUCCESS_GOAL7 } from "../../constants";

// all variable reducer for goal 7
export const allVariableReducerGoal7 = (state = {allVariableList: []}, action) => {
    switch(action.type){
        case ALL_VARIABLES_REQUEST_GOAL7:
            return {loading: true}
        case ALL_VARIABLES_SUCCESS_GOAL7:
            return{loading: false, allVariableList: action.payload }
        case ALL_VARIABLES_FAIL_GOAL7:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}