import React, { useEffect, useState } from 'react';
import './PredictSection.css';
import * as XLSX from "xlsx";
import capitalize from '../../../../../utils/capitalize';
import like from '../../../../Images/like.png';


function PredictSection({ predictionData, newProjectState }) {
    const [predictionValue, setPredictionValue] = useState();

    let dataForReport = []

    useEffect(() => {
       
        if (Object.keys(predictionData?.resultOptions).length >= 2) {
            setPredictionValue(predictionData.PridicitonResult)
        } else {
            const data = parseInt(predictionData.PridicitonResult);
            setPredictionValue(data)
        }

    }, [predictionData])


    const handleClickDownloadButton = () =>{
       
        const keyData = Object.keys(newProjectState.predictVarList);
        const Data = Object.values(newProjectState.predictVarList);
        

        keyData.forEach((item, i) => {
            dataForReport.push({
                "Project Name": i === 0 ? newProjectState.ProjectName: '',
                "Project Type": i === 0 ? newProjectState.projectType : '',
                "Algorithm": i === 0 ? newProjectState.algoSelected : '',
                "Independent Variables": keyData[i],
                "Independent Variables Values": Data[i],
                "Dependent Variables": i === 0 ? newProjectState.DependentVar : '',
                "Result": i === 0 ? `${predictionValue}%` : ''
            })
        })

        var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(dataForReport);

        XLSX.utils.book_append_sheet(wb, ws, `${newProjectState.ProjectName} Prediction Report`);
        XLSX.writeFile(wb, `${newProjectState.ProjectName} Prediction Report.xlsx`)

     
    }
    return (
        <>

            {predictionValue && <>
             <div style={{display : 'flex', 'textAlign': 'left'}} >
                {typeof predictionValue === 'string' ?
                <div className={`resultImg`}>
                    <img src={like} alt="risk-prediction-icon" />
                </div> :
                <div className="pie animate no-round" style={{'--p': Math.abs(predictionValue),'--c': (predictionValue > 0) ? '#3d3ec2' : '#e51a1a'}} >
                    <span>{predictionValue}</span>
                </div>
                }
                <div className='predictResultInfo'>
                    <p>{`Project Name:  ${capitalize(newProjectState.ProjectName)}`}</p>
                    <p>{`Project Type:  ${capitalize(newProjectState.projectType)}`}</p>
                    <p>{`Algorithm:  ${capitalize(newProjectState.algoSelected)}`}</p>
                    <p>{`Dependent Variables:  ${newProjectState.DependentVar}`}</p>
                    <p>{`Result: ${predictionValue}`}</p>
                    {/* <p>{`Dependent Variable: ${capitalize(newProjectState.DependentVar.replaceAll('_', ' '))}`}</p> */}

                </div>
              
            </div>
              <button className='continue-button' onClick={handleClickDownloadButton}>
              <span>Download Report</span>
              <svg fill="currentColor" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88"><path fillRule="evenodd" d="M27.25,0H95.63a27.31,27.31,0,0,1,27.25,27.25V95.63a27.31,27.31,0,0,1-27.25,27.25H27.25A27.31,27.31,0,0,1,0,95.63V27.25A27.31,27.31,0,0,1,27.25,0ZM71.59,50.47a3.38,3.38,0,1,1,4.74,4.83L63.48,68a3.4,3.4,0,0,1-4.75,0L46.07,55.48a3.38,3.38,0,1,1,4.74-4.83l7,6.91.07-24.33a3.39,3.39,0,1,1,6.77.05l-.07,24.15,7-7ZM34.48,81.55l.05-13.24a3.39,3.39,0,1,1,6.77.06l0,9.64q20.16,0,40.32,0l0-9.7a3.39,3.39,0,1,1,6.78.06l-.05,13.18h0A3.38,3.38,0,0,1,85,84.78c-15.72,0-31.39,0-47.11,0a3.38,3.38,0,0,1-3.35-3.23ZM95.63,7H27.25A20.35,20.35,0,0,0,7,27.25V95.63a20.35,20.35,0,0,0,20.29,20.29H95.63a20.35,20.35,0,0,0,20.29-20.29V27.25A20.35,20.35,0,0,0,95.63,7Z"/></svg>
          </button>
          
            
            </>
            
            }

        </>
    );
}

export default PredictSection;