import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MasterPage from '../../MasterPage';
import './homescreen.css';
import HomeScreenHeader from './HomeScreenHeader/HomeScreenHeader';
import DetailsCard from '../DetailsCards/DetailsCards'
import OverViewCard from '../OverviewCard/OverviewCard';

function HomeScreen({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal,setSavedVariables}) {
    let location = useLocation();
    const [detailsProps,setDetailsProps] = useState({})

     // setting selected goal and selected tab based on the route
    useEffect(() => {
        
            setCurrentlyActiveTab("home")
        
        if(!selectedGoal.length){
            setSelectedGoal(location.state)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location,selectedGoal.length])

    useEffect(() => {
        if(selectedGoal === 'goal1'){
            setDetailsProps({
                goalName: 'Loan Approval Prediction',
                highestAccuracy: '99.77%' ,
                highestAccuracyModelName: 'Logistic Regression' ,
                depVariable: 'Will Default',
                depVariableDef: 'Current status of the loan',
                algorithms: 5,
                selectedVariables: 20,
                selectedGoal: "Goal1"
            })  
        } 
        else if(selectedGoal === 'goal2'){
            setDetailsProps({
                goalName: 'Self Learning Credit Risk',
                highestAccuracy: '98.60%' ,
                highestAccuracyModelName: 'XGBoost Classifier' ,
                depVariable: 'Good Bad',
                depVariableDef: 'Good or Bad credit score (0 for bad and 1 for good)',
                algorithms: 5,
                selectedVariables: 15,
                selectedGoal: "Goal2"
            })  
        }
        else if(selectedGoal === 'goal3'){
            setDetailsProps({
                goalName: 'Predictive analytics to determine individual interest rates',
                highestAccuracy: '98.93%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'Int Rate',
                depVariableDef: 'Interest Rate on the loan',
                algorithms: 6,
                selectedVariables: 39,
                selectedGoal: "Goal3"
            })  
        }
        else if(selectedGoal === 'goal4'){
            setDetailsProps({
                goalName: 'Model to estimate the risk of liquidity based on payment history',
                highestAccuracy: '99.31%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'High Low',
                depVariableDef: 'The risk of incurring losses',
                algorithms: 5,
                selectedVariables: 33,
                selectedGoal: "Goal4"
            })  
        }
        else if(selectedGoal === 'goal5'){
            setDetailsProps({
                goalName: 'Cashflow based credit model',
                highestAccuracy: '99.52%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'Good Bad',
                depVariableDef: 'Good or Bad credit score (0 for bad and 1 for good',
                algorithms: 5,
                selectedVariables: 26,
                selectedGoal: "Goal5"
            })  
        }
        else if(selectedGoal === 'goal6'){
            setDetailsProps({
                goalName: 'Automated Credit Decisioning',
                highestAccuracy: '99.74%' ,
                highestAccuracyModelName: 'Random Forest Classifier ' ,
                depVariable: 'Approved',
                depVariableDef: 'Customer with good credit score is approved and vice versa',
                algorithms: 7,
                selectedVariables: 17,
                selectedGoal: "Goal6"
            })  
        }
        else{
            setDetailsProps({
                goalName: 'Behavioral analytics',
                highestAccuracy: '60.3174%' ,
                highestAccuracyModelName: 'Random Forest' ,
                depVariable: 'High Low',
                depVariableDef: 'The risk of incurring losses',
                algorithms: 6,
                selectedVariables: 33,
                selectedGoal: "Goal7"
            }) 
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedGoal])

    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />
        {/* <DetailsCard {...detailsProps} /> */}
        
        <div className='nosidebar-screen screen'>
            <OverViewCard {...detailsProps} />
            <HomeScreenHeader setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} setSavedVariables={setSavedVariables} />
        </div>
        </>
    );
}

export default HomeScreen;