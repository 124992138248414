import { sendVariablesApiGoal3 } from "../../../utils/api";
import { GET_CHART_RESULT_FAIL_GOAL3, GET_CHART_RESULT_REQUEST_GOAL3, GET_CHART_RESULT_SUCCESS_GOAL3 } from "../../constants";

// result action for goal 3
export const getResultGoal3 = (variables) => async(dispatch) => {
    dispatch({
        type: GET_CHART_RESULT_REQUEST_GOAL3
    })

    try{
      const {data} = await sendVariablesApiGoal3(variables);
      console.log(data);
      dispatch({
          type: GET_CHART_RESULT_SUCCESS_GOAL3,
          payload: data
      });
    }
    catch(error){
        dispatch({
            type: GET_CHART_RESULT_FAIL_GOAL3,
            payload: error.message,
        });
    }
}