import {forwardRef, useRef, useEffect} from 'react'

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef()
      const resolvedRef = ref || defaultRef
  
      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input  className='action-checkbox' type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
)

export default IndeterminateCheckbox