import {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MasterPage from '../../MasterPage';
import './goalscreen.css';

function GoalScreen({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal}) {
    let navigate = useNavigate();

    let location = useLocation();

     // setting selected goal based on the route
    useEffect(() => {
        if(location.pathname === '/' || location.pathname.split('/').includes('home')){
            setCurrentlyActiveTab("home")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location,selectedGoal.length])


    //setting the respective goal when the card is clicked
    const handleCardClick = (goal) => {
        setSelectedGoal(goal)
        navigate('/goalHome',{state:goal})    }

    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />
        <div className='goalscreen screen'>
            <div className='goalscreen-main'>
            <h1>Please Select a goal</h1>
            <div className='goal-cards-group'>
                <div className='goal-card' onClick={() => handleCardClick('goal1')}>
                    <h1>Goal 1</h1>
                    <h4>Loan Approval Prediction</h4>
                    <h4>Accuracy: 99.77%</h4>
                </div>
                <div className='goal-card' onClick={() => handleCardClick('goal2')}>
                    <h1>Goal 2</h1>
                    <h4>Self Learning Credit Risk</h4>
                    <h4>Accuracy: 98.60%</h4>
                </div>
                <div className='goal-card' onClick={() => handleCardClick('goal3')}>
                    <h1>Goal 3</h1>
                    <h4>predictive analytics to determine individual interest rates</h4>
                    <h4>Accuracy: 98.93%</h4>
                </div>
                <div className='goal-card' onClick={() => handleCardClick('goal4')}>
                    <h1>Goal 4</h1>
                    <h4>model to estimate the risk of liquidity based on payment history</h4>
                    <h4>Accuracy: 99.31%</h4>
                </div>
                <div className='goal-card' onClick={() => handleCardClick('goal5')}>
                    <h1>Goal 5</h1>
                    <h4>Cashflow based credit model</h4>
                    <h4>Accuracy: 99.52%</h4>
                </div>
                <div className='goal-card' onClick={() => handleCardClick('goal6')}>
                    <h1>Goal 6</h1>
                    <h4>Automated Credit Decisioning</h4>
                    <h4>Accuracy: 99.74%</h4>
                </div>
                <div className='goal-card' onClick={() => handleCardClick('goal7')}>
                    <h1>Goal 7</h1>
                    <h4>Behavioral analytics</h4>
                    <h4>Accuracy: 60.3174%</h4>
                </div>
            </div>
            </div>
        </div>
        </>
    );
}

export default GoalScreen;