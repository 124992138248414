import MasterPage from '../../MasterPage';
import './NextScreen.css'
import Data from '../../Images/data.svg'
import Upload from '../../Images/upload.svg'
import { useNavigate } from 'react-router-dom';

function NextScreen({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal}) {

    let navigate = useNavigate();

    const handleExistingDataCardClick = () => {
        navigate('/existingdata')
    }

    const handleCardClick = () => {
        
    }

    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal = {selectedGoal} setSelectedGoal = {setSelectedGoal} needSidebar={false} />
        <div className='nextscreen screen'>
            <div className='nextscreen-main'>
            <h1>Please select an option</h1>
            <div className='next-cards-group'>
                <div className='next-card' onClick={() => handleCardClick()}>
                    <div className='icon'>
                        <img src={Upload} alt="upload-icon" />
                    </div>
                    <h1>Upload Data</h1>
                    <h4>Short description</h4>
                    <button className="get-started">Get Started</button>
                </div>
                <div className='next-card'>
                    <div className='icon'>
                        <img src={Data} alt="data-icon" />
                    </div>
                    <h1>Use Existing Data</h1>            
                    <h4>Short description</h4>
                    <button onClick={() => handleExistingDataCardClick()} className="get-started">Get Started</button>
                </div>
            </div>
            </div>
        </div>
        </>
    );
}

export default NextScreen;