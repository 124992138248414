import './SlideBarXd.css';
import shadow from '../Images/shadow.png';
import home from '../Images/home.png';
import dashboard from '../Images/dashboard.svg';
import orders from '../Images/orders.png';
import { useNavigate } from 'react-router-dom';

function SideBar2({ currentlyActiveTab, setCurrentlyActiveTab, selectedGoal }) {

    let navigate = useNavigate();

  


    const handleViewPredictPageButtonClick = () => {
        setCurrentlyActiveTab('predict_page')
        navigate('/Predict')
        // navigate('/Predict', { state: {from: location.state.from,modelName: location.state.modelName}})
    }

    const handleViewTrainedModelsPageButtonClick = () => {
        setCurrentlyActiveTab('home')
        navigate('/trainedmodels')
        // navigate('/Predict', { state: {from: location.state.from,modelName: location.state.modelName}})
    }

    const handleModelInfoPageButtonClick = () => {
        setCurrentlyActiveTab('Models_info')
        navigate('/savedresult')
     
    }

    //navigating only when a goal is selected
    // const handleNavigation = (route) => {
    //     console.log(selectedGoal)
    //     console.log(route)
    //     if(selectedGoal.length){
    //         navigate(route)
    //     }
    // }

    return (
        <>
              <div className='sidebar'>
                <img className="shadow" src={shadow} alt='shadow' />
                <div className='listItems'>
                    <ul>
                        <li className={`homeIcon firstTab ${currentlyActiveTab === 'home' && "activeTab"}`}>
                            <button onClick={handleViewTrainedModelsPageButtonClick} >
                                <img className="home" src={home} alt='home' />
                            </button>
                        </li>
                        
                        <li className='seprator'></li>
                        <li className={`secondIcon2 fourthTab ${currentlyActiveTab === 'Models_info' && "activeTab"} `}>
                            <button onClick={handleModelInfoPageButtonClick}>
                                <img className="orders2" src={orders} alt='' />
                            </button>                                 
                        </li>
                        <li className='seprator'></li>
                        <li className={`secondIcon2 secondTab ${currentlyActiveTab === 'predict_page' && "activeTab"}`}>
                            <button onClick={handleViewPredictPageButtonClick} >
                                <img className="dashboard2" src={dashboard} alt='' />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default SideBar2;