import { getSummaryApiGoal7 } from "../../../utils/api";
import {SUMMARY_FAIL_GOAL7, SUMMARY_REQUEST_GOAL7, SUMMARY_SUCCESS_GOAL7 } from "../../constants";

// summary action for goal 7
export const getSummaryGoal7 = () => async(dispatch) => {
    dispatch({
        type: SUMMARY_REQUEST_GOAL7
    })

    try{
      const {data} = await getSummaryApiGoal7();
      dispatch({
          type: SUMMARY_SUCCESS_GOAL7,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SUMMARY_FAIL_GOAL7,
            payload: error.message
        })
    }
}