import './SlideBar.css';
import shadow from '../Images/shadow.png';
import home from '../Images/home.png';
import dashboard from '../Images/dashboard.svg';
import gauge from '../Images/gauge.png';
import orders from '../Images/orders.png';
import settings from '../Images/settings.svg'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function SideBar({ currentlyActiveTab, setCurrentlyActiveTab, selectedGoal }) {

    const {hasResult} = useSelector((state) => state.gotModalResultReducer)
    const {hasResultGoal2} = useSelector((state) => state.gotModalResultReducerGoal2)
    const {hasResultGoal3} = useSelector((state) => state.gotModalResultReducerGoal3)
    const {hasResultGoal4} = useSelector((state) => state.gotModalResultReducerGoal4)
    const {hasResultGoal5} = useSelector((state) => state.gotModalResultReducerGoal4)
    const {hasResultGoal6} = useSelector((state) => state.gotModalResultReducerGoal4)
    const {hasResultGoal7} = useSelector((state) => state.gotModalResultReducerGoal4)
    let navigate = useNavigate();

    //navigating to result only when result is obtained already
    const handleResultNavigation = (route,tab) => {
        if(selectedGoal === 'goal1'){
            if(hasResult && hasResult.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
        else if(selectedGoal === 'goal2'){
            if(hasResultGoal2 && hasResultGoal2.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
        else if(selectedGoal === 'goal3'){
            if(hasResultGoal3 && hasResultGoal3.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
        else if(selectedGoal === 'goal4'){
            if(hasResultGoal4 && hasResultGoal4.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
        else if(selectedGoal === 'goal5'){
            if(hasResultGoal5 && hasResultGoal5.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
        else if(selectedGoal === 'goal6'){
            if(hasResultGoal6 && hasResultGoal6.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
        else if(selectedGoal === 'goal7'){
            if(hasResultGoal7 && hasResultGoal7.accuracy){
                navigate(route)
                setCurrentlyActiveTab(tab)
            }
        }
    }

    //navigating only when a goal is selected
    const handleNavigation = (route) => {
     
        if(selectedGoal.length){
            navigate(route)
        }
    }

    return (
        <>
            <div className='sidebar'>
                <img className="shadow" src={shadow} alt='shadow' />
                <div className='listItems'>
                    <ul>
                        <li className={`homeIcon firstTab ${currentlyActiveTab === 'home' && "activeTab"}`}>
                            <button onClick={() => handleNavigation('/goalHome')} >
                                <img className="home" src={home} alt='home' />
                            </button>
                        </li>
                        <li className='seprator'></li>
                        <li className={`secondIcon thirdTab  ${currentlyActiveTab === 'Comparision' && "activeTab"} `}>
                            <button onClick={() => handleResultNavigation(`/${selectedGoal}/result/Comparision`,'Comparision')}>
                                <img className="gauge" src={gauge} alt='' />
                            </button>    
                        </li>
                        <li className='seprator'></li>
                        <li className={`secondIcon fourthTab ${currentlyActiveTab === 'Perdictive_Models' && "activeTab"} `}>
                            <button onClick={() => handleResultNavigation(`/${selectedGoal}/result/Perdictive_Models`,'Perdictive_Models')}>
                                <img className="orders" src={orders} alt='' />
                            </button>                                 
                        </li>
                        <li className='seprator'></li>
                        <li className={`secondIcon lastTab ${currentlyActiveTab === 'Model_Result' && "activeTab"}`}>
                            <button onClick={() => handleResultNavigation(`/${selectedGoal}/result/Model_Result`,'Model_Result')}>
                                <img className="settings" src={settings} alt='' />
                            </button>
                        </li>
                        <li className='seprator'></li>
                        <li className={`secondIcon secondTab ${currentlyActiveTab === 'correlation' && "activeTab"}`}>
                            <button onClick={() => handleNavigation(`/${selectedGoal}/correlation`)} >
                                <img className="dashboard" src={dashboard} alt='' />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default SideBar;