import { GET_CHART_RESULT_FAIL, GET_CHART_RESULT_REQUEST, GET_CHART_RESULT_SUCCESS, RESET_DATA } from "../../constants"

// result reducer for goal 1
export const gotModalResultReducer = (state = {hasResult: []}, action) => {
    switch(action.type){
        case GET_CHART_RESULT_REQUEST:
            return {loading: true}
        case GET_CHART_RESULT_SUCCESS:
            return{loading: false, hasResult: action.payload }
        case GET_CHART_RESULT_FAIL:
            return {loading : false, error: action.payload}
        case RESET_DATA:
            return {loading : false, hasResult: []}
        default:
            return state
    }
}
