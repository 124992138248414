import { SUMMARY_FAIL, SUMMARY_REQUEST, SUMMARY_SUCCESS } from "../../constants";

// summary reducer for goal 1
export const summaryReducer = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST:
            return {loading: true}
        case SUMMARY_SUCCESS:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}