import { getAllVariableApiGoal3 } from "../../../utils/api";
import { ALL_VARIABLES_FAIL_GOAL3, ALL_VARIABLES_REQUEST_GOAL3, ALL_VARIABLES_SUCCESS_GOAL3 } from "../../constants";

// all variable action for goal 3
export const getAllVariableGoal3 = () => async(dispatch) => {
    dispatch({
        type: ALL_VARIABLES_REQUEST_GOAL3
    })

    try{
      const {data} = await getAllVariableApiGoal3();
      dispatch({
          type: ALL_VARIABLES_SUCCESS_GOAL3,
          payload: data
      });
    }catch(error){
        dispatch({
            type: ALL_VARIABLES_FAIL_GOAL3,
            payload: error.message
        })
    }
}