/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as XLSX from "xlsx";
import './SavedResultScreen.css'
import resultBase from '../../Images/resultBase.svg';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getPredictHeaders } from '../../../utils/getSavedResultHeaders';
import Table from '../HomeScreen/Table/Table';
import MasterPage from '../../MasterPage';
import { useNavigate, useLocation } from 'react-router-dom';
import { AddBuiltInProjectPredictionsApi, checkBuiltInprojecpredictiontApi, checkBuiltInprojectApi, saveBuiltInProjectAPI, saveBuiltInProjectpredictionAPI, sendPredictDataGoal1, sendPredictDataGoal2, sendPredictDataGoal3, sendPredictDataGoal4, sendPredictDataGoal5, sendPredictDataGoal6, sendPredictDataGoal7 } from '../../../utils/api'
import { useSelector } from 'react-redux';
import OverViewCard from '../OverviewCard/OverviewCard';
import { getOverViewCardsDetails } from '../../../utils/getOverViewCardsDetails';
import PredictionResult from './PredictionResult';

function Predict({result,setResult,goalResult,tableData,isLoading,row,setDataToSend,dataToSend, builtInProjectState, setBuiltInProjectState, setCurrentlyActiveTab, currentlyActiveTab, selectedGoal, setSelectedGoal }) {
  
    const [header, setHeader] = useState();

  
    const [detailsProps, setDetailsProps] = useState({})
    const [errorMsg, setErrorMsg] = useState('');
  

    const User = useSelector(state => state.User);
    const { UserInfo } = User;

    let location = useLocation();

    let navigate = useNavigate();

    var response;

   

    const SelectedTrainedModelReducer = useSelector(state => state.SelectedTrainedModelReducer);
    const { selectedTrainedModel } = SelectedTrainedModelReducer;

    const override = css`position: absolute; transform: translate(-20%, 300%);`;


    let dataForReport = [];
    let data = [];

    const goalOptions = [
        { name: 'grade', type: 'select', options: ['C', 'D', 'B', 'A', 'E', 'F', 'G'] },
        {
            name: 'sub_grade', type: 'select', options: ['C1', 'D2', 'D1', 'C4', 'C3', 'B3', 'A4', 'B5', 'C5', 'D4', 'C2', 'E1', 'E4', 'B4',
                'D3', 'A1', 'E5', 'B2', 'B1', 'A5', 'A3', 'D5', 'E3', 'A2', 'E2', 'F4', 'G1', 'G2',
                'F1', 'F5', 'F2', 'F3', 'G4', 'G5', 'G3']
        },
        { name: 'home_ownership', type: 'select', options: ['RENT', 'MORTGAGE', 'OWN', 'ANY', 'NONE', 'OTHER'] },
        { name: 'verification_status', type: 'select', options: ['Not Verified', 'Source Verified', 'Verified'] },
        {
            name: 'loan_status', type: 'select', options: ['Current', 'Fully Paid', 'In Grace Period', 'Late (31-120 days)',
                'Late (16-30 days)', 'Charged Off', 'Default', 'Issued']
        },
        { name: 'pymnt_plan', type: 'select', options: ['n', 'y'] },
        {
            name: 'purpose', type: 'select', options: ['debt_consolidation', 'credit_card', 'house', 'car', 'other', 'vacation',
                'home_improvement', 'small_business', 'major_purchase', 'medical',
                'renewable_energy', 'moving', 'wedding', 'educational']
        },
        { name: 'initial_list_status', type: 'select', options: ['w', 'f'] },
        { name: 'application_type', type: 'select', options: ['Individual', 'Joint App', 'INDIVIDUAL', 'JOINT'] },
        { name: 'issue_d', type: 'date' },
        { name: 'last_pymnt_d', type: 'date' },
        { name: 'last_credit_pull_d', type: 'date' },
        { name: 'earliest_cr_line', type: 'date' }
    ]



    const handleButtonClick = async () => {
        const tableContent = goalResult[0]['independentVariables'].length
        console.log(goalResult[0]['independentVariables'])
        console.log(dataToSend)
        if (tableContent !== Object.keys(dataToSend).length) {
            setErrorMsg('Please fill all the entries to predict');
            return;
        }



        if (selectedTrainedModel.from === 'goal1') {
            response = await sendPredictDataGoal1({ modelName: selectedTrainedModel.modelName, data: dataToSend })
            setResult(response.data.result)
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })


            }
        }
        else if (selectedTrainedModel.from === 'goal2') {

            response = await sendPredictDataGoal2({ modelName: selectedTrainedModel.modelName, data: dataToSend })
            setResult(response.data.result)
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })


            }
        }
        else if (selectedTrainedModel.from === 'goal3') {

            response = await sendPredictDataGoal3({ modelName: selectedTrainedModel.modelName, data: dataToSend })

            setResult(response.data.result)
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })


            }
        }
        else if (selectedTrainedModel.from === 'goal4') {
            response = await sendPredictDataGoal4({ modelName: selectedTrainedModel.modelName, data: dataToSend })
            setResult(response.data.result)
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })


            }
        }
        else if (selectedTrainedModel.from === 'goal5') {
            response = await sendPredictDataGoal5({ modelName: selectedTrainedModel.modelName, data: dataToSend })
            setResult(response.data.result)
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })


            }
        }
        else if (selectedTrainedModel.from === 'goal6') {
            response = await sendPredictDataGoal6({ modelName: selectedTrainedModel.modelName, data: dataToSend })
            setResult(response.data.result)
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })


            }
        }
        else {
            sendPredictDataGoal7({ modelName: selectedTrainedModel.modelName, data: dataToSend })
            if (response.status === 200) {
                setBuiltInProjectState((current) => {
                    return ({
                        ...current,
                        predictVarList: dataToSend,
                        result: response.data.result,
                        Username: UserInfo.username,
                        Useremail: UserInfo.email
                    })
                })



            }
        }

        const ProjectDetails = {
            Project_Name: builtInProjectState.ProjectName,
            Useremail: UserInfo.email,
            UserName: UserInfo.username,
            goal: builtInProjectState.goal,
            modelName: builtInProjectState.modelName,
            depVar: detailsProps.depVariable,
        }

        const ProjectPredictionDetails = {
            Project_Name: builtInProjectState.ProjectName,
            Useremail: UserInfo.email,
            UserName: UserInfo.username,
            goal: builtInProjectState.goal,
            modelName: builtInProjectState.modelName,
            depVar: detailsProps.depVariable,
            Prediction_info: [{
                "uid": Math.floor(Math.random() * 100).toString(),
                Project_Name: builtInProjectState.ProjectName,
                Useremail: UserInfo.email,
                UserName: UserInfo.username,
                goal: builtInProjectState.goal,
                modelName: builtInProjectState.modelName,
                predictVarList: { ...dataToSend },
                depVar: detailsProps.depVariable,
                result: response.data.result,
                "isFav": false
            }]
        }

        saveProject(ProjectDetails, ProjectPredictionDetails);
    }

    const saveProject = async (projectData, ProjectPredictionDetails) => {

        try {
            const checkifProjectCreated = await checkBuiltInprojectApi(projectData)
            if (checkifProjectCreated.data.length === 0) {
                const res = await saveBuiltInProjectAPI(projectData);
                const checkifPredictionPresent = await checkBuiltInprojecpredictiontApi(projectData)
                if (checkifPredictionPresent.data.length === 0) {
                    // create prediction
                    const res = await saveBuiltInProjectpredictionAPI(ProjectPredictionDetails);
                } else {
                    // update prediction
                    const res = await AddBuiltInProjectPredictionsApi(ProjectPredictionDetails);
                }
          
            } else {
                //update prediction
                const res = await AddBuiltInProjectPredictionsApi(ProjectPredictionDetails);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleInputChange = (e, indVar, type) => {
        // setDataToSend(obj => {


        //     if (type === 'date') {

        //         const date = new Date(e.target.value)
        //         obj[indVar] = date.toLocaleString('en-us', { month: 'short' }) + '-' + date.getFullYear();
        //     }
        //     else {
        //         obj[indVar] = e.target.value
        //     }
        //     return obj
        // })



        setDataToSend((current) => {
            if (type === 'date') {
                const date = new Date(e.target.value)
                return ({
                    ...current, [indVar]: date.toLocaleString('en-us', { month: 'short' }) + '-' + date.getFullYear()
                })
            } else {
                console.log("Checking",indVar,e.target.value);
                return ({

                    ...current, 
                    [indVar]: e.target.value
                })
            }
        })

    }


    const handleClickDownloadButton = () => {

        const keyData = Object.values(dataToSend);


        Object.keys(dataToSend).forEach((item, i) => {
            dataForReport.push({
                "Goal": selectedTrainedModel.from,
                "Model Name": selectedTrainedModel.modelName,
                "Independent Variables": selectedTrainedModel.independentVariables[i],
                "Independent Variables Values": keyData[i],
                "Dependent Variables": tableData[i]['Dep Var'],
                "Driving Force": tableData[i]['Driving Force'],
                "Weightage": tableData[i]['Weightage'],
                "Result": result
            })
        })

        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(dataForReport);

        XLSX.utils.book_append_sheet(wb, ws, "PredictionReport");
        XLSX.writeFile(wb, "PredictionReport.xlsx")


    }

  

   

    useEffect(() => {
        // if(!location.state) return

        const columns = getPredictHeaders(dataToSend,handleInputChange, selectedTrainedModel.from);
        setHeader(columns)
        // if (selectedTrainedModel.from === 'goal3') {
        //     setDataToSend({
        //         grade: 'C',
        //         sub_grade: 'C1',
        //         home_ownership: 'RENT',
        //         verification_status: 'Not Verified',
        //         loan_status: 'Current',
        //         pymnt_plan: 'n',
        //         purpose: 'debt_consolidation',
        //         initial_list_status: 'w',
        //         application_type: 'Individual'
        //     })
        // }
        // else if (selectedTrainedModel.from === 'goal4') {
        //     setDataToSend({
        //         grade: 'C',
        //         home_ownership: 'RENT',
        //         verification_status: 'Not Verified',
        //         pymnt_plan: 'n',
        //         purpose: 'debt_consolidation',
        //         initial_list_status: 'w',
        //         application_type: 'Individual'
        //     })
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToSend])

    useEffect(() => {
        if (Object.keys(dataToSend).length !== 0) {

            let tableKeys = Object.keys(dataToSend)

            goalOptions.forEach((item, i) => {
                if (tableKeys.includes(item.name)) {
                    const el = tableKeys.indexOf(item.name)
                    tableKeys.splice(el, 1)
                }
            })



            for (let i = 0; i < tableKeys.length; i++) {

                if (Object.keys(dataToSend).length !== 0) {
                    if (isNaN(dataToSend[tableKeys[i]])) {
                        setErrorMsg('Please enter only numeric value in the inputs');

                        break;
                    } else {
                        setErrorMsg('');

                    }

                }

            }



        } else {
            setErrorMsg('');
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToSend])


    useEffect(() => {
        getOverViewCardsDetails(selectedTrainedModel, setDetailsProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} needSidebar={false} needSidebar2={true} />
            <div className='screen nosidebar-screen'>
                <OverViewCard {...detailsProps} />
                {/* <div className='tableHeader savedresult-tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <label className='table-title'>Predict</label>
                    <button className='view-variables-button' onClick={handleButtonClick}>Predict</button>
                </div>
                <div className="Container boundary">
                <div className='table-view__container predict-table'>
                    {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                        <>
                            {(header && row) && <Table columns={header} data={row} />}
                        </>
                    }
                </div>

            </div> */}

                <div className='predict-second-row-wrapper'>
                    <div className='prediction-result-block'>
                        <div className='tableHeader savedresult-tableHeader' style={{
                            background: `url(${resultBase})`,
                        }}>
                            <label className='prediction-result-block-title'>Prediction Result</label>

                        </div>
                        <div className='prediction-result-container-block'>

                            {result !== '' ? <PredictionResult predictedResult={result} handleClickDownloadButton={handleClickDownloadButton} /> : <h4>Please fill in the input values and click on Get Result button to make a prediction</h4>}


                        </div>

                    </div>
                    <div className='predict-result-section'>
                        <div className='tableHeader savedresult-tableHeader' style={{
                            background: `url(${resultBase})`,
                        }}>
                            <label className='table-title'>Predict</label>
                            <button className='view-variables-button' onClick={handleButtonClick}>Get Result</button>
                        </div>
                        <div className="Container boundary">
                            <div className='tableContainer predict-table'>
                                {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                                    <>
                                        {(header && row) && <div className='table-view__container'><Table columns={header} data={row} /></div>}
                                    </>
                                }
                            </div>

                        </div>
                        {errorMsg.length !== 0 && <p className='error'>{errorMsg}</p>}

                    </div>
                </div>
            </div>


        </>
    );
}

export default Predict;