import React, { useEffect, useState } from 'react';
import './UploadFile.css';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { uploadFileApi } from '../../../utils/api';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';



function UploadFile({setisNextButtonDisabled,setNewProjectState, newProjectState}) {
    const [isLoading, setIsLoading] = useState(false)
    const [successMsg, setSuccessMsg] = useState('');
    const User = useSelector(state => state.User);
    const { UserInfo } = User;
    const {
        register,
        handleSubmit,
      } = useForm();

    const onSubmit = async (data) =>{
        if(data.file.length === 0){
            setSuccessMsg('Please Select A file ');
            return;
        }

        const filename = data.file[0].name.split('.');
        if(filename[filename.length - 1] !== 'csv'){
            setSuccessMsg('Please upload a CSV format file.');
            return;
        }

        const fileSize = data.file[0].size;
        const sizeInMB = fileSize/1000000
        if(sizeInMB > 1124){
            setSuccessMsg('Uploaded file size exceeds the limit of 1GB');
            return;
        }
        let formData = new FormData();
        setNewProjectState((current) => {
            return ({
                ...current,
                fileDetail: [data.file[0]]
            })
        })
        formData.append('ProjectName', newProjectState.ProjectName);
        formData.append('Useremail', UserInfo.email);
        formData.append('Username', UserInfo.username);
        formData.append('fileDetail',data.file[0]);
        setIsLoading(true)
        await uploadFileApi(formData)
          .then((res) => {
              if(res.data.Variables){
                setIsLoading(false)
                setSuccessMsg(res.data.msg)
                setNewProjectState((current) => {
                    return ({
                        ...current,
                        AllVars: res.data.Variables,
                        NumberOfColumns: res.data.NumberOfColumns,
                        NumberOfRows: res.data.NumberOfRows,
                        BinaryClassification_Vars: res.data.BinaryClassification_Vars,
                        Regression_Vars: res.data.Regression_Vars,
                        DataSet:filename[0]
                    })
                    })
              }

              if(res.data.error){
                setSuccessMsg(res.data.error);
                setIsLoading(false);
              }
              
            })
            .catch((err)=> {
                console.log(err);
            })

            
           
        
    }

    const override = css`position: absolute; transform: translate(-50%, 30%);`;

    useEffect(()=> {
        if(newProjectState.AllVars.length === 0){
            setisNextButtonDisabled(true)
        }else if(newProjectState.AllVars.length !== 0) 
        {  
            setisNextButtonDisabled(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState.AllVars])


    return (
        <>
        <form className='upload-data-form' onSubmit={handleSubmit(onSubmit)}>
            <div className='upload-field'>
                <p><input {...register('file')} accept=".csv, text/csv" type='file' id="raised-button-file" className='upload-input' name='file' /></p>
            </div>
            <div className='upload-button'>
            <Button variant="contained" type="submit">Upload</Button>
            </div>
        </form>
        <div className='upload-info'>
            <div className='loaderContainer'>
                {successMsg.length === 0 ? <BarLoader size={40} color='blue' loading={isLoading} width="42%" css={override}  /> : <p>{successMsg}</p> }
            </div>

        </div>
        </>
    );
}

export default UploadFile;