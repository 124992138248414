import { useEffect, useState } from 'react';
import Chart from './Chart';
import './ChartScreen.css';
import chartarrow from '../../../Images/Chartarrow.svg';
import { useSelector } from 'react-redux';


function ChartScreen({selectedGoal}) {
    const [result,setResult] = useState()

    const haveResult = useSelector(state => state.gotModalResultReducer);
    const { hasResult } = haveResult;

    const haveResultGoal2 = useSelector(state => state.gotModalResultReducerGoal2);
    const { hasResultGoal2 } = haveResultGoal2;

    const haveResultGoal3 = useSelector(state => state.gotModalResultReducerGoal3);
    const { hasResultGoal3 } = haveResultGoal3;

    const haveResultGoal4 = useSelector(state => state.gotModalResultReducerGoal4);
    const { hasResultGoal4 } = haveResultGoal4;

    const haveResultGoal5 = useSelector(state => state.gotModalResultReducerGoal5);
    const { hasResultGoal5 } = haveResultGoal5;

    const haveResultGoal6 = useSelector(state => state.gotModalResultReducerGoal6);
    const { hasResultGoal6 } = haveResultGoal6;

    useEffect(() => {
        if(selectedGoal === 'goal1'){
            setResult(hasResult)
        } 
        else if(selectedGoal === 'goal2'){
            setResult(hasResultGoal2)
        }
        else if(selectedGoal === 'goal3'){
            setResult(hasResultGoal3)
        }
        else if(selectedGoal === 'goal4'){
            setResult(hasResultGoal4)
        }
        else if(selectedGoal === 'goal5'){
            setResult(hasResultGoal5)
        }
        else if(selectedGoal === 'goal6'){
            setResult(hasResultGoal6)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedGoal])
  
    return (
        <div className='chart-container'>
            {result  &&
            <div className="container">
                <div className="column-1 box">
                    <div className='chart-box'>
                        <span className='chart-heading2'>CURRENT MODEL</span>
                        <Chart chartID="chartdiv5" value={result.accuracy[0]} />
                    </div>
                    <div className='analytics-data-box'>
                        <span className='chart-heading1'>{`${result.algorithms[0]} ${result.accuracy[0]}%`}</span>
                        <div className='result-data'>
                            <h1 className='Comparision'>{`+ ${result.Model_comparision[0]}%`}</h1>
                            <img className="increment-arrow" src={chartarrow} alt='' />
                        </div>
                        <span className='chart-text-bottom'>Compared to all Models</span>
                    </div>
                </div>
                <div className="column-2 box1">
                    <div className="row">
                        <div className="column" >
                            <span className='chart-heading1'>{`${result.algorithms[1]}`}</span>
                            <Chart chartID="chartdiv1" value={result.accuracy[1]}  />
                            <span className='chart-heading1'>{`${result.accuracy[1]}%`}</span>
                        </div>

                        <div className="column">
                            <span className='chart-heading1'>{`${result.algorithms[2]}`}</span>
                            <Chart chartID="chartdiv2"  value={result.accuracy[2]} />
                            <span className='chart-heading1'>{`${result.accuracy[2]}%`}</span>
                        </div>

                        <div className="column">
                            <span className='chart-heading1'>{`${result.algorithms[3]}`}</span>
                            <Chart chartID="chartdiv3" value={result.accuracy[3]}  />
                            <span className='chart-heading1'>{`${result.accuracy[3]}%`}</span>
                        </div>

                        <div className="column">
                            <span className='chart-heading1'>{`${result.algorithms[4]}`}</span>
                            <Chart chartID="chartdiv4" value={result.accuracy[4]}  />
                            <span className='chart-heading1'>{`${result.accuracy[4]}%`}</span>
                        </div>
                    </div>
                </div>
            </div>
}
        </div>
    );
}

export default ChartScreen;