import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { GetProjectApi, DeleteProjectApi } from '../../../utils/api';
import capitalize from '../../../utils/capitalize';
import MasterPage from '../../MasterPage';
import { ReactComponent as DeleteButton } from '../../Images/delete.svg';
import './MyProjectScreen.css'
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
function MyProjectScreen({ currentlyActiveTab, setCurrentlyActiveTab, selectedGoal, setSelectedGoal }) {

    let navigate = useNavigate();
    let newData = [];
    const [projectsList, setProjectsLis] = useState([]);
    const User = useSelector(state => state.User);
    const { UserInfo } = User;

    const PER_PAGE = 12;

    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        getAllProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getAllProjects() {
        try {
            const res = await GetProjectApi(UserInfo.username);
            setProjectsLis(res.data)
          

        } catch (e) {
            console.log(e);
        }

    }

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    const offset = currentPage * PER_PAGE;
    let currentPageData = projectsList.slice(offset, offset + PER_PAGE)

    const pageCount = Math.ceil(projectsList.length / PER_PAGE)


    function handleClickProjectCard(item) {
        navigate('/myProjectsPrediction',{state :{username: item['Username'], projectname: item['ProjectName']}})
    }
   function handleClickCreateProjectCard(){
       navigate(`/createNewProject`)
   }


    async function deleteProject(ProjectName, Username) {
      
        let data = {
            'Projectname': ProjectName,
            'Username': Username
        }
        try {
            const res = await DeleteProjectApi(data);
            newData = projectsList.filter((item)=> item.ProjectName !== data.Projectname)
            setProjectsLis(newData)
            
            
        } catch (e) {
            console.log(e);
        }

    }
  

    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} needSidebar={false} needHomePageSidebar={true} />
          
            <div className='myProjectContainer'>
            <h1 id='myProjectHeader'>My Projects</h1>
                <div className='myProjectSection'>
                   
              


                    {currentPageData.length === 0 ?
                        <h1>All the projects created will be displayed here</h1>
                        
                        :
                        <>
                            <div className='project-Cards-group'>
                            <div className='create-project-card'> 
                             <div onClick={() => handleClickCreateProjectCard()}>
                            
                                     <div id='add'><AddBoxIcon fontSize='inherit' id='add-icon' style={{ color: '#8a9191' }} /></div>
                                     <h2>create new Project</h2>
                                      </div>                                  
                                  </div>




                                {currentPageData.reverse().map((item) => (
                                    <div className='project-card' key={item.ProjectName}>  <div onClick={() => handleClickProjectCard(item)}>
                                        <h1>{capitalize(item.ProjectName)} </h1>
                                        <h4>{capitalize(item.ProjectType)}</h4>
                                        <h4>{`Dependent Variable: ${item.Dependent_Vars.replaceAll('_', ' ')}`}</h4>
                                        <h4>{`DataSet: ${item.DataSet}`}</h4>


                                    </div>
                                        <Button style={{ color: '#8a9191' }} onClick={() => {deleteProject(item.ProjectName, item.Username)}}>
                                            <DeleteIcon />
                                        </Button>
                                    </div>
                                ))
                                }
                            </div>
                            <ReactPaginate
                                previousLabel={"← Previous"}
                                nextLabel={"Next→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                        </>
                    }

                </div>
            </div>
        </>
    );
}

export default MyProjectScreen;