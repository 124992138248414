import { CORRELATION_ALL_VARIABLES_FAIL_GOAL5, CORRELATION_ALL_VARIABLES_REQUEST_GOAL5, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL5 } from "../../constants"

// correlation reducer for goal 5
export const correlation_allVariableReducerGoal5 = (state = {CorrelationallVariableListGoal5: []}, action) => {
    switch(action.type){
        case CORRELATION_ALL_VARIABLES_REQUEST_GOAL5:
            return {loading: true}
        case CORRELATION_ALL_VARIABLES_SUCCESS_GOAL5:
            return{loading: false, CorrelationallVariableListGoal5: action.payload }
        case CORRELATION_ALL_VARIABLES_FAIL_GOAL5:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}