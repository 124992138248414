import React from 'react';
import Header from './SubComponent/Header';
import HomePageSideBar from './SubComponent/HomePageSideBar';
import SideBar from './SubComponent/SideBar';
import SideBar2 from './SubComponent/SideBarXd';

// contains header and sidebar
function MasterPage({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal,savedVariables,isResultLoading,needSidebar= true, needSidebar2= false,needHomePageSidebar= false}) {
    return (
        <>
        <div className='main'>
        <Header setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} selectedGoal={selectedGoal} isResultLoading={isResultLoading} />
        {needSidebar && <SideBar setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} /> }
        {needSidebar2 && <SideBar2 setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} />}
        {needHomePageSidebar && <HomePageSideBar setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} />}
        </div>
        </>
    );
}

export default MasterPage;