import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import MasterPage from '../../MasterPage';
import StepperBox from './Stepper';
import Button from '@mui/material/Button';
import './NewProjectMainScreen.css'
import ProjectDetailsForm from './ProjectDetailsForm';
import UploadFile from './UploadFile';
import SelectVariableScreen from './SelectVariableNewProject/SelectVariableScreen';
import { AddProjectPredictionsApi, GetProjectPredictionsApi, newProjectGetResultApi, newProjectSummaryApi, savePredictionApi } from '../../../utils/api';
import SummaryScreen from './SummaryScreenNewProject/SummaryScreen';
import PredictionScreen from './PredictNewProject/PredictionScreen';
import { useSelector } from 'react-redux';




function NewProjectMainScreen({ totalCombinations, SetTotalCombinations, hyperdataaftertest, sethyperdataaftertest, hyperdataaftervalidation, sethyperdataaftervalidation, hyperdatabeforetest, sethyperdatabeforetest, sethyperdatabeforevalidation, hyperdatabeforevalidation, flag, setflag, activeTab, setActiveTab, showToast, initalstate, newProjectState, setNewProjectState, currentlyActiveTab, setCurrentlyActiveTab, selectedGoal, setSelectedGoal }) {

    const location = useLocation();

    const User = useSelector(state => state.User);
    const { UserInfo } = User;


    const [isNextButtonDisabled, setisNextButtonDisabled] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [predictApiData, setPredictApiData] = useState({});
    const [previousResult, setPreviousResult] = useState({});


    let filteredArray = newProjectState.IndependentVar.filter(e => e !== newProjectState.DependentVar)
    newProjectState.IndependentVar = filteredArray;

    let formData = new FormData();

    formData.append('ProjectName', newProjectState.ProjectName.toLowerCase());
    formData.append('Useremail', UserInfo.email);
    formData.append('Username', UserInfo.username);
    formData.append('fileDetail', newProjectState.fileDetail[0]);
    formData.append('AllVars', newProjectState.AllVars);
    formData.append('DependentVar', newProjectState.DependentVar);

    formData.append('IndependentVar', newProjectState.IndependentVar);
    formData.append('NumberOfColumns', newProjectState.NumberOfColumns);
    formData.append('NumberOfRows', newProjectState.NumberOfRows);
    formData.append('ProjectType', newProjectState.projectType);
    formData.append('featureSelectionValue', newProjectState.featureSelectionValue);
    formData.append('multicollinearityValue', newProjectState.multicollinearityValue);
    formData.append('algoAccuracies', newProjectState.algoAccuracies);
    formData.append('algoSelected', newProjectState.algoSelected);
    formData.append('cat_var', newProjectState.cat_var);
    formData.append('predictVarList', newProjectState.predictVarList);
    formData.append('DataSet', newProjectState.DataSet);


    const handleNextButtonClick = () => {
        if (activeTab === 0 && newProjectState.ProjectName === '') {
            return;
        }



        if (activeTab === 2 && newProjectState.DependentVar.length !== 0 && newProjectState.IndependentVar.length !== 0 && newProjectState.projectType.length !== 0) {

            getNewProjectSummary(newProjectState);

        }

        // if(activeTab === 3 && newProjectState.algoAccuracies.length !== 0){

        // }



        setActiveTab((prevState) => prevState + 1)

    }

    const handlePredictButtonClick = () => {

        if (newProjectState.algoSelected.length === 0) {
            setErrorMsg('Please Select An Algorithm');
            return;
        } else {
            setErrorMsg('');
        }




        getResult();
    }

    const handleBackButtonClick = () => {
        if (activeTab === 0) return;
        setActiveTab((prevState) => prevState - 1)
        setisNextButtonDisabled(false)
    }

    useEffect(() => {
        if (activeTab === 0) {
            setNewProjectState((current) => {
                return ({
                    ...current,
                    AllVars: []
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    useEffect(() => {
        setNewProjectState(!location.state ? initalstate : location.state.projectState)
        setflag(!location.state?.isHypertunned ? 1 : 0);
        console.log("isHypertunned", location);
        if (location.state) {
            console.log("location.state", location.state);
            console.log(location.state.activeTab === 2);
            if (location.state.activeTab === 2) {
                if (location.state.prevResult) {
                    const key = location.state.projectState.DependentVar
                    const pvResult = {
                        [key]: location.state.prevResult
                    }
                    setPreviousResult(pvResult)
                    
                }
                setActiveTab(2)
            } else if (location.state.activeTab === 4) {
                setActiveTab(4);
            } else {
                setActiveTab(0)
            }
        } else {
            setActiveTab(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state])

    useEffect(() => {
        if (location.state) {
            if (location.state.result) {
                setPredictApiData(location.state.result)
            } else {
                setPredictApiData({})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (Object.keys(newProjectState.predictVarList).length !== 0) {
            let tableKeys = Object.keys(newProjectState.predictVarList)

            newProjectState.cat_var.forEach((item, i) => {
                if (tableKeys.includes(item.name)) {
                    const el = tableKeys.indexOf(item.name)
                    tableKeys.splice(el, 1)
                }
            })



            for (let i = 0; i < tableKeys.length; i++) {

                if (isNaN(newProjectState.predictVarList[tableKeys[i]])) {
                    setErrorMsg('Please enter only numeric value in the inputs');
                    setisNextButtonDisabled(true);
                    break;
                } else if (newProjectState.predictVarList[tableKeys[i]] === '') {
                    setErrorMsg('Please enter a value in the inputs');
                    setisNextButtonDisabled(true);
                    break;
                } else {
                    setErrorMsg('');
                    setisNextButtonDisabled(false)
                }

            }





        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState.predictVarList])



    async function getNewProjectSummary(newProjectState) {
        let summaryArr = []
        
        try {



            const res = await newProjectSummaryApi(formData);

    
            if (res.data.summary && (summaryArr.length !== newProjectState.DependentVar.length)) {
                console.log("res", res);

                const Algoritms = Object.keys(res.data.accuracies);
                const Accuracies = Object.values(res.data.accuracies)
                let AlogritmsUsed = []

                Algoritms.forEach((item, i) => {
                    const value = `${Algoritms[i]} -  ${parseFloat(Accuracies[i]).toFixed(2)}%`
                    AlogritmsUsed.push({ [item]: value })
                })




                const newPredictVarList = Object.keys(res.data.Avg_values)
                const prevVar = Object.keys(previousResult)

                if (prevVar.length !== 0) {

                    if (newPredictVarList.includes(prevVar[0])) {
                        const oldList = { ...res.data.Avg_values }
                        oldList[prevVar[0]] = previousResult[prevVar[0]]
                        res.data.Avg_values = { ...oldList }
                    }

                }else if(location.state && location.state.projectState){
    
                    res.data.Avg_values = location.state.projectState.predictVarList
                }

               

                setNewProjectState((current) => {
                    summaryArr.push({ ...res.data.summary })
                    return ({
                        ...current,
                        summary: [...summaryArr],
                        algoAccuracies: [...AlogritmsUsed],
                        cat_var: [...res.data.cat_var_uniques],
                        train_cat_dummies: [...res.data.train_cat_dummies],
                        train_cat_vars: [...res.data.train_cat_vars],
                        train_vars: [...res.data.train_vars],
                        dep_val: { ...res.data.dep_val },
                        predictVarList: { ...res.data.Avg_values },
                        deletedVar: { ...res.data.deleted_variables },
                        dependent: res.data.dependent
                    })
                })
            }


            if (res.data.error) {
                setErrorMsg(res.data.error)
            }



        }
        catch (e) {
            console.log(e);
        }
    }



    async function getProjectPredictionsData(checkPredictionParams,predictionData) {

        try {
            const res = await GetProjectPredictionsApi(checkPredictionParams);
            if(res.data.length === 0){
                savePredictionApi(predictionData)
            } else {
                // update code here
               await AddProjectPredictionsApi(predictionData)
            }
        } catch (error) {
            console.log(error);
        }
    }


    let checkPredictionParams = {
        username:UserInfo.username,
        projectname: newProjectState.ProjectName.toLowerCase()
    }








    async function getResult() {

        try {

            let fileName = newProjectState.fileDetail[0].name;
            let fileSize = newProjectState.fileDetail[0].size;

            const keyData = Object.keys(newProjectState.predictVarList);
            const Data = Object.values(newProjectState.predictVarList);

            let usr_name = UserInfo.username;
            let usr_email = UserInfo.email;
            const dataToSend = { ...newProjectState, Username: usr_name, Useremail: usr_email}

            const res = await newProjectGetResultApi(dataToSend);
            setPredictApiData(res)
            let predictionData = {
                "UserName": UserInfo.username,
                "Project_Name": newProjectState.ProjectName.toLowerCase(),
                "Prediction_info": [{
                    "uid": Math.floor(Math.random() * 100).toString(),
                    "UserName": UserInfo.username,
                    "Project_Name": newProjectState.ProjectName.toLowerCase(),
                    "Project_Type": newProjectState.projectType,
                    "Algorithm": newProjectState.algoSelected,
                    "Independent_Variables": [...keyData],
                    "Independent_Variables_Values": [...Data],
                    "Dependent_Variables": newProjectState.DependentVar,
                    "Result": res.data.PridicitonResult,
                    "fileName": fileName,
                    "fileSize": fileSize,
                    "projectData": newProjectState,
                    "resultOptions": res.data.resultOptions,
                    "initalistValues": newProjectState.predictVarList,
                    "isFav": false
                }]
            }
            setNewProjectState((current) => {

                return ({
                    ...current,
                    projectDetails: {
                        ...current.projectDetails,
                        "UserName": newProjectState.Username,
                        "Project_Name": newProjectState.ProjectName,
                        "Project_Type": newProjectState.projectType,
                        "Algorithm": newProjectState.algoSelected,
                        "Independent_Variables": [...keyData],
                        "Independent_Variables_Values": [...Data],
                        "Dependent_Variables": newProjectState.DependentVar,
                        "Result": res.data.PridicitonResult,
                        "fileName": fileName,
                        "fileSize": fileSize,

                    }
                })
            })

            getProjectPredictionsData(checkPredictionParams,predictionData)


        } catch (error) {
            console.log(error);
        }
    }




    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} needSidebar={false} needHomePageSidebar={true} />
            <div className="new-projectbox">
                <div className='new-project-container'>

                    <div className='steps-container'>
                        <StepperBox tab={activeTab} />
                    </div>
                    <div className='form-container'>
                        {activeTab === 0 && <ProjectDetailsForm setisNextButtonDisabled={setisNextButtonDisabled} setNewProjectState={setNewProjectState} newProjectState={newProjectState} />}
                        {activeTab === 1 && <UploadFile setisNextButtonDisabled={setisNextButtonDisabled} setNewProjectState={setNewProjectState} newProjectState={newProjectState} />}
                        {activeTab === 2 && <SelectVariableScreen setisNextButtonDisabled={setisNextButtonDisabled} newProjectState={newProjectState} setNewProjectState={setNewProjectState} />}
                        {activeTab === 3 && <SummaryScreen errorMsg={errorMsg} setisNextButtonDisabled={setisNextButtonDisabled} newProjectState={newProjectState} setNewProjectState={setNewProjectState} />}
                        {activeTab === 4 && <PredictionScreen totalCombinations={totalCombinations} SetTotalCombinations={SetTotalCombinations} hyperdataaftertest={hyperdataaftertest} sethyperdataaftertest={sethyperdataaftertest} hyperdataaftervalidation={hyperdataaftervalidation} sethyperdataaftervalidation={sethyperdataaftervalidation} hyperdatabeforetest={hyperdatabeforetest} sethyperdatabeforetest={sethyperdatabeforetest} sethyperdatabeforevalidation={sethyperdatabeforevalidation} hyperdatabeforevalidation={hyperdatabeforevalidation} flag={flag} setflag={setflag} showToast={showToast} setErrorMsg={setErrorMsg} errorMsg={errorMsg} predictApiData={predictApiData} setisNextButtonDisabled={setisNextButtonDisabled} newProjectState={newProjectState} setNewProjectState={setNewProjectState} />}
                        <div className={`form-button-container ${activeTab === 0 && "shiftbutton"} `}>
                            {activeTab !== 0 && <Button variant="contained" onClick={handleBackButtonClick} >Back</Button>}
                            {activeTab === 4 ? <Button variant="contained" disabled={isNextButtonDisabled} onClick={handlePredictButtonClick} >Predict</Button> : <Button variant="contained" disabled={isNextButtonDisabled} onClick={handleNextButtonClick}>Next</Button>}
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}

export default NewProjectMainScreen;