import { SELECT_TRAINED_MODEL } from "../../constants";

// selected trained model reducer
export const SelectedTrainedModelReducer = (state = {selectedTrainedModel: {}}, action) => {
    switch(action.type){
        case SELECT_TRAINED_MODEL:
            return {selectedTrainedModel: action.payload}
        default:
            return state
    }
}