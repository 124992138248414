/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './SavedResultScreen.css'
import resultBase from '../../Images/resultBase.svg';
import risk from '../../Images/risk.png';
import like from '../../Images/like.png';
import MasterPage from '../../MasterPage';
import { useNavigate, useLocation } from 'react-router-dom';

function PredictionResult({predictedResult,handleClickDownloadButton}) {

    const [result, setResult] = useState(0);
    const [timeTaken, setTimeTaken] = useState(Date.now());

    let location = useLocation();

    let navigate = useNavigate();

    const handleanotherPredictionClick = () => {
        navigate('/goals')
    }

    useEffect(() => {
        if(!predictedResult) return
       
        if(!isNaN(predictedResult))
        {
            setResult(parseInt(predictedResult))

            let start = 0;
            let duration = 1;
            // first three numbers from props
            const end = parseInt(predictedResult);
          
            // const end = predictedResult[0];
            // if zero, return
            if (start === end) return;

            // find duration per increment
            let totalMilSecDur = parseInt(duration);
            let incrementTime = (totalMilSecDur / end) * 500;
            let incValue = (predictedResult > 0) ? 1 : -1
            let timer = setInterval(() => {
                start += incValue;
        
                //update uisng state
                setResult(start);
        
                if (start === end) {
                clearInterval(timer);
                const diff = Date.now() - timeTaken;
                setTimeTaken(diff / 1000);
                }
            }, incrementTime);
        }
        else{
            setResult(predictedResult)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[predictedResult])

    return (
        <>
            
        
               
                <div className='predictionresult-body' style={{backgroundColor: '#2b2c34'}}>
                    
                    {!isNaN(predictedResult) && <div className="pie animate no-round" style={{'--p': Math.abs(result),'--c': (predictedResult > 0) ? '#3d3ec2' : '#e51a1a'}} >
                        <span>{result}</span>
                    
                    </div>}
                    {(isNaN(predictedResult) && (predictedResult === 'Low Liquidity Risk' || predictedResult === 'High Credit Risk' || predictedResult === 'Low Credit Risk' || predictedResult === 'High Liquidity Risk' || predictedResult === 'Low default risk' || predictedResult === 'High default risk')) && <div className="prediction-result-container" >
                        <div className='low-high-result'>
                        <img src={risk} alt="risk-prediction-icon" />
                            </div>
                        <p>{result}</p>
                    </div>}
                    {(isNaN(predictedResult) && (predictedResult === 'Good Credit Score' || predictedResult === 'Bad Credit Score')) && <div className="prediction-result-container" >
                        <div className={`low-high-result ${predictedResult === 'Bad Credit Score' && "flip_image"}`}>
                        <img src={like} alt="risk-prediction-icon" />
                            </div>
                        <p>{result}</p>
                    </div>}
                    {(isNaN(predictedResult) && (predictedResult === 'Not Approved' || predictedResult === 'Approved')) && <div className="prediction-result-container" >
                        <div className={`low-high-result ${predictedResult === 'Not Approved' && "flip_image"}`}>
                        <img src={like} alt="risk-prediction-icon" />
                            </div>
                        <p>{result}</p>
                    </div>}
                    <button className='continue-button' onClick={handleClickDownloadButton}>
                        <span>Download Report</span>
                        <svg fill="currentColor" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88"><path fillRule="evenodd" d="M27.25,0H95.63a27.31,27.31,0,0,1,27.25,27.25V95.63a27.31,27.31,0,0,1-27.25,27.25H27.25A27.31,27.31,0,0,1,0,95.63V27.25A27.31,27.31,0,0,1,27.25,0ZM71.59,50.47a3.38,3.38,0,1,1,4.74,4.83L63.48,68a3.4,3.4,0,0,1-4.75,0L46.07,55.48a3.38,3.38,0,1,1,4.74-4.83l7,6.91.07-24.33a3.39,3.39,0,1,1,6.77.05l-.07,24.15,7-7ZM34.48,81.55l.05-13.24a3.39,3.39,0,1,1,6.77.06l0,9.64q20.16,0,40.32,0l0-9.7a3.39,3.39,0,1,1,6.78.06l-.05,13.18h0A3.38,3.38,0,0,1,85,84.78c-15.72,0-31.39,0-47.11,0a3.38,3.38,0,0,1-3.35-3.23ZM95.63,7H27.25A20.35,20.35,0,0,0,7,27.25V95.63a20.35,20.35,0,0,0,20.29,20.29H95.63a20.35,20.35,0,0,0,20.29-20.29V27.25A20.35,20.35,0,0,0,95.63,7Z"/></svg>
                    </button>
                </div>
                
            


        </>
    );
}

export default PredictionResult;