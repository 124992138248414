import { CORRELATION_ALL_VARIABLES_FAIL_GOAL6, CORRELATION_ALL_VARIABLES_REQUEST_GOAL6, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL6 } from "../../constants"

// correlation reducer for goal 6
export const correlation_allVariableReducerGoal6 = (state = {CorrelationallVariableListGoal6: []}, action) => {
    switch(action.type){
        case CORRELATION_ALL_VARIABLES_REQUEST_GOAL6:
            return {loading: true}
        case CORRELATION_ALL_VARIABLES_SUCCESS_GOAL6:
            return{loading: false, CorrelationallVariableListGoal6: action.payload }
        case CORRELATION_ALL_VARIABLES_FAIL_GOAL6:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}