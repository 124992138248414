import { ALL_VARIABLES_FAIL, ALL_VARIABLES_REQUEST, ALL_VARIABLES_SUCCESS } from "../../constants";

// all variable reducer for goal 1 
export const allVariableReducer = (state = {allVariableList: []}, action) => {
    switch(action.type){
        case ALL_VARIABLES_REQUEST:
            return {loading: true}
        case ALL_VARIABLES_SUCCESS:
            return{loading: false, allVariableList: action.payload }
        case ALL_VARIABLES_FAIL:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}