import { getAllVariableApiGoal2 } from "../../../utils/api";
import { ALL_VARIABLES_FAIL_GOAL2, ALL_VARIABLES_REQUEST_GOAL2, ALL_VARIABLES_SUCCESS_GOAL2 } from "../../constants";

// all variable action for goal 2
export const getAllVariableGoal2 = () => async(dispatch) => {
    dispatch({
        type: ALL_VARIABLES_REQUEST_GOAL2
    })

    try{
      const {data} = await getAllVariableApiGoal2();
      dispatch({
          type: ALL_VARIABLES_SUCCESS_GOAL2,
          payload: data
      });
    }catch(error){
        dispatch({
            type: ALL_VARIABLES_FAIL_GOAL2,
            payload: error.message
        })
    }
}