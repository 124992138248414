import {createStore, compose , applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { allVariableReducer } from './Reducers/allVariable/allVariableReducer';
import { allVariableReducerGoal2 } from './Reducers/allVariable/allVariableReducerGoal2';
import { allVariableReducerGoal3 } from './Reducers/allVariable/allVariableReducerGoal3';
import { allVariableReducerGoal4 } from './Reducers/allVariable/allVariableReducerGoal4';
import { allVariableReducerGoal5 } from './Reducers/allVariable/allVariableReducerGoal5';
import { allVariableReducerGoal6 } from './Reducers/allVariable/allVariableReducerGoal6';
import { allVariableReducerGoal7 } from './Reducers/allVariable/allVariableReducerGoal7';
import { correlation_allVariableReducer } from './Reducers/correlation_all_var/correlation_allVarReducer';
import { correlation_allVariableReducerGoal2 } from './Reducers/correlation_all_var/correlation_allVarReducerGoal2';
import { correlation_allVariableReducerGoal3 } from './Reducers/correlation_all_var/correlation_allVarReducerGoal3';
import { correlation_allVariableReducerGoal4 } from './Reducers/correlation_all_var/correlation_allVarReducerGoal4';
import { correlation_allVariableReducerGoal5 } from './Reducers/correlation_all_var/correlation_allVarReducerGoal5';
import { correlation_allVariableReducerGoal6 } from './Reducers/correlation_all_var/correlation_allVarReducerGoal6';
import { correlation_allVariableReducerGoal7 } from './Reducers/correlation_all_var/correlation_allVarReducerGoal7';
import { gotModalResultReducer } from './Reducers/Result/resultReducer';
import { gotModalResultReducerGoal2 } from './Reducers/Result/resultReducerGoal2';
import { gotModalResultReducerGoal3 } from './Reducers/Result/resultReducerGoal3';
import { gotModalResultReducerGoal4 } from './Reducers/Result/resultReducerGoal4';
import { gotModalResultReducerGoal5 } from './Reducers/Result/resultReducerGoal5';
import { gotModalResultReducerGoal6 } from './Reducers/Result/resultReducerGoal6';
import { selectedVariableReducer } from './Reducers/selectedVariable/selectedVariableReducer';
import { selectedVariableReducerGoal2 } from './Reducers/selectedVariable/selectedVariableReducerGoal2';
import { selectedVariableReducerGoal3 } from './Reducers/selectedVariable/selectedVariableReducerGoal3';
import { selectedVariableReducerGoal4 } from './Reducers/selectedVariable/selectedVariableReducerGoal4';
import { selectedVariableReducerGoal5 } from './Reducers/selectedVariable/selectedVariableReducerGoal5';
import { selectedVariableReducerGoal6 } from './Reducers/selectedVariable/selectedVariableReducerGoal6';
import { selectedVariableReducerGoal7 } from './Reducers/selectedVariable/selectedVariableReducerGoal7';
import { summaryReducer } from './Reducers/summary/summaryReducer';
import { summaryReducerGoal2 } from './Reducers/summary/summaryReducerGoal2';
import { summaryReducerGoal3 } from './Reducers/summary/summaryReducerGoal3';
import { summaryReducerGoal4 } from './Reducers/summary/summaryReducerGoal4';
import { summaryReducerGoal5 } from './Reducers/summary/summaryReducerGoal5';
import { summaryReducerGoal6 } from './Reducers/summary/summaryReducerGoal6';
import { summaryReducerGoal7 } from './Reducers/summary/summaryReducerGoal7';
import {  goalResultReducer } from './Reducers/GoalResult/GoalResultReducer'
import { SelectedTrainedModelReducer } from './Reducers/SelectedTrainedModel/SelectedTrainedModelReducer'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userReducer } from './Reducers/UserLogin/UserLoginReducer';

const initialState = {}

//combininng all reducers
const reducer = combineReducers({
    allVariableReducer:allVariableReducer,
    allVariableReducerGoal2:allVariableReducerGoal2,
    allVariableReducerGoal3:allVariableReducerGoal3,
    allVariableReducerGoal4:allVariableReducerGoal4,
    allVariableReducerGoal5:allVariableReducerGoal5,
    allVariableReducerGoal6:allVariableReducerGoal6,
    allVariableReducerGoal7:allVariableReducerGoal7,
    summaryReducer:summaryReducer,
    summaryReducerGoal2:summaryReducerGoal2,
    summaryReducerGoal3:summaryReducerGoal3,
    summaryReducerGoal4:summaryReducerGoal4,
    summaryReducerGoal5:summaryReducerGoal5,
    summaryReducerGoal6:summaryReducerGoal6,
    summaryReducerGoal7:summaryReducerGoal7,
    selectedVariableReducer:selectedVariableReducer,
    selectedVariableReducerGoal2:selectedVariableReducerGoal2,
    selectedVariableReducerGoal3:selectedVariableReducerGoal3,
    selectedVariableReducerGoal4:selectedVariableReducerGoal4,
    selectedVariableReducerGoal5:selectedVariableReducerGoal5,
    selectedVariableReducerGoal6:selectedVariableReducerGoal6,
    selectedVariableReducerGoal7:selectedVariableReducerGoal7,
    correlation_allVariableReducer: correlation_allVariableReducer,
    correlation_allVariableReducerGoal2: correlation_allVariableReducerGoal2,
    correlation_allVariableReducerGoal3: correlation_allVariableReducerGoal3,
    correlation_allVariableReducerGoal4: correlation_allVariableReducerGoal4,
    correlation_allVariableReducerGoal5: correlation_allVariableReducerGoal5,
    correlation_allVariableReducerGoal6: correlation_allVariableReducerGoal6,
    correlation_allVariableReducerGoal7: correlation_allVariableReducerGoal7,
    gotModalResultReducer: gotModalResultReducer,
    gotModalResultReducerGoal2: gotModalResultReducerGoal2,
    gotModalResultReducerGoal3: gotModalResultReducerGoal3,
    gotModalResultReducerGoal4: gotModalResultReducerGoal4,
    gotModalResultReducerGoal5: gotModalResultReducerGoal5,
    gotModalResultReducerGoal6: gotModalResultReducerGoal6,
    goalResultReducer:  goalResultReducer,
    SelectedTrainedModelReducer: SelectedTrainedModelReducer,
    User: userReducer
})

const persistConfig = {
    key: 'root',
    storage,
}

//passing the combined reducers to persistReducer function to persist the redux store
const persistedReducer = persistReducer(persistConfig, reducer)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export let persistor = persistStore(store)

