import { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import ErrorBoundary from './Components/ErrorBoundary';
import ResultScreen from './Components/SubComponent/ResultScreen/ResultScreen';
import SavedResultScreen from './Components/SubComponent/SavedResultScreen/SavedResultScreen';
import ModelVariable from './Components/SubComponent/SavedResultScreen/ModelVariable';
import SummaryStats from './Components/SubComponent/SavedResultScreen/SummaryStats';
import CorrelationScreen from './Components/SubComponent/CorrelationScreen/CorrelationScreen';
import HomeScreen from './Components/SubComponent/HomeScreen/HomeScreen';
import GoalScreen from './Components/SubComponent/GoalScreen/GoalScreen';
import LandingPage from './Components/SubComponent/LandingPage/LandingPage';
import NextScreen from './Components/SubComponent/NextScreen/NextScreen';
import ExistingData from './Components/SubComponent/ExistingData/ExistingData';
import TrainedModels from './Components/SubComponent/TrainedModels/TrainedModels';
import Correlation from './Components/SubComponent/SavedResultScreen/Correlation';
import ModelResult from './Components/SubComponent/SavedResultScreen/ModelResult';
import Predict from './Components/SubComponent/SavedResultScreen/Predict';
import PredictionResult from './Components/SubComponent/SavedResultScreen/PredictionResult';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVariable } from './Redux/Reducers/allVariable/allVariableAction';
import { getAllVariableGoal2 } from './Redux/Reducers/allVariable/allVariableActionGoal2';
import { getAllVariableGoal3 } from './Redux/Reducers/allVariable/allVariableActionGoal3';
import { getAllVariableGoal4 } from './Redux/Reducers/allVariable/allVariableActionGoal4';
import { getAllVariableGoal5 } from './Redux/Reducers/allVariable/allVariableActionGoal5';
import { getAllVariableGoal6 } from './Redux/Reducers/allVariable/allVariableActionGoal6';
import { getAllVariableGoal7 } from './Redux/Reducers/allVariable/allVariableActionGoal7';
import { getSummary } from './Redux/Reducers/summary/summaryAction';
import { getSummaryGoal2 } from './Redux/Reducers/summary/summaryActionGoal2';
import { getSummaryGoal3 } from './Redux/Reducers/summary/summaryActionGoal3';
import { getSummaryGoal4 } from './Redux/Reducers/summary/summaryActionGoal4';
import { getSummaryGoal5 } from './Redux/Reducers/summary/summaryActionGoal5';
import { getSummaryGoal6 } from './Redux/Reducers/summary/summaryActionGoal6';
import { getSummaryGoal7 } from './Redux/Reducers/summary/summaryActionGoal7';
import { getselectedVariable } from './Redux/Reducers/selectedVariable/selectedVariableAction';
import { getselectedVariableGoal2 } from './Redux/Reducers/selectedVariable/selectedVariableActionGoal2';
import { getselectedVariableGoal3 } from './Redux/Reducers/selectedVariable/selectedVariableActionGoal3';
import { getselectedVariableGoal4 } from './Redux/Reducers/selectedVariable/selectedVariableActionGoal4';
import { getselectedVariableGoal5 } from './Redux/Reducers/selectedVariable/selectedVariableActionGoal5';
import { getselectedVariableGoal6 } from './Redux/Reducers/selectedVariable/selectedVariableActionGoal6';
import { getselectedVariableGoal7 } from './Redux/Reducers/selectedVariable/selectedVariableActionGoal7';
import { Correlation_getAllVariable } from './Redux/Reducers/correlation_all_var/correlation_allVarAction';
import { Correlation_getAllVariableGoal2 } from './Redux/Reducers/correlation_all_var/correlation_allVarActionGoal2';
import { Correlation_getAllVariableGoal3 } from './Redux/Reducers/correlation_all_var/correlation_allVarActionGoal3';
import { Correlation_getAllVariableGoal4 } from './Redux/Reducers/correlation_all_var/correlation_allVarActionGoal4';
import { Correlation_getAllVariableGoal5 } from './Redux/Reducers/correlation_all_var/correlation_allVarActionGoal5';
import { Correlation_getAllVariableGoal6 } from './Redux/Reducers/correlation_all_var/correlation_allVarActionGoal6';
import { Correlation_getAllVariableGoal7 } from './Redux/Reducers/correlation_all_var/correlation_allVarActionGoal7';
import LoginPage from './Components/SubComponent/LoginComponents/LoginPage';
import PrivateRoute from './Components/PrivateRoute';
import ViewProfilePage from './Components/SubComponent/ViewProfile/ViewProfilePage';
import NewProjectMainScreen from './Components/SubComponent/NewProjectScreen/NewProjectMainScreen';
import MyProjectScreen from './Components/SubComponent/MyProjectsModule/MyProjectScreen';
import ProjectPredictions from './Components/SubComponent/MyProjectsModule/ProjectPredictions/ProjectPredictions';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import PredictContainer from './Components/SubComponent/SavedResultScreen/PredictContainer';
import MyProjectMainSCreen from './Components/SubComponent/MyProjectsModule/MyProjectMainSCreen';
import MyBuiltInProjectScreen from './Components/SubComponent/MyProjectsModule/MyBuiltInProjectScreen';
import BuiltInProjectPredictions from './Components/SubComponent/MyProjectsModule/ProjectPredictions/BuiltInProjectPredictions';







function App() {

  const [currentlyActiveTab, setCurrentlyActiveTab] = useState("home");
  const [selectedGoal, setSelectedGoal] = useState('');
  const [savedVariables, setSavedVariables] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [flag, setflag] = useState(1);
  const [hyperdatabeforevalidation, sethyperdatabeforevalidation] = useState('');
  const [hyperdatabeforetest, sethyperdatabeforetest] = useState('');
  const [hyperdataaftervalidation, sethyperdataaftervalidation] = useState('');
  const [hyperdataaftertest, sethyperdataaftertest] = useState('')
  const [totalCombinations, SetTotalCombinations] = useState(0);

  const User = useSelector(state => state.User);
  const { UserInfo } = User;

  const inBuiltProjectInitialState = {

    Username: "",
    Useremail: "",
    ProjectName: '',
    goal:'',
    modelName:'',
    predictVarList: {},
    result:''

  }

  const initalstate = {

    Username: "",
    Useremail: "",
    ProjectName: '',
    fileDetail: {},
    AllVars: [],
    deletedVar: {},
    DependentVar: '',
    IndependentVar: [],
    NumberOfColumns: '',
    NumberOfRows: '',
    projectType: 'Binary Classification Problem',
    summary: [],
    featureSelectionValue: 'yes',
    multicollinearityValue: 'no',
    algoAccuracies: [],
    algoSelected: '',
    cat_var: [],
    predictVarList: {},
    train_cat_dummies: [],
    train_cat_vars: [],
    train_vars: [],
    dep_val: {},
    projectDetails: {
      "UserName": '',
      "Project_Name": '',
      "Project_Type": '',
      "Algorithm": '',
      "Independent_Variables": [],
      "Independent_Variables_Values": [],
      "Dependent_Variables": '',
      "Result": '',
      "fileName": '',
      "fileSize": '',
    },
    BinaryClassification_Vars: [],
    Regression_Vars: [],
    hyperTunedAlgo: '',
    DataSet: '',
  }

  const [newProjectState, setNewProjectState] = useState(initalstate);
  const [builtInProjectState, setBuiltInProjectState] = useState(inBuiltProjectInitialState)
  const dispatch = useDispatch();

  const CustomToastWithLink = () => {
    
    return(
    <div>
      <Link to = "/createNewProject"   state={{projectState:newProjectState,result: {},isHypertunned:true, activeTab:4}} style={{'color': '#ffffff', 'textDecoration': 'none'}}>Hypertunning Completed. Click here to predicit using HyperTunned Algorithm</Link>
    </div>)
  };

  // dispatching respective actions based on the goal selected
  useEffect(() => {
    if (selectedGoal.length !== 0)
      if (selectedGoal === "goal1") {
        dispatch(getAllVariable());
        dispatch(getSummary());
        dispatch(getselectedVariable());
        dispatch(Correlation_getAllVariable());
      }

      else if (selectedGoal === "goal2") {
        dispatch(getAllVariableGoal2())
        dispatch(getSummaryGoal2());
        dispatch(getselectedVariableGoal2());
        dispatch(Correlation_getAllVariableGoal2());
      }

      else if (selectedGoal === "goal3") {
        dispatch(getAllVariableGoal3())
        dispatch(getSummaryGoal3());
        dispatch(getselectedVariableGoal3());
        dispatch(Correlation_getAllVariableGoal3());
      }

      else if (selectedGoal === "goal4") {

        dispatch(getAllVariableGoal4())
        dispatch(getSummaryGoal4());
        dispatch(getselectedVariableGoal4());
        dispatch(Correlation_getAllVariableGoal4());
      }

      else if (selectedGoal === "goal5") {
        dispatch(getAllVariableGoal5())
        dispatch(getSummaryGoal5());
        dispatch(getselectedVariableGoal5());
        dispatch(Correlation_getAllVariableGoal5());
      }

      else if (selectedGoal === "goal6") {
        dispatch(getAllVariableGoal6())
        dispatch(getSummaryGoal6());
        dispatch(getselectedVariableGoal6());
        dispatch(Correlation_getAllVariableGoal6());
      }
      else if (selectedGoal === "goal7") {
        dispatch(getAllVariableGoal7())
        dispatch(getSummaryGoal7());
        dispatch(getselectedVariableGoal7());
        dispatch(Correlation_getAllVariableGoal7());
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal])
  
  useEffect(() => {
    if (UserInfo) {
      console.log(UserInfo.username);
      console.log(UserInfo.email);
      let usr_name = UserInfo.username;
      let usr_email = UserInfo.email;

      const dataToSend = { ...initalstate, Username: usr_name, Useremail: usr_email}
      console.log(dataToSend)

      setNewProjectState(dataToSend)
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserInfo])

  const showToast = () => {
    toast.info(CustomToastWithLink, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"
      });
  };

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <div className="App">
          <ToastContainer 
          />
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<LandingPage builtInProjectState={builtInProjectState} setBuiltInProjectState={setBuiltInProjectState}  setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/viewprofile" element={<ViewProfilePage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/nextscreen" element={<NextScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/existingdata" element={<ExistingData setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/trainedmodels" element={<TrainedModels builtInProjectState={builtInProjectState} setBuiltInProjectState={setBuiltInProjectState} setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/savedresult" element={<SavedResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/modelvariable" element={<ModelVariable setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/summarystatistics" element={<SummaryStats setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/correlation" element={<Correlation setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/modelresult" element={<ModelResult setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/predict" element={<PredictContainer builtInProjectState={builtInProjectState} setBuiltInProjectState={setBuiltInProjectState} setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/prediction-result" element={<PredictionResult setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goals" element={<GoalScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route exact path="/goalHome" element={<HomeScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} setSavedVariables={setSavedVariables} />} />
              <Route path="/goal1/result/:tab" element={<ResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goal2/result/:tab" element={<ResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goal3/result/:tab" element={<ResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goal4/result/:tab" element={<ResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goal5/result/:tab" element={<ResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goal6/result/:tab" element={<ResultScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} savedVariables={savedVariables} />} />
              <Route path="/goal1/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/goal2/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/goal3/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/goal4/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/goal5/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/goal6/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/goal7/correlation" element={<CorrelationScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path='/createNewProject' element={<NewProjectMainScreen totalCombinations={totalCombinations} SetTotalCombinations={SetTotalCombinations} hyperdataaftertest={hyperdataaftertest} sethyperdataaftertest={sethyperdataaftertest} 
              hyperdataaftervalidation={hyperdataaftervalidation} sethyperdataaftervalidation={sethyperdataaftervalidation} 
              hyperdatabeforetest={hyperdatabeforetest} sethyperdatabeforetest={sethyperdatabeforetest} sethyperdatabeforevalidation={sethyperdatabeforevalidation} 
              hyperdatabeforevalidation={hyperdatabeforevalidation} flag={flag} setflag={setflag} activeTab={activeTab} setActiveTab={setActiveTab} showToast={showToast} initalstate={initalstate} 
              newProjectState={newProjectState} setNewProjectState={setNewProjectState} setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} 
              setSelectedGoal={setSelectedGoal} />} />
              <Route path="/myProjectMainScreen" element={<MyProjectMainSCreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path="/myProjects" element={<MyProjectScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path='/myBuiltInProjects' element={<MyBuiltInProjectScreen setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path='/myBuiltInProjectsPrediction' element={<BuiltInProjectPredictions setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
              <Route path='/myProjectsPrediction' element={<ProjectPredictions setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal} />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
