import { CORRELATION_ALL_VARIABLES_FAIL_GOAL3, CORRELATION_ALL_VARIABLES_REQUEST_GOAL3, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL3 } from "../../constants"

// correlation reducer for goal 3
export const correlation_allVariableReducerGoal3 = (state = {CorrelationallVariableListGoal3: []}, action) => {
    switch(action.type){
        case CORRELATION_ALL_VARIABLES_REQUEST_GOAL3:
            return {loading: true}
        case CORRELATION_ALL_VARIABLES_SUCCESS_GOAL3:
            return{loading: false, CorrelationallVariableListGoal3: action.payload }
        case CORRELATION_ALL_VARIABLES_FAIL_GOAL3:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}