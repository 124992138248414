import { getSummaryApi } from "../../../utils/api";
import {SUMMARY_FAIL, SUMMARY_REQUEST, SUMMARY_SUCCESS } from "../../constants";

// summary action for goal 1
export const getSummary = (a) => async(dispatch) => {
    dispatch({
        type: SUMMARY_REQUEST
    })

    try{
      const {data} = await getSummaryApi(a);
      dispatch({
          type: SUMMARY_SUCCESS,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SUMMARY_FAIL,
            payload: error.message
        })
    }
}