import { getSavedResultsApiByGoal } from "../../../utils/api";
import {GOAL_RESULT_FAIL, GOAL_RESULT_REQUEST, GOAL_RESULT_SUCCESS } from "../../constants";

// goal result action
export const getGoalResult = (goal) => async(dispatch) => {
    dispatch({
        type: GOAL_RESULT_REQUEST
    })

    try{
      const {data} = await getSavedResultsApiByGoal(goal);
      dispatch({
          type: GOAL_RESULT_SUCCESS,
          payload: data
      });
    }catch(error){
        dispatch({
            type: GOAL_RESULT_FAIL,
            payload: error.message
        })
    }
}