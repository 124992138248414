import { GET_CHART_RESULT_FAIL_GOAL3, GET_CHART_RESULT_REQUEST_GOAL3, GET_CHART_RESULT_SUCCESS_GOAL3, RESET_DATA_GOAL3 } from "../../constants"

// result reducer for goal 3
export const gotModalResultReducerGoal3 = (state = {hasResultGoal3: []}, action) => {
    switch(action.type){
        case GET_CHART_RESULT_REQUEST_GOAL3:
            return {loading: true}
        case GET_CHART_RESULT_SUCCESS_GOAL3:
            return{loading: false, hasResultGoal3: action.payload }
        case GET_CHART_RESULT_FAIL_GOAL3:
            return {loading : false, error: action.payload}
        case RESET_DATA_GOAL3:
            return {loading : false, hasResultGoal3: []}
        default:
            return state
    }
}
