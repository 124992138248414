import { USER_LOGIN_SAVE, USER_LOGOUT } from "../../constants"

export const userReducer = (state = {}, action) => {
    switch(action.type){
        
        case USER_LOGIN_SAVE:
            return{loading: false, UserInfo: action.payload }
        case USER_LOGOUT:
            return {UserInfo: {}}
        default:
            return state
    }
}