import { getSelectedVariableApiGoal4 } from "../../../utils/api";
import { SELECTED_VARIABLES_REQUEST_GOAL4, SELECTED_VARIABLES_SUCCESS_GOAL4, SELECTED_VARIABLES_FAIL_GOAL4 } from "../../constants";

// selected variables action for goal 4
export const getselectedVariableGoal4 = () => async(dispatch) => {
    dispatch({
        type: SELECTED_VARIABLES_REQUEST_GOAL4
    })

    try{
      const {data} = await getSelectedVariableApiGoal4();
      dispatch({
          type: SELECTED_VARIABLES_SUCCESS_GOAL4,
          payload: data
      });
    }catch(error){
        dispatch({
            type: SELECTED_VARIABLES_FAIL_GOAL4,
            payload: error.message
        })
    }
}

