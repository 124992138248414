import React from 'react';
import { useTable,useRowSelect } from 'react-table';
import '../../../HomeScreen/Table/Table.css';
import IndeterminateCheckbox from '../../../HomeScreen/Table/CheckBox';

function IndVarTable({ columns, data, getVariables }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows
      } = useTable({
        columns,
        data,
      },
      useRowSelect,
      hooks => {
        
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          ...columns,
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div style={{marginLeft : '10px'}}>
               <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          }
        ])
      }
      
      )

    var variablesToSend = [];
    React.useEffect(() => {
      selectedFlatRows.forEach(row => {
    
        variablesToSend.push(row.original.varName)
      });
     

      getVariables(variablesToSend)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedFlatRows])
    return (
        <table className='table-responsive' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();
                  return (
                    <th key={key} {...restColumn}>
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={key} {...restCellProps}>
                      <span>{cell.render("Cell")}</span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
}

export default IndVarTable;