/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './SavedResultScreen.css'
import resultBase from '../../Images/resultBase.svg';
import { useSelector,useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import ScaleLoader from 'react-spinners/ScaleLoader';
// import { getAllVariable } from '../../../Redux/Reducers/allVariable/allVariableAction';
import { getModelVariableHeader } from '../../../utils/getSavedResultHeaders';
import Table from '../HomeScreen/Table/Table';
import MasterPage from '../../MasterPage';
import { useNavigate, useLocation } from 'react-router-dom';
import { getselectedVariable } from '../../../Redux/Reducers/selectedVariable/selectedVariableAction';
import { getselectedVariableGoal2 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal2';
import { getselectedVariableGoal3 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal3';
import { getselectedVariableGoal4 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal4';
import { getselectedVariableGoal5 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal5';
import { getselectedVariableGoal6 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal6';
import { getselectedVariableGoal7 } from '../../../Redux/Reducers/selectedVariable/selectedVariableActionGoal7';
// import { LocalAtm } from '@mui/icons-material';

function ModelVariable({setCurrentlyActiveTab,currentlyActiveTab, selectedGoal,setSelectedGoal}) {

    const dispatch = useDispatch();
    const [header, setHeader] = useState();
    const [row, setRow] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const selectedVariable = useSelector(state => state.selectedVariableReducer);
    const { loading, error: error3, selectedVariableList } = selectedVariable;

    const selectedVariableGoal2 = useSelector(state => state.selectedVariableReducerGoal2);
    const { loading: loadingGoal2, error: error3Goal2, selectedVariableList: selectedVariableListGoal2 } = selectedVariableGoal2;

    const selectedVariableGoal3 = useSelector(state => state.selectedVariableReducerGoal3);
    const { loading: loadingGoal3, error: error3Goal3, selectedVariableList: selectedVariableListGoal3 } = selectedVariableGoal3;

    const selectedVariableGoal4 = useSelector(state => state.selectedVariableReducerGoal4);
    const { loading: loadingGoal4, error: error3Goal4, selectedVariableList: selectedVariableListGoal4 } = selectedVariableGoal4;

    const selectedVariableGoal5 = useSelector(state => state.selectedVariableReducerGoal5);
    const { loading: loadingGoal5, error: error3Goal5, selectedVariableList: selectedVariableListGoal5 } = selectedVariableGoal5;

    const selectedVariableGoal6 = useSelector(state => state.selectedVariableReducerGoal6);
    const { loading: loadingGoal6, error: error3Goal6, selectedVariableList: selectedVariableListGoal6 } = selectedVariableGoal6;

    const selectedVariableGoal7 = useSelector(state => state.selectedVariableReducerGoal7);
    const { loading: loadingGoal7, error: error3Goal7, selectedVariableList: selectedVariableListGoal7 } = selectedVariableGoal7;

    const  SelectedTrainedModelReducer = useSelector(state => state. SelectedTrainedModelReducer);
    const {  selectedTrainedModel } =  SelectedTrainedModelReducer;


    let location = useLocation();

    let navigate = useNavigate();

    const override = css`position: absolute; transform: translate(-20%, 300%);`;

    const handleButtonClick = () => {
        navigate('/summarystatistics')
        // navigate('/summarystatistics', { state: {from: location.state.from,dependentVariables: location.state.dependentVariables,independentVariables: location.state.independentVariables,modelName: location.state.modelName}})
    }

    useEffect(() => {
        const columns = getModelVariableHeader();
        setHeader(columns);
    },[])

    useEffect(() => {
   
        switch(selectedTrainedModel.from){
            case 'goal1':
                dispatch(getselectedVariable())
                break;
            case 'goal2':
                dispatch(getselectedVariableGoal2())
                break;
            case 'goal3':
                dispatch(getselectedVariableGoal3())
                break;
            case 'goal4':
           
                dispatch(getselectedVariableGoal4())
                break;
            case 'goal5':
                dispatch(getselectedVariableGoal5())
                break;
            case 'goal6':
                dispatch(getselectedVariableGoal6())
                break;
            case 'goal7':
                dispatch(getselectedVariableGoal7())
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTrainedModel])

    //setting rows for table
    useEffect(() => {
        if(selectedTrainedModel.from === 'goal1'){
            setRow(selectedVariableList); setIsLoading(loading)
        }
        if(selectedTrainedModel.from === 'goal2'){
           setRow(selectedVariableListGoal2); setIsLoading(loadingGoal2)
        }
        if(selectedTrainedModel.from === 'goal3'){
           setRow(selectedVariableListGoal3);setIsLoading(loadingGoal3)
        }
        if(selectedTrainedModel.from === 'goal4'){
            setRow(selectedVariableListGoal4); setIsLoading(loadingGoal4)
        }
        if(selectedTrainedModel.from === 'goal5'){
            setRow(selectedVariableListGoal5); setIsLoading(loadingGoal5) 
        }
        if(selectedTrainedModel.from === 'goal6'){
            setRow(selectedVariableListGoal6); setIsLoading(loadingGoal6)
        }
        if(selectedTrainedModel.from === 'goal7'){
            setRow(selectedVariableListGoal7); setIsLoading(loadingGoal7)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading,loadingGoal2,loadingGoal3,loadingGoal4,loadingGoal5,loadingGoal6,loadingGoal7])

    return (
        <>
            <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab}  currentlyActiveTab={currentlyActiveTab} selectedGoal={selectedGoal} setSelectedGoal={setSelectedGoal}  needSidebar={false} />
            <div className='screen nosidebar-screen modelvariable'>
                <div className='tableHeader savedresult-tableHeader' style={{
                    background: `url(${resultBase})`,
                }}>
                    <label className='table-title'>Model Variables</label>
                    <button className='view-variables-button' onClick={handleButtonClick}>View summary statistics</button>
                </div>
                <div className="Container boundary">
                <div className='table-view__container'>
                    {isLoading ? <div><ScaleLoader color={"#fff"} height={40} width={5} loading={isLoading} css={override} /></div> :
                        <>
                            {(header && row) && <Table columns={header} data={row} />}
                        </>
                    }
                </div>

            </div>
            </div>


        </>
    );
}

export default ModelVariable;