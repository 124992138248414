import { SUMMARY_FAIL_GOAL2, SUMMARY_REQUEST_GOAL2, SUMMARY_SUCCESS_GOAL2 } from "../../constants";

// summary reducer for goal 2
export const summaryReducerGoal2 = (state = {summaryStatistics: []}, action) => {
    switch(action.type){
        case SUMMARY_REQUEST_GOAL2:
            return {loading: true}
        case SUMMARY_SUCCESS_GOAL2:
            return{loading: false, summaryStatistics: action.payload }
        case SUMMARY_FAIL_GOAL2:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}