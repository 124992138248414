import React, { useRef } from 'react';
import MasterPage from '../../MasterPage';
import Predictions from '../../Images/predictions.svg';
import newProject from '../../Images/newProject.png'
import { useNavigate } from 'react-router-dom';

function MyProjectMainSCreen({currentlyActiveTab,setCurrentlyActiveTab,selectedGoal,setSelectedGoal}) {

    const predictCard = useRef(null)
    const createProject = useRef(null)

    let navigate = useNavigate();

    const handlePredictCardClick = () => {
        predictCard.current.classList.toggle('selected')
        createProject.current.classList.remove('selected')
        navigate('/myBuiltInProjects')
    }

    
    const handleNewProjectCardClick = () => {
        createProject.current.classList.toggle('selected')
        predictCard.current.classList.remove('selected')
        navigate('/myProjects')
    }


    return (
        <>
        <MasterPage setCurrentlyActiveTab={setCurrentlyActiveTab} currentlyActiveTab={currentlyActiveTab} selectedGoal = {selectedGoal} setSelectedGoal = {setSelectedGoal} needSidebar={false} needHomePageSidebar={true} />
        <div className='landingscreen screen'>
            <div className='landingscreen-main'>
            <h1>Please select an option</h1>
            <div className='landing-cards-group'>
                <div ref={createProject} className='landing-card' onClick={() => handleNewProjectCardClick()}>               
                    <h1>View Custom Projects</h1>
                    <img src={newProject} alt="prediction-icon" />
                    <h4>View Projects created using the custom goal created by training the models on the data uploaded by user.</h4>
                </div>
                <div ref={predictCard} className='landing-card' onClick={() => handlePredictCardClick()}>               
                    <h1>View Built In Projects</h1>
                    <img src={Predictions} alt="prediction-icon" />
                    <h4>View Projects created using BuiltIn Pre-trained Goals on extensive amount of data (over 6M+ observations)</h4>
                </div>
              
            </div>

            </div>
        </div>
        </>
    );
}

export default MyProjectMainSCreen;