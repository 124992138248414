import { getAllVariableApiGoal7 } from "../../../utils/api";
import { ALL_VARIABLES_FAIL_GOAL7, ALL_VARIABLES_REQUEST_GOAL7, ALL_VARIABLES_SUCCESS_GOAL7 } from "../../constants";

// all variable action for goal 7
export const getAllVariableGoal7 = () => async(dispatch) => {
    dispatch({
        type: ALL_VARIABLES_REQUEST_GOAL7
    })

    try{
      const {data} = await getAllVariableApiGoal7();
      dispatch({
          type: ALL_VARIABLES_SUCCESS_GOAL7,
          payload: data
      });
    }catch(error){
        dispatch({
            type: ALL_VARIABLES_FAIL_GOAL7,
            payload: error.message
        })
    }
}