import React, { useEffect } from 'react';
import './ProjectDetailsForm.css'
import TextField from '@mui/material/TextField';


function ProjectDetailsForm({setisNextButtonDisabled,setNewProjectState, newProjectState}) {

    const handleInputChange = (e) => {
        setNewProjectState((current) => {
        return ({
            ...current,
            ProjectName: e.target.value
        })
        })

        
        
    }

    useEffect(()=> {
        if(newProjectState.ProjectName === ''){
            setisNextButtonDisabled(true)
        } else if(newProjectState.ProjectName !== ''){
            setisNextButtonDisabled(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState.ProjectName])
    return (
        <div className='project-details-form'>
            <TextField id="outlined-basic" label="Project Name" value ={newProjectState.ProjectName} variant="outlined" InputLabelProps={{
                style: { color: '#fff' },
            }}  onChange={handleInputChange} />


        </div>
    );
}

export default ProjectDetailsForm;