import axios from "axios";

const baseURL = 'https://my.plensy.com/api/';

const summary = 'summary';
const selectedVariable = 'selectedVariable';
const getVariables = 'getVariables';
const getCorelationAllVariables = 'getCorelationAllVariables'
const saveResult = 'saveResult'
const getSavedResults = 'getSavedResults'
const getSavedResultsByGoal = 'getSavedResults/goal'
const goal2Route = 'goal2'
const goal3Route = 'goal3'
const goal4Route = 'goal4'
const goal5Route = 'goal5'
const goal6Route = 'goal6'
const goal7Route = 'goal7'
const login = 'https://my.plensy.com/api/login'
const uploadDatafile = 'https://my.plensy.com/api/uploadUserData'
const getCustomProjectSummaryStats = 'https://my.plensy.com/api/CustomProjectSummaryStats'
const newProjectGetResult = 'https://my.plensy.com/api/getPredictionResultNewProject'
const savePrediction = 'https://my.plensy.com/api/savecustomprojectprediction'
const getcustomProject = 'https://my.plensy.com/api/getcustomProject'
const getcustomProjectPredictions = 'https://my.plensy.com/api/getcustomProjectPredictions'
const deleteSingleProject = 'https://my.plensy.com/api/deleteSingleProject'
const deletePrediction = 'https://my.plensy.com/api/deletePrediction'
const HyperParameterTuning = 'https://my.plensy.com/api/HyperParameterTuning'
const deleteProjectPrediction  = 'deleteProjectPrediction'
const updateSinglePrediction = 'updatecustomprojectprediction'
const shuffledPredictionCards = 'shuffledPredictionCards'
const saveBuiltInproject = 'saveBuiltInproject'
const updatebuiltInprojectprediction ='updatebuiltInprojectprediction'
const saveBuiltInprojectprediction = 'saveBuiltInprojectprediction'
const getBuiltInproject = 'getBuiltInproject'
const getBuiltInprojectprediction = 'getBuiltInprojectprediction'
const fetchBuiltInproject = 'fetchBuiltInproject'
const deleteBuiltInSingleProject = 'deleteBuiltInSingleProject'
const deleteBuiltInSingleProjectPrediction = 'deleteBuiltInSingleProjectPrediction'
const shuffledBuiltInPredictionCards ='shuffledBuiltInPredictionCards'


export const shuffledBuiltInPredictionsApi = (data) => {
    return axios.post(baseURL + shuffledBuiltInPredictionCards,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}


export const removeBuiltInProjectPredictionAPI =(data) => {
    return axios.post(baseURL + deleteBuiltInSingleProjectPrediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}


export const removeBuiltInprojectAPI =(data) => {
    return axios.post(baseURL + deleteBuiltInSingleProject,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}



export const fetchBuiltInprojectApi = (data) => {
    return axios.post(baseURL + fetchBuiltInproject,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}



export const AddBuiltInProjectPredictionsApi = (data) => {
    return axios.post(baseURL + updatebuiltInprojectprediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}


export const saveBuiltInProjectpredictionAPI =(data) => {
    return axios.post(baseURL + saveBuiltInprojectprediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}


export const checkBuiltInprojecpredictiontApi = (data) => {
    return axios.post(baseURL + getBuiltInprojectprediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}

export const checkBuiltInprojectApi = (data) => {
    return axios.post(baseURL + getBuiltInproject,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}




export const saveBuiltInProjectAPI =(data) => {
    return axios.post(baseURL + saveBuiltInproject,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}



export const removePredictionAPI =(data) => {
    return axios.post(baseURL + deleteProjectPrediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}


export const AddProjectPredictionsApi = (data) => {
    return axios.post(baseURL + updateSinglePrediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}

export const shuffledPredictionsApi = (data) => {
    return axios.post(baseURL + shuffledPredictionCards,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}




export const getAllVariableApi = () => {
    return axios.get(baseURL)
 
}

export const getAllVariableApiGoal2 = () => {
    return axios.get(baseURL + goal2Route)
}

export const getAllVariableApiGoal3 = () => {
    return axios.get(baseURL + goal3Route)
}

export const getAllVariableApiGoal4 = () => {
    return axios.get(baseURL + goal4Route)
}

export const getAllVariableApiGoal5 = () => {
    return axios.get(baseURL + goal5Route)
}

export const getAllVariableApiGoal6 = () => {
    return axios.get(baseURL + goal6Route)
}

export const getAllVariableApiGoal7 = () => {
    return axios.get(baseURL + goal7Route)
}

export const getSelectedVariableApi = () => {
    return axios.get(baseURL+selectedVariable)
}

export const getSelectedVariableApiGoal2 = () => {
    return axios.get(baseURL+ goal2Route + '/' +selectedVariable)
}

export const getSelectedVariableApiGoal3 = () => {
    return axios.get(baseURL+ goal3Route + '/' +selectedVariable)
}

export const getSelectedVariableApiGoal4 = () => {
    return axios.get(baseURL+ goal4Route + '/' +selectedVariable)
}


export const getSelectedVariableApiGoal5 = () => {
    return axios.get(baseURL+ goal5Route + '/' +selectedVariable)
}

export const getSelectedVariableApiGoal6 = () => {
    return axios.get(baseURL+ goal6Route + '/' +selectedVariable)
}

export const getSelectedVariableApiGoal7 = () => {
    return axios.get(baseURL+ goal7Route + '/' +selectedVariable)
}

export const getSummaryApi = () => {
    return axios.get(baseURL+summary)
}

export const getSummaryApiGoal2 = () => {
    return axios.get(baseURL + goal2Route + '/' +summary)
}

export const getSummaryApiGoal3 = () => {
    return axios.get(baseURL + goal3Route + '/' +summary)
}

export const getSummaryApiGoal4 = () => {
    return axios.get(baseURL + goal4Route + '/' +summary)
}

export const getSummaryApiGoal5 = () => {
    return axios.get(baseURL + goal5Route + '/' +summary)
}

export const getSummaryApiGoal6 = () => {
    return axios.get(baseURL + goal6Route + '/' +summary)
}

export const getSummaryApiGoal7 = () => {
  //  return axios.get(baseURL + goal7Route + '/' +summary)
}

export const sendVariablesApi = (variables) => {
   
    return axios.post(baseURL+getVariables,variables,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const uploadFileApi = (data) => {
    return axios.post(uploadDatafile,data,{headers:{    
        // 'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const newProjectSummaryApi = (data) => {
    return axios.post(getCustomProjectSummaryStats,data,{headers:{    
        // 'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const newProjectGetResultApi = (data) => {
    return axios.post(newProjectGetResult,data,{headers:{    
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}

export const hypetuneAlgoApi = (data) => {
    return axios.post(HyperParameterTuning,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}

export const GetProjectApi = (data) => {
    return axios.post(getcustomProject,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }}).catch(err =>
        console.log('getprojectapi', err.response.data))
}

export const GetProjectPredictionsApi = (data) => {
    console.log(getcustomProjectPredictions)
    return axios.post(getcustomProjectPredictions,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }})
    
}

export const DeletePredictionApi =(data) => {
    return axios.post(deletePrediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}

export const DeleteProjectApi =(data) => {
    return axios.post(deleteSingleProject,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }})
}

export const sendVariablesApiGoal2 = (variables) => {

    return axios.post(baseURL+ goal2Route + '/' + getVariables,variables,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const loginApi = (data) => {
    return axios.post(login,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // // 'Access-Control-Allow-Origin': '*' 
    }})
}



export const savePredictionApi = (data) => {
    return axios.post(savePrediction,data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}


export const registerApi = (data) => {
    return axios.post(baseURL + "register",data,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendVariablesApiGoal3 = (variables) => {
   
    return axios.post(baseURL+ goal3Route + '/' + getVariables,variables,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendVariablesApiGoal4 = (variables) => {
   
    return axios.post(baseURL+ goal4Route + '/' + getVariables,variables,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendVariablesApiGoal5 = (variables) => {
    return axios.post(baseURL+ goal5Route + '/' + getVariables,variables,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendVariablesApiGoal6 = (variables) => {
    return axios.post(baseURL+ goal6Route + '/' + getVariables,variables,{headers:{    
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const getCorrelationAllVar = () => {
    console.log(baseURL+getCorelationAllVariables)
    return axios.get(baseURL+getCorelationAllVariables)
}

export const getCorrelationAllVarGoal2 = () => {
    return axios.get(baseURL + goal2Route + '/'+getCorelationAllVariables)
}

export const getCorrelationAllVarGoal3 = () => {
    return axios.get(baseURL + goal3Route + '/'+getCorelationAllVariables)
}

export const getCorrelationAllVarGoal4 = () => {
    return axios.get(baseURL + goal4Route + '/'+getCorelationAllVariables)
}

export const getCorrelationAllVarGoal5 = () => {
    return axios.get(baseURL + goal5Route + '/'+getCorelationAllVariables)
}

export const getCorrelationAllVarGoal6 = () => {
    return axios.get(baseURL + goal6Route + '/'+getCorelationAllVariables)
}

export const getCorrelationAllVarGoal7 = () => {
    return axios.get(baseURL + goal7Route + '/'+getCorelationAllVariables)
}

// save result feauture post api
export const saveResultApi = (data) => {
    return axios.post(baseURL+saveResult,data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

// get all the saved results
export const getSavedResultsApi = () => {
    return axios.get(baseURL + getSavedResults)
}

// get a particular the saved results
export const getSavedResultsApiById = (id) => {
    return axios.get(baseURL + getSavedResults + '/' +id)
}

// get a particular the saved results
export const getSavedResultsApiByGoal = (from) => {
    return axios.get(baseURL + getSavedResultsByGoal + '/' + from)
}

//send user input from predict page data to backend

export const sendPredictDataGoal1 = (data) => {
    return axios.post(baseURL+'PreditctGoal1',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendPredictDataGoal2 = (data) => {
    return axios.post(baseURL+'PreditctGoal2',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendPredictDataGoal3 = (data) => {
    return axios.post(baseURL+'PreditctGoal3',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendPredictDataGoal4 = (data) => {
    return axios.post(baseURL+'PreditctGoal4',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendPredictDataGoal5 = (data) => {
    return axios.post(baseURL+'PreditctGoal5',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendPredictDataGoal6 = (data) => {
    return axios.post(baseURL+'PreditctGoal6',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}

export const sendPredictDataGoal7 = (data) => {
    return axios.post(baseURL+'PreditctGoal7',data,{headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*' 
    }})
}