import React, { useEffect, useState } from 'react';
import IndVarTable from './IndVarTable';
import './IndVardsScreenNewProject.css'
import { getNewProjectTableHeader } from '../../../../../utils/getNewProjectTableHeader';

function IndVardsScreenNewProject({ newProjectState,setNewProjectState }) {
    const [header, setHeader] = useState();
    const [row, setRow] = useState();

    // const [formValidationErr, setFormValidationErr] = useState('');

    let tableData = [];




    const getVariables = (variables) => {
        let filteredArray = newProjectState.IndependentVar.filter(e => e !== newProjectState.DependentVar)
    
        setNewProjectState((current) => {
            return ({
                ...current,
                IndependentVar: variables
              //  IndependentVar: filteredArray
            })
        })
    }

    useEffect(() => {
        const columns = getNewProjectTableHeader();
        setHeader(columns);
        newProjectState.AllVars.forEach(element => {
            if(newProjectState.DependentVar !== element){
            tableData.push({
                varName: element
            })
        }
        });
        setRow(tableData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProjectState.AllVars,newProjectState.DependentVar])

    

    return (
        <div className='indVarSectionContainer'>
            <h2>Please Select Independent Variables</h2>
            <div className='table-view__container'>
                {(row && header) && <IndVarTable columns={header} data={row} getVariables={getVariables} /> }
            </div>
        </div>
    );
}

export default IndVardsScreenNewProject;