import { CORRELATION_ALL_VARIABLES_FAIL_GOAL7, CORRELATION_ALL_VARIABLES_REQUEST_GOAL7, CORRELATION_ALL_VARIABLES_SUCCESS_GOAL7 } from "../../constants"

// correlation reducer for goal 7
export const correlation_allVariableReducerGoal7 = (state = {CorrelationallVariableListGoal7: []}, action) => {
    switch(action.type){
        case CORRELATION_ALL_VARIABLES_REQUEST_GOAL7:
            return {loading: true}
        case CORRELATION_ALL_VARIABLES_SUCCESS_GOAL7:
            return{loading: false, CorrelationallVariableListGoal7: action.payload }
        case CORRELATION_ALL_VARIABLES_FAIL_GOAL7:
            return {loading : false, error: action.payload}
        default:
            return state
    }
}