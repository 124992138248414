import { GET_CHART_RESULT_FAIL_GOAL5, GET_CHART_RESULT_REQUEST_GOAL5, GET_CHART_RESULT_SUCCESS_GOAL5, RESET_DATA_GOAL5 } from "../../constants"

// result reducer for goal 5
export const gotModalResultReducerGoal5 = (state = {hasResultGoal5: []}, action) => {
    switch(action.type){
        case GET_CHART_RESULT_REQUEST_GOAL5:
            return {loading: true}
        case GET_CHART_RESULT_SUCCESS_GOAL5:
            return{loading: false, hasResultGoal5: action.payload }
        case GET_CHART_RESULT_FAIL_GOAL5:
            return {loading : false, error: action.payload}
        case RESET_DATA_GOAL5:
            return {loading : false, hasResultGoal5: []}
        default:
            return state
    }
}
