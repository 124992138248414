import { SELECTED_VARIABLES_REQUEST_GOAL5, SELECTED_VARIABLES_SUCCESS_GOAL5, SELECTED_VARIABLES_FAIL_GOAL5 } from "../../constants";

// selected variables reducer for goal 5
export const selectedVariableReducerGoal5 = (state = {selectedVariableList: []}, action) => {
    switch(action.type){
        case SELECTED_VARIABLES_REQUEST_GOAL5:
            return {loading: true}
        case SELECTED_VARIABLES_SUCCESS_GOAL5:
            return{loading: false, selectedVariableList: action.payload }
        case SELECTED_VARIABLES_FAIL_GOAL5:
            return {loading : false, error: action.payload}
        
        default:
            return state
    }
}