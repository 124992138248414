import { SELECTED_VARIABLES_REQUEST_GOAL3, SELECTED_VARIABLES_SUCCESS_GOAL3, SELECTED_VARIABLES_FAIL_GOAL3 } from "../../constants";

// selected variables reducer for goal 3
export const selectedVariableReducerGoal3 = (state = {selectedVariableList: []}, action) => {
    switch(action.type){
        case SELECTED_VARIABLES_REQUEST_GOAL3:
            return {loading: true}
        case SELECTED_VARIABLES_SUCCESS_GOAL3:
            return{loading: false, selectedVariableList: action.payload }
        case SELECTED_VARIABLES_FAIL_GOAL3:
            return {loading : false, error: action.payload}
        
        default:
            return state
    }
}