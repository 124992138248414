import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


function PrivateRoute(props) {
    const User = useSelector(state => state.User);
    const { UserInfo } = User;
    if (UserInfo) {
        if (Object.keys(UserInfo).length !== 0) {
            return <Outlet />
        }
        else {
            return <Navigate to="/login" />
        }
    } else {
        return <Navigate to="/login" />
    }
    // return Object.keys(UserInfo).length !== 0 ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoute;