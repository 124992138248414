import { SELECTED_VARIABLES_REQUEST, SELECTED_VARIABLES_SUCCESS, SELECTED_VARIABLES_FAIL } from "../../constants";

// selected variables reducer for goal 1
export const selectedVariableReducer = (state = {selectedVariableList: []}, action) => {
    switch(action.type){
        case SELECTED_VARIABLES_REQUEST:
            return {loading: true}
        case SELECTED_VARIABLES_SUCCESS:
            return{loading: false, selectedVariableList: action.payload }
        case SELECTED_VARIABLES_FAIL:
            return {loading : false, error: action.payload}
        
        default:
            return state
    }
}